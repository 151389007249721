import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Container from "../../components/common/Container";
import { PopupContext } from '../../components/LoginPopup/LoginPopupContext';
import Banner from '../../components/common/Banner';
import { Swiper, SwiperSlide } from 'swiper/react';
import searchIcon from "../../assets/images/search-icon.png";

export default function Virtuals() {
  document.title = "Virtuals";
  const [royalCasinoApi, setRoyalCasino] = useState();
   const navigate = useNavigate();
    const [providerList, setProviderList] = useState([]);
    const [results, setResults] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedProvider, setSelectedProvider] = useState("All");
    const [selectedType, setSelectedType] = useState("All");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const token = sessionStorage.getItem("token");
    const { openPopup, loggedIn, isLoggedOut } = useContext(PopupContext);
    const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
    const dropdownRef = useRef(null);
    const [dropdown, setDropdown] = useState();
    const [selectTab, setSelectTab] = useState(0);
    const [gameTab, setGameTab] = useState('All');
    const [tabType, setTabType] = useState("");
    const [gameName, setgameName] = useState("");

    const handlePlayNow = (item) => {
      navigate(`/live_casinoInPlay/${item._id}`);
    }
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false); // Close the dropdown if clicked outside
      }
    };
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

    const dropdownHandle = () => {
      setDropdown(!dropdown);
    }




    useEffect(() => {
      callAPI("All", "All");
      callProviderAPI("All", "All");
    }, []);

    const callAPI = (name, type) => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/virtualNew?provider=${name}&type=${type}`, requestOptions)
        .then(async (response) => {
          let paresData = await response.json();
          setRoyalCasino(paresData);
          setgameName(paresData.gameNameAll)
          console.log("live_casino - ", paresData);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const callProviderAPI = (name,type) => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/virtualNew?provider=${name}&type=${type}`, requestOptions)
        .then(async (response) => {
          setGameTab('All');
          let paresData = await response.json();
          let list = paresData.providerNames;
          setgameName(paresData.gameNameAll);
          let newString = "All"
          setProviderList([newString, ...list]);
          console.log("providerlist - ", paresData);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleSearchChange = async (event) => {
      const value = event.target.value;
      setSearchTerm(value);

      if (value.length > 1) {
        setShowSuggestions(true);

        try {
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          const raw = JSON.stringify({
            whiteLabelName: window.location.hostname,
            search: value,
          });
          console.log("gameSearch Req", raw);
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/games/gameSearch`,
            requestOptions
          )
            .then(async (response) => {
              let paresData = await response.json();
              setResults(paresData);
              console.log("gameSearch Response : ", paresData);
            })
            .catch((error) => {
              console.log("gameSearch Error : ", error);
            });
        } catch (err) {
          console.log("gameSearch Error : ", err.message);
        }
      } else {
        setShowSuggestions(false);
      }
    };

    const callFavorite = (gameId) => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const raw = JSON.stringify({
        id: gameId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_BASE_URL}/arlivecasinofavioutgame`,
        requestOptions
      )
        .then(async (response) => {
          let paresData = await response.json();
          callAPI(selectedOption, tabType);
          console.log("arlivecasinofavioutgame - ", paresData);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleSuggestionClick = (item) => {
      if(isLoggedIn || loggedIn){
        console.log("handleSuggestionClick : ", item);
        navigate(`/live_casinoInPlay/${item._id}`);
        handleClear();
        setShowSuggestions(false); // Hide suggestions when user selects one
      }else{
        openPopup();
      }
    };

    const handleClear = () => {
      console.log("no Record found! clear text");
      setSearchTerm("");
      setResults();
    };

    const handleLikeUnlike = (item) => {
      callFavorite(item._id);
    };


    let cat = gameName
    console.log(cat, 'catcatcat');


    const handleProvider = (item,index) =>{
      setGameTab('All');
      setSelectTab(index);
      callAPI(item,"All");
      setSelectedProvider(item);
      console.log("handleProvider : ",item);
    }
    const handleType = (type) =>{
      setSelectedType(type);
      callAPI(selectedProvider,type);
      console.log("handleType : ",type);
      setGameTab(type);
    }

  return (
    <>
      <Container size="full">
        <div className="content-full">
        <Banner image={`https://admin.${window.location.hostname}/img/virtual_top.webp`} className='live-casino-banner' />
          <Swiper className="game-provider-list"
            spaceBetween={5}
            scrollbar={{
              el: '.swiper-scrollbar',
              draggable: true,
            }}
            slidesPerView={'auto'}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {providerList && providerList.map((item, index) => (
              <SwiperSlide onClick={()=>handleProvider(item,index)} key={index} style={{ width: 'auto' }} className={`${index === selectTab ? 'active': ''}`}>
                <span>{item}</span>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="search-with-swiper">
          <Swiper className="game-category-list" spaceBetween={10} slidesPerView={'auto'} onSlideChange={() => console.log('slide change')} onSwiper={(swiper) => console.log(swiper)} >

            {gameName && gameName.map((item, index) => (
              <SwiperSlide key={index} style={{ width: 'auto' }}>
                <div onClick={()=>handleType(item.id)} className={`game-tab ${item.id === gameTab ? 'active': ''}`}>
                  <span className="icon"><img src={item.url} /></span>
                  {item.name}
                </div> </SwiperSlide>
            ))}
          </Swiper>
          <div className="dropdown search-dropdown">
              <button className="search-icon" onClick={dropdownHandle}><span className="icon"><img src={searchIcon} /></span> Search</button>
              <div className={`dropdown-menu ${dropdown ? 'active' : ''}`} ref={dropdownRef}>
                <input
                  type="text"
                  placeholder="Search games..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onFocus={() => setShowSuggestions(true)}
                />
                {results && showSuggestions && results.games.length > 0 && (
                  <ul className="search-result">
                    {results.games.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(item)}
                      >
                        {item.game_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>






          <div className="home-grid-sec casino-grid-sec">
            {royalCasinoApi ?
              <div className="home-grid casino-grid">
                {royalCasinoApi &&
                  royalCasinoApi.games.map((item, index) => (
                    <div className="col" key={index}>
                      <Link>
                        <img src={item.url_thumb} />
                      </Link>
                      <div className="title">
                        {item.game_name}
                      </div>
                      <div className="login-btn-overlay">
                        {loggedIn || isLoggedIn ? <button onClick={() => handlePlayNow(item)} className="btn">Play now</button> : <button onClick={openPopup} className="btn">Login to continue</button>}
                      </div>
                    </div>
                  ))}
              </div> : <div className='please-wait-data'>Please wait data is loading...</div>
            }

            {royalCasinoApi && royalCasinoApi.games.length === 0 ? (
              <div className='no-data'>No Casino Found!</div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Container>
    </>
  )
}
