import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [sessionData, setSessionData] = useState('');
  const [loggedIn, setloggedIn] = useState('');

  // Load username from sessionStorage on initial load
  useEffect(() => {
    const userSession = JSON.parse(sessionStorage.getItem('userinfo'));
    const logedInStatus = JSON.parse(sessionStorage.getItem('loggedIn', 'true'));
    if (userSession && logedInStatus) {
        setSessionData(userSession);
        setloggedIn(logedInStatus)
    }
  }, []);

  return (
    <UserContext.Provider value={{ sessionData, setSessionData, loggedIn, setloggedIn }}>
      {children}
    </UserContext.Provider>
  );
};
