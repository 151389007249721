import React, { useState, useEffect, useContext, useRef } from 'react'
import './mobilebottommenu.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import menuIcon1 from '../../../assets/images/bottom-menu-icon1.svg'
import menuIcon2 from '../../../assets/images/bottom-menu-icon2.svg'
import menuIcon3 from '../../../assets/images/bottom-menu-icon3.svg'
import menuIcon4 from '../../../assets/images/bottom-menu-icon4.svg'
import mainMenuBotom from '../../../assets/images/main-menu-botom.svg'
import { PopupContext } from '../../LoginPopup/LoginPopupContext'


function MobileBottomMenu() {
const[btmMenuPopup, setBtmMenuPopup] = useState();
const[menuResponse, setMenuResponse] = useState([]);
const [activeTab, setActiveTab] = useState("");
const { openPopup , loggedIn, isLoggedOut } = useContext(PopupContext);
const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
const initialized = useRef(false);
const navigate = useNavigate();
const location = useLocation();
const handleClick = () =>{
    setBtmMenuPopup(true);
}
const handleCloseClick = () =>{
    setBtmMenuPopup(false);
}
const handleProfile = () =>{
   if(isLoggedIn || loggedIn){
      navigate('/myprofile')
   }
   else{
      openPopup()
   }
}

const handleItem = (item) => {
   setActiveTab(item.menuName);
   handleMenu(item);
   setBtmMenuPopup(false);
 }

const callMenuAPI = () => {
   const myHeaders = new Headers();
   myHeaders.append("Content-Type", "application/json");

   const raw = JSON.stringify({
     whiteLabelName: window.location.hostname
   });

   const requestOptions = {
     method: "POST",
     headers: myHeaders,
     body: raw,
     redirect: "follow",
   };

   fetch(`${process.env.REACT_APP_BASE_URL}/getMenuData`, requestOptions)
     .then(async (response) => {
       let paresData = await response.json();

       let newString = {
         "_id": "664c6f125f1e5ffsdgdsf7cc2ee2fa",
         "menuName": "Home",
         "num": 0,
         "url": "/",
         "page": "Cards1",
         "status": true,
         "whiteLabelName": window.location.hostname,
         "__v": 0
     }
     setMenuResponse([newString, ...paresData.data]);
       // console.log("getMenuData : ",paresData)
     })
     .catch((error) => {
       console.log(error);
     });
 };

 useEffect(() => {
  if (!initialized.current) {
    initialized.current = true;
   callMenuAPI();
  }
 }, [location])

 const handleMenu = (item) => {
   if(item.menuName === 'Sportsbook' ){
     if(isLoggedIn || loggedIn){
       navigate(item.url)
     }else{
       openPopup()
     }
   }else{
     navigate(item.url)
   }
 }
    return (
    <div className="mobile-bottom-menu">
    <div className="menu-links">
       <Link to="/exchange/inPlay">
          <img src={menuIcon1} alt="" />
          In-Play
       </Link>
       {/* <span onClick={()=> isLoggedIn || loggedIn ? navigate('/exchange'): openPopup()}>
          <img src={menuIcon2} alt="" />
          Sports
       </span> */}
       <span onClick={()=> navigate('/exchange')}>
          <img src={menuIcon2} alt="" />
          Sports
       </span>
       <Link className='menu-popup-icon' onClick={()=>{handleClick()}}>
        <img src={mainMenuBotom} alt="" />
      </Link>
       <Link to="/live_casino">
          <img src={menuIcon3} alt="" />
          Casino
       </Link>
       <span onClick={handleProfile}>
          <img src={menuIcon4} alt="" />
          Profile
       </span>
    </div>
    <div className={`bottom-menu-popup ${btmMenuPopup && btmMenuPopup ? 'open': ''}`}>
      <div className='overlay' onClick={handleCloseClick}></div>
       <div className="pop-menu-sec">
          <ul className="popup-menu-links">
          {menuResponse && menuResponse.map((item, index) => (
                  <li key={index}> <span className={`${location.pathname === item.url ? 'active' : ''}`} onClick={()=>handleItem(item)} key={index}>{item.menuName}</span> </li>
         ))}
          </ul>
       </div>
    </div>
  </div>
  )
}

export default MobileBottomMenu