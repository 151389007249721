import { UserProvider } from "./UserContext";
import { LoginPopupProvider } from "./components/LoginPopup/LoginPopupContext";
import ChildApp from "./ChildApp";
import LoginPopup from "./components/LoginPopup/LoginPopup";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
function App() {
  const [loading, setLoading] = useState(true);
  const initialized = useRef(false);
  
  useEffect(() => {
    function isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }
  
  
    const fetchColorCodes = async () => {
      try {
        const hostname = window.location.hostname;
        if (!initialized.current) {
          initialized.current = true;
        //   document.addEventListener('visibilitychange', () => {
        //     if (document.hidden) {
        //         // console.log('-----------------------------App went to the background',new Date());
        //         // Save necessary state
        //     } else {
        //       if(isIOS()){
        //         window.location.reload();
        //       }
        //         // console.log('-----------------------------App came to the foreground',new Date());
        //         // Restore necessary state
        //     }
        // });
        }
        // Update Favicon
        const faviconUrl = `https://admin.${hostname}/logo/${hostname}.ico`;
        const faviconElement = document.getElementById("dynamic-favicon");
        if (faviconElement) {
          faviconElement.href = faviconUrl;
        } else {
          console.warn("Favicon element not found.");
        }

        // API Request
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          whiteLabelName: hostname,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/colorCode`, requestOptions);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const parsedData = await response.json();
        const root = document.documentElement;
        const colorCodes = parsedData.colorCode;
        localStorage.setItem("logoData", JSON.stringify(parsedData.logoSizeData));
        localStorage.setItem("isB2c", JSON.stringify(parsedData.isB2c));
        // localStorage.setItem("isB2c", true);
        

        // Update CSS variables
        document.title = parsedData.basicDetails.mainName;
        localStorage.setItem("mainName", parsedData.basicDetails.mainName);
        root.style.setProperty("--primary-color", colorCodes.color2);
        root.style.setProperty("--primary-light", colorCodes.color7);
        root.style.setProperty("--light-color", colorCodes.color5);

        if (colorCodes.sidemenu_bg) {
          root.style.setProperty("--sidemenu-bg", colorCodes.sidemenu_bg);          
        }
        if (colorCodes.sidemenu_text) {
          root.style.setProperty("--sidemenu-text", colorCodes.sidemenu_text);          
        }
        if (colorCodes.sidemenu_text_hover) {
          root.style.setProperty("--sidemenu-text-hover", colorCodes.sidemenu_text_hover);          
        }
        if (colorCodes.notification_text) {
          root.style.setProperty("--notification-text", colorCodes.notification_text);          
        }
        if (colorCodes.betslip_element) {
          root.style.setProperty("--betslip-elements", colorCodes.betslip_element);          
        }
        root.style.setProperty(
          "--header-bg",
          `linear-gradient(135deg, ${colorCodes.header_bg1} 0%, ${colorCodes.header_bg2} 100%)`
        );
        root.style.setProperty(
          "--primary-gradient-hover",
          `linear-gradient(135deg, ${colorCodes.color6_1} 0%, ${colorCodes.color6_2} 100%)`
        );
        root.style.setProperty(
          "--primary-gradient",
          `linear-gradient(135deg, ${colorCodes.color1_1} 0%, ${colorCodes.color1_2} 100%)`
        );
      } catch (error) {
        console.error("Error fetching color codes:", error);

        // Fallback colors in case of error
        const root = document.documentElement;
        root.style.setProperty("--primary-color", "#ffffff");
        root.style.setProperty("--primary-light", "#f0f0f0");
        root.style.setProperty("--light-color", "#d0d0d0");
        root.style.setProperty("--header-bg", "linear-gradient(135deg, #e0e0e0 0%, #c0c0c0 100%)");
        root.style.setProperty("--primary-gradient-hover", "linear-gradient(135deg, #b0b0b0 0%, #909090 100%)");
        root.style.setProperty("--primary-gradient", "linear-gradient(135deg, #808080 0%, #606060 100%)");
      } finally {
        setLoading(false); // Hide loader
      }
    };

    fetchColorCodes();
  }, []);

  return (
    <div className="App">
     {/* Loader */}
     {loading && (<div id="loader" className="loader-page"><span className="loader"></span></div>)}
     {!loading && 
     <UserProvider>
        <LoginPopupProvider>
          <BrowserRouter>
            <LoginPopup />
            <ChildApp />
          </BrowserRouter>
        </LoginPopupProvider>
      </UserProvider>}
    </div>
  );
}

export default App;
