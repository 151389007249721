import React, { useEffect, useRef, useState } from 'react';

const OddsFeedComponenttt = ({ eventAccess, getMatchOddsHandle,getMatchOddsHandle2,getToasHandle, getBookmakerHandle, getFancyHandle, getUnderOverHandle,getOtherBookmakerHandle }) => {
  if(eventAccess?.thatMatchMarkets?.bookmakers){
    eventAccess.thatMatchMarkets.bookmakers = eventAccess.thatMatchMarkets.bookmakers.filter(item => item.marketName.trim() != "Bookmaker 0 Commission" && item.marketName.trim() != "To Win The Toss" && item.marketName.trim() != "Bookmaker")
  }
  // console.log(eventAccess, 'eventAccess?.thatMatchMarkets?eventAccess?.thatMatchMarkets?eventAccess?.thatMatchMarkets?');
  
  let otheBmMarketId = eventAccess?.thatMatchMarkets?.bookmakers.map(item => item.marketId)
  let HGMarketIdsArray = eventAccess?.thatMatchMarkets?.matchOdds.map(item => item.marketId)
  let otheMOMarketID = eventAccess?.thatMatchMarkets?.matchOdds?.map(item => item.marketType === "TOURNAMENT_WINNER" ? item.marketId : null).filter(marketId => marketId !== null);
  // console.log(otheMOMarketID, '11111111111111111111111111111');
  
  const [connected, setConnected] = useState(false);
  const [matchOdds, setMatchOdds] = useState(null);
  const [matchOdds2, setMatchOdds2] = useState(null);
  const [bookmaker, setBookmaker] = useState(null);
  const [otherBM, setotherBM] = useState(null);
  const [underover, setUnderover] = useState([null, null, null, null]);
  const [fancy, setFancy] = useState(null);
  const [toas, setToas] = useState(null);
  let subscribeChannels = useRef([]);
  const wsRef = useRef(null);
  let heartbeatInterval;
// console.log(otherBM, 'otherBMotherBMotherBMotherBMotherBM');

  const updateUnderOver = (index, value) => {
    setUnderover((prev) => {
      const updated = [...prev]; // Create a shallow copy of the current array
      updated[index] = value;   // Update the specific position
      return updated;           // Return the new array
    });
  };

  useEffect(() => {
    getMatchOddsHandle(matchOdds);
  }, [matchOdds, getMatchOddsHandle]);

  useEffect(() => {
    getMatchOddsHandle2(matchOdds2);
  }, [matchOdds2, getMatchOddsHandle2]);

  useEffect(() => {
    getUnderOverHandle(underover);
  }, [underover, getUnderOverHandle]);

  useEffect(() => {
    getBookmakerHandle(bookmaker);
  }, [bookmaker, getBookmakerHandle]);

  useEffect(() => {
    getOtherBookmakerHandle(otherBM);
  }, [otherBM, getOtherBookmakerHandle]);
  
  useEffect(() => {
    getToasHandle(toas);
  }, [toas, getToasHandle]);

  useEffect(() => {
    getFancyHandle(fancy);
  }, [fancy, getFancyHandle]);

  const startWebSocket = () => {
    const ws = new WebSocket('wss://ag-api.supermaster.live/');
    wsRef.current = ws;

    ws.onopen = () => {
      console.log('11111--Connected to WebSocket server');
      setConnected(true);
      // console.log(eventAccess,"------eventAccess");
      
      // Subscribe to topics
      if (eventAccess?.bookmakerStatus) {
          if (!subscribeChannels.current.includes(`/topic/tommy_bm_update/${eventAccess?.bookmakerId}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/tommy_bm_update/${eventAccess?.bookmakerId}` }));
            subscribeChannels.current.push(`/topic/tommy_bm_update/${eventAccess?.bookmakerId}`);
          }
      }else{
        // console.log("11111--BOOKMAKERS Not Found");
        }

        for(let k = 0; k < otheBmMarketId.length; k++){
          if (!subscribeChannels.current.includes(`/topic/tommy_bm_update/${otheBmMarketId[k]}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/tommy_bm_update/${otheBmMarketId[k]}` }));
            subscribeChannels.current.push(`/topic/tommy_bm_update/${otheBmMarketId[k]}`);
          }
        }
        if(HGMarketIdsArray){
          for(let i = 0 ; i <HGMarketIdsArray.length; i++){
            if (!subscribeChannels.current.includes(`/topic/betfair_match_odds_update/${HGMarketIdsArray[i]}`)) {
              ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/betfair_match_odds_update/${HGMarketIdsArray[i]}` }));
              subscribeChannels.current.push(`/topic/betfair_match_odds_update/${HGMarketIdsArray[i]}`);
            }
          }
        }

        for(let k = 0; k < otheMOMarketID.length; k++){
          if (!subscribeChannels.current.includes(`/topic/betfair_match_odds_update/${otheMOMarketID[k]}`)) {
            console.log(otheMOMarketID[k], 'gggggggggggggggggggggggggggggggggg');
            
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/betfair_match_odds_update/${otheMOMarketID[k]}` }));
            subscribeChannels.current.push(`/topic/betfair_match_odds_update/${otheMOMarketID[k]}`);
          }
        }

        

        if (eventAccess?.tossStatus) {
          if (!subscribeChannels.current.includes(`/topic/tommy_bm_update/${eventAccess?.tossId}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/tommy_bm_update/${eventAccess?.tossId}` }));
            subscribeChannels.current.push(`/topic/tommy_bm_update/${eventAccess?.tossId}`);
          }
      }else{
        // console.log("11111--tossStatus Not Found");
        }

      if (eventAccess?.matchoddStatus && eventAccess?.matchData.sportId == "1") {
        // console.log(eventAccess ,"-----------matchoddIdeventAccess?.matchoddId");
        
          if (!subscribeChannels.current.includes(`/topic/betfair_match_odds_update/${eventAccess?.matchoddId}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/betfair_match_odds_update/${eventAccess?.matchoddId}` }));
            subscribeChannels.current.push(`/topic/betfair_match_odds_update/${eventAccess?.matchoddId}`);
          }
          
          if (eventAccess?.overunder0Status && !subscribeChannels.current.includes(`/topic/betfair_match_odds_update/${eventAccess?.overunder0Id}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/betfair_match_odds_update/${eventAccess?.overunder0Id}` }));
            subscribeChannels.current.push(`/topic/betfair_match_odds_update/${eventAccess?.overunder0Id}`);
          }

          if (eventAccess?.overunder1Status && !subscribeChannels.current.includes(`/topic/betfair_match_odds_update/${eventAccess?.overunder1Id}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/betfair_match_odds_update/${eventAccess?.overunder1Id}` }));
            subscribeChannels.current.push(`/topic/betfair_match_odds_update/${eventAccess?.overunder1Id}`);
          }

          if (eventAccess?.overunder2Status && !subscribeChannels.current.includes(`/topic/betfair_match_odds_update/${eventAccess?.overunder2Id}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/betfair_match_odds_update/${eventAccess?.overunder2Id}` }));
            subscribeChannels.current.push(`/topic/betfair_match_odds_update/${eventAccess?.overunder2Id}`);
          }

          if (eventAccess?.overunder3Status && !subscribeChannels.current.includes(`/topic/betfair_match_odds_update/${eventAccess?.overunder3Id}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/betfair_match_odds_update/${eventAccess?.overunder3Id}` }));
            subscribeChannels.current.push(`/topic/betfair_match_odds_update/${eventAccess?.overunder3Id}`);
          }

      }else if (eventAccess?.matchoddStatus) {
        if (!subscribeChannels.current.includes(`/topic/betfair_match_odds_update/${eventAccess?.matchoddId}`)) {
          ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/betfair_match_odds_update/${eventAccess?.matchoddId}` }));
          subscribeChannels.current.push(`/topic/betfair_match_odds_update/${eventAccess?.matchoddId}`);
        }
      }

        if (eventAccess.winningSeatStatus || eventAccess.oddeventStatus || eventAccess.wpmarketStatus || eventAccess.onlyoverStatus || (eventAccess.sessionStatus && eventAccess?.matchData.sportId == "4" && eventAccess?.matchData.eventId)) {
        const topic = `/topic/tommy_fancy_update/${eventAccess?.matchData.eventId}`;
        
        if (!subscribeChannels.current.includes(topic)) {
          ws.send(JSON.stringify({ action: 'subscribe', topic }));
          subscribeChannels.current.push(topic);
        }
      }else{
        // console.log("11111--FANCY Not Found");
        }

      heartbeatInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ action: 'ping' }));
        }
      }, 30000); // 30 seconds
    };
    let toSetData = {}
    ws.onmessage = (event) => {
      const { topic, marketData } = JSON.parse(event.data);
      if (marketData) {
        const parsedData = JSON.parse(marketData);
        if (topic.startsWith('/topic/tommy_bm_update/')) {
          // console.log('toSetData',otheBmMarketId.includes(`${topic.split('/').pop()}`),otheBmMarketId,`${topic.split('/').pop()}`,subscribeChannels.current, 'toSetDatatoSetDatatoSetDatatoSetData');
          if(otheBmMarketId.includes(`${topic.split('/').pop()}`)){
            toSetData[`${topic.split('/').pop()}`] = parsedData
            // console.log(toSetData, 'toSetDatatoSetDatatoSetData');
            
            setotherBM(toSetData)
          }else{
            if (eventAccess?.tossStatus) {
              if (eventAccess?.tossId.includes(topic.split('/').pop())) {
                setToas(parsedData);
              }else{
                setBookmaker(parsedData);
              }
            }else{
              setBookmaker(parsedData);
            }
          }
            

        } else if (topic.startsWith('/topic/betfair_match_odds_update/')) {          
          if (eventAccess?.matchoddId.includes(topic.split('/').pop())) {
            setMatchOdds(parsedData);
          }else if(otheMOMarketID.includes(`${topic.split('/').pop()}`)){
            // console.log(otheMOMarketID, 'otheMOMarketIDotheMOMarketID');
            
            toSetData[`${topic.split('/').pop()}`] = parsedData
            setotherBM(toSetData)
          } else {
              if(HGMarketIdsArray.includes(`${topic.split('/').pop()}`)){
                toSetData[`${topic.split('/').pop()}`] = parsedData
                // console.log(toSetData, 'toSetDatatoSetData');
                
                setMatchOdds2(toSetData);              
            }
            if(eventAccess?.overunder0Id.includes(topic.split('/').pop())){
              updateUnderOver(0,parsedData);
            }

            if(eventAccess?.overunder1Id.includes(topic.split('/').pop())){
              updateUnderOver(1,parsedData);
            }

            if(eventAccess?.overunder2Id.includes(topic.split('/').pop())){
              updateUnderOver(2,parsedData);
            }
            if(eventAccess?.overunder3Id.includes(topic.split('/').pop())){
              updateUnderOver(3,parsedData);
            }
          }
        } else if (topic.startsWith('/topic/tommy_fancy_update/')) {
          setFancy(parsedData);
        }
      }
    };

    ws.onclose = () => {
      console.log('11111--WebSocket disconnected');
      setConnected(false);
      clearInterval(heartbeatInterval);
      reconnectWebSocket();
    };

    ws.onerror = (error) => {
      console.log('11111--WebSocket error:', error);
      clearInterval(heartbeatInterval);
      reconnectWebSocket();
    };
  };

  const reconnectWebSocket = () => {
    console.log('11111--Reconnecting WebSocket...');
    setTimeout(() => {
      // unsubscribeAll();
      startWebSocket();
    }, 3000); // Reconnect after 3 seconds
  };

  const unsubscribeAll = () => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      subscribeChannels.current.forEach((topic) => {
        wsRef.current.send(JSON.stringify({ action: 'unsubscribe', topic }));
      });
      subscribeChannels.current = [];
    }
  };

  useEffect(() => {
    if (eventAccess) {
        // console.log('11111--eventAccess',eventAccess);
        
      startWebSocket();
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        // unsubscribeAll();
        clearInterval(heartbeatInterval);
      }
    };
  }, [eventAccess]);

  return null;
};

export default OddsFeedComponenttt;
