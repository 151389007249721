import React, { useState, useEffect,useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Container from "../../components/common/Container";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext";

export default function MyPlStatementEvent() {
    document.title = "PL Statement";
  const navigate = useNavigate();
    const initialized = useRef(false);
    const token = sessionStorage.getItem("token");
    const [plStatement, setPLStatement] = useState();
    const { eventname } = useParams();
  //   const { loggedIn } = useContext(PopupContext);
  //   const [isLoggedIn, setIsLoggedIn] = useState(() => {
  //     const storedValue = sessionStorage.getItem('loggedIn');
  //     return storedValue === "true";
  //   }); 
  //   useEffect(()=>{
  //     if(!isLoggedIn || loggedIn){
  //         navigate('/')
  //     }
  // },[isLoggedIn, loggedIn])

    const handleRowClick = (item) => {
      navigate(`/MyPlStatement/${item.event}/${item.match}`);
    };

    const callPLStatement = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getMyPLReport?eventname=${eventname}`, requestOptions)
          .then(async (response) => {
            let paresData = await response.json();
            setPLStatement(paresData);
            console.log("getMyPLReport - ", paresData);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            callPLStatement();
          }
      });

  return (

    <Container size='full'>
      <div className="account-sec">
      <div className="acount-header-filter">
        <div className="title-wraper">
          <h3 className="title back-icon" onClick={()=>navigate(-1)}><i className="fa-solid fa-arrow-left"></i> P/L Statement</h3>
        </div>
      </div>
      <div className="account-table">
      <table border="1" cellPadding="10" cellSpacing="0">
     <thead>
     <tr>
        <th className="match">Match</th>
        <th>Total Bets</th>
        <th>Win</th>
        <th>Lost</th>
        <th>Void</th>
        <th>Open</th>
        <th>Profit/Loss</th>
     </tr>
        </thead>
        <tbody>
      {plStatement && plStatement.data.map((item,index)=>(
        <tr key={index} onClick={() => handleRowClick(item)} style={{ cursor: "pointer" }}>
            <td>{item.match}</td>
            <td>{item.totalData}</td>
            <td>{item.win}</td>
            <td>{item.loss}</td>
            <td>{item.cancel}</td>
            <td>{item.open}</td>
            <td className={`${item.totalSumOfReturns > 0 ? 'green-txt' : 'red-txt'}`}>{item.totalSumOfReturns}</td>
        </tr>
      ))}
      </tbody>
      </table>
      </div>
      </div>
      </Container>
  )
}
