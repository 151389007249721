import React, { useContext, useEffect, useState } from 'react'
import './sidebarsportmenu.css'
import { PopupContext } from '../LoginPopup/LoginPopupContext';
import { Link, useNavigate } from 'react-router-dom';
import Tab_Cricket from '../../assets/images/tab_cricket.png';
import Tab_Election from '../../assets/images/tab_election.png';
import Tab_Football from '../../assets/images/tab_football.png';
import Tab_Grayhud from '../../assets/images/tab_grayhud.png';
import Tab_Horse from '../../assets/images/tab_horse.png';
import Tab_Kabaddi from '../../assets/images/tab_kabaddi.png';
import Tab_Tennis from '../../assets/images/tab_tennis.png';
import poker_chip from '../../assets/images/poker-chip.png';
import ezugi from '../../assets/images/ezugi.png';
import sexy from '../../assets/images/sexy.png';
import poker from '../../assets/images/poker.png';
import arcade_machine from '../../assets/images/arcade-machine.png';
import { Tab } from 'bootstrap';

function SidebarSportMenu() {
    const [activeMenu, setActiveMenu] = useState("");
    const [subActiveMenu, setSubActiveMenu] = useState("");

    const [mheight, setmheight] = useState("");
    const [hheight, sethheight] = useState("");

    const [apiH, setapiH] = useState();

    const navigate = useNavigate();
    const [menuList, setMenuList] = useState();
    const { openMSideMenu,closeMSideMenu,mSideMenu,mainHeight,headerHeight} = useContext(PopupContext);
    const handleClick = (index,sptIndex) => {
      let id = `${index}_${sptIndex}`
        if (id === activeMenu) {
          setActiveMenu();
        }else{
          setActiveMenu(id);
        }
        setSubActiveMenu();
      }
    const handleClick2 = (index,sptIndex,seriseIndex) => {
      let ids = `${index}_${sptIndex}_${seriseIndex}`
        if (subActiveMenu === ids) {
          setSubActiveMenu();
        }else{
          setSubActiveMenu(ids);
        }
      }

      const handleLink = (baseUrl) =>{
          window.location.href = baseUrl        
      }

      const adjustHeights = () => {
        window.addEventListener("resize", adjustHeights);
        const header = document.getElementById("header");
        const viewportHeight = window.innerHeight;
        const headerHeight = header.offsetHeight;
        sethheight(headerHeight)
        setmheight(viewportHeight - headerHeight);
      };

        const getMenuAPICall = () => {
              const myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");

              const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };

              fetch(
                `${process.env.REACT_APP_BASE_URL}/getSideMenu`,
                requestOptions
              )
                .then(async (response) => {
                    let paresData = await response.json();
                    setMenuList(paresData);
                })
                .catch((error) => {
                  console.log(error);
                });
            };

            useEffect(() => {
              let data = localStorage.getItem('logoData')
              let datas = JSON.parse(data)
              if (datas && datas.header_height) {
                setapiH(datas.header_height)
              }

              getMenuAPICall();
            }, []);

            const getImageLogo = (sportId) =>{
              if (sportId == 7) {
                return Tab_Horse;
              }else if (sportId == 4339) {
                return Tab_Grayhud;
              }else if (sportId == 400) {
                return Tab_Kabaddi;
              }else if (sportId == 500) {
                return Tab_Election;
              }else if (sportId == 2) {
                return Tab_Tennis;
              }else if (sportId == 1) {
                return Tab_Football;
              }else if (sportId == 4) {
                return Tab_Cricket;
              }else if (sportId == "indianCasino") {
                return poker_chip;
              }else if (sportId == "evolutionGaming") {
                return poker;
              }else if (sportId == "Ezugi") {
                return ezugi;
              }else if (sportId == "Spribe") {
                return arcade_machine;
              }else if (sportId == "SEXYBCRT") {
                return sexy;
              }else{
                return Tab_Cricket;
              }
            }


            const getHeight = () => {
              let header = document.getElementById("header");
              if (!header) {
                console.error("Element with ID 'header' not found.");
                return 56;
              }
              let viewportHeight = window.innerHeight;
              let headerHeight = header.offsetHeight;
              let h = viewportHeight - headerHeight; 
              if (mainHeight === h) {
                return mainHeight;
              }else{             
                return(viewportHeight - headerHeight);
              }
            }

    return (
      <div className={`sportsmenu-sec ${mSideMenu ? "open" : ''}`} style={{height:`${getHeight()}px`, ...(apiH && { top: `${apiH}px` })}}>
        <div className='sportmenu'>
              <div className='sport-overlay' onClick={()=>{
                if(mSideMenu){
                  closeMSideMenu();
                }else{
                  openMSideMenu();
                }
              }}></div>
            <button className='sport-close-menu' onClick={()=>{
                if(mSideMenu){
                  closeMSideMenu();
                }else{
                  openMSideMenu();
                }
              }}>X</button>

            {menuList && menuList.data.map((typeItem,index) =>(
              <div key={index} id={index} className='box'>
                <div className='title'>{typeItem.type}</div>
                  <ul>
                    {
                      typeItem.sport_Type.map((sportTypeItem,sptIndex)=>(
                        <li className={`dropdown ${activeMenu === `${index}_${sptIndex}` ? 'active' : ''}`} id={sptIndex} key={sptIndex}>
                          <span className='menu_link' onClick={()=>handleClick(index,sptIndex)}>
                          <img src={getImageLogo(sportTypeItem.Sport_Id)} alt={sportTypeItem.sport_type} />{sportTypeItem.sport_type}</span>
                          <ul className={`sport-dropdown-menu ${activeMenu === `${index}_${sptIndex}` ? 'active' : ''}`}>

                            {sportTypeItem.sportData.map((seriesItem,seriseIndex)=>(
                              <li key={seriseIndex} id={seriseIndex} className={`dropdown ${subActiveMenu === `${index}_${sptIndex}_${seriseIndex}` ? 'active' : ''}`}>
                                <span className='menu_link' onClick={()=>handleClick2(index,sptIndex,seriseIndex)}>{seriesItem.series}</span>
                                <ul className={`sport-dropdown-menu ${subActiveMenu === `${index}_${sptIndex}_${seriseIndex}` ? 'active' : ''}`}>
                                  {seriesItem.matchdata.map((matchItem,matchIndex)=>(
                                    <li key={matchIndex} id={matchIndex}>
                                      <span onClick={()=>handleLink(matchItem.baseUrl)} className='menu_link'>{matchItem.eventName}</span>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}

                        </ul>
                        </li>
                      ))
                    }
                  </ul>
            </div>
            ))}
            {/* <div className='box'>
                <div className='title'>Live Casino</div>
                <ul>
                  <li><span className="menu_link">Evolution Gaming</span></li>
                  <li><span className="menu_link">Ezugi</span></li>
                  <li><span className="menu_link">Spribe</span></li>
                  <li><span className="menu_link">SEXYBCRT</span></li>
                  <li><span className="menu_link">Super Spade Games</span></li>
                  <li><span className="menu_link">SuperNowa</span></li>
                  <li><span className="menu_link">KINGMAKER</span></li>
                  <li><span className="menu_link">Pragmatic Play</span></li>
                  <li><span className="menu_link">Bombay Live</span></li>
                  <li><span className="menu_link">TVBet</span></li>
                  <li><span className="menu_link">OneTouch</span></li>
                  <li><span className="menu_link">Playtech Live</span></li>
                  <li><span className="menu_link">RT</span></li>
                  <li><span className="menu_link">Asia Gaming</span></li>
                  <li><span className="menu_link">Betsoft</span></li>
                  <li><span className="menu_link">Beter Live</span></li>
                  <li><span className="menu_link">Evoplay Entertainment</span></li>
                  <li><span className="menu_link">Gamzix</span></li>
                  <li><span className="menu_link">Hacksaw Gaming</span></li>
                  <li><span className="menu_link">HORSEBOOK</span></li>
                  <li><span className="menu_link">Pragmatic Play Live</span></li>
                  <li><span className="menu_link">Smartsoft Gaming</span></li>
                  <li><span className="menu_link">VENUS</span></li>
              </ul>
            </div> */}
            {/* <div className='box'>
                <div className='title'>Racing Sports</div>
                  <ul>
                      <li> <a to="/"><img src='../images/horse.png' /> Horse Racing</a> </li>
                      <li> <a to="/"><img src='../images/dog.png' /> Greyhound Racing</a> </li>
                  </ul>
            </div> */}

            {/* <div className='box'>
                <div className='title'>All Sports</div>
                <ul>
                    <li onClick={handleClick} className={`dropdown ${isActive ? 'active' : ''}`}>
                     <a to="/"><img src='../images/cricket-ball.png' /> Cricket</a>
                      <span className='arrow'></span>
                        <ul className={`sport-dropdown-menu ${isActive ? 'active' : ''}`}>
                            <li className={`dropdown ${issubActive ? 'active' : ''}`}><a href='#'>Womens One Day Internationals</a> <span onClick={handleClick2} className='arrow'></span>
                              <ul className={`sport-dropdown-menu ${issubActive ? 'active' : ''}`}>
                                  <li><a href='#'>New Zealand v Sri Lanka</a></li>
                                  <li><a href='#'>New Zealand v Sri Lanka</a></li>
                                  <li><a href='#'>New Zealand v Sri Lanka</a></li>
                                  <li><a href='#'>New Zealand v Sri Lanka</a></li>
                                  <li><a href='#'>New Zealand v Sri Lanka</a></li>
                              </ul>
                            </li>
                        </ul>
                    </li>
                    <li> <a to="/"><img src='../images/football.png' /> Football</a> </li>
                    <li> <a to="/"><img src='../images/tennis.png' /> Tennis</a> </li>
                    <li> <a to="/"><img src='../images/mayor.png' /> Election</a> </li>
                    <li> <a to="/"><img src='../images/kabaddi.png' /> Kabaddi</a> </li>
                </ul>
            </div> */}
        </div>
        </div>
    )
}

export default SidebarSportMenu