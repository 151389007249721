// LoginPopup.js
import React, { useContext, useEffect, useState } from "react";
import "./login.css";
import { PopupContext } from "./LoginPopupContext";
import Popup from "../common/popup/Popup";
import { UserContext } from "../../UserContext";

import popupLogo from "../../assets/images/Royal77701.png";
import loginBanner from "../../assets/images/mymodelloginimg.png";
import { useNavigate } from "react-router-dom";
import ChangePasswordPopup from "../menudropdoenpopup/ChangePasswordPopup";
import ForgotPassPopup from "./ForgotPassPopup";

const LoginPopup = () => {
  const { isPopupOpen, closePopup, isLoggedIn } = useContext(PopupContext);
  const [loginSucessMsg, setLoginSucessMsg] = useState();
  const { sessionData, loggedIn } = useContext(UserContext);
  const navigate = useNavigate();
  const [uname, setUname] = useState();
  const [password, setPassword] = useState();
  const [errMsg, setErrmsg] = useState(false);
  const [msg, setMsg] = useState('');
  const { setSessionData: setGlobalsession } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [domainName, setDomainName] = useState('');
  const [signupPopup, setSignupPopup] = useState(false);

  useEffect(() => {
    const hostname = window.location.hostname;
    setDomainName(hostname);
    if (isPopupOpen) {
      document.body.classList.add('popup-open');
    } else {
      document.body.classList.remove('popup-open');
    }
    return () => {
      document.body.classList.remove('popup-open');
    };
  }, [isPopupOpen]);

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClick = () => {
    if (!uname || !password) {
      setErrmsg(true);
      const errMsgTime = setTimeout(() => {
        setErrmsg(false);
      }, 3000);
      return () => {
        clearTimeout(errMsgTime);
      };
    }

    const loginData = {
      userName: uname,
      password: password,
      whiteLabelName: window.location.hostname
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/userLogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Login success:", data);
        if (data.token && data.user && data.sessionId) {
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("sessionId", data.sessionId);
          sessionStorage.setItem("userinfo", JSON.stringify(data.user));
          sessionStorage.setItem("userId",data.user._id)
          sessionStorage.setItem("loggedIn", "true");
          sessionStorage.setItem('isOneClicked',data?.user?.oneclickstatus)
          sessionStorage.setItem('oneClickAmount',data?.user?.oneclickstack)
          sessionStorage.setItem('passwordchanged',data?.user?.passwordchanged)
          setGlobalsession(data.user);
          navigate("/");
          setLoginSucessMsg(true);
          isLoggedIn();
          closePopup();
        } else {
          setMsg(data.message)
          setErrmsg(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle login error
      });
  };

  const demoHandleClick = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/userLogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: "Demo" }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log('Login success:', data);
        if (data.token && data.user && data.sessionId) {
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("sessionId", data.sessionId);
          sessionStorage.setItem("userinfo", JSON.stringify(data.user));
          sessionStorage.setItem("userId",data.user._id)
          sessionStorage.setItem("loggedIn", "true");
          setGlobalsession(data.user);
          navigate("/");
          setLoginSucessMsg(true);
          isLoggedIn();
          closePopup();
        } else {
          setMsg(data.message)
          setErrmsg(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if(loginSucessMsg){
      const setloginsucessMsg = setTimeout(() => {
        setLoginSucessMsg(false);
      }, 2000);

      return () => {
        clearTimeout(setloginsucessMsg);
      };
    }
  }, [sessionData]);

  return (
    <>
      <div className={`popup login-popup ${isPopupOpen ? "open" : ""}`}>
        <div className="popup-content">
          <div className="loginpopup-content">
            <div className="lp-row">
            <div className="lp-col">
              <div className="login-banner">
                <img src={`https://admin.${domainName}/img/login_page.webp`} alt="" />
              </div>
            </div>
            <div className="lp-col">
              <div className="login-form-wraper">
                <div className="logo">
                  <img
                    className="myloginmodl-form-logo"
                    src={`https://admin.${domainName}/logo/${domainName}2.webp`}
                    alt=""
                  />
                </div>
                <form
                  method="post"
                  className="myloginmodl-form-dv"
                  onSubmit={handleSubmit}
                >

                  <div className="input-group">
                    <label>E-mail / username</label>
                    <input
                      type="text"
                      name="uname"
                      onChange={handleUnameChange}
                      autoComplete="current-uname"
                    />
                  </div>
                  <div className="input-group">
                    <label>Password</label>
                    <div className="input-with-icon">
                      <input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        onChange={handlePasswordChange}
                        autoComplete="current-password"
                      />
                      <span className="icon" onClick={() => setShowPassword(!showPassword)}>
                        <i className={`fa-solid ${
                      showPassword ? "fa-eye" : "fa-eye-slash"
                    }`}></i>
                      </span>
                    </div>
                  </div>
                  {JSON.parse(localStorage.getItem("isB2c") || "false") ? 
                    <p class="forgot-psw">Forget Password  <span onClick={()=>{ setSignupPopup(true); closePopup(); }}>Click Here</span></p>
                  : "" }
                  <div className="login-btns">
                    <button
                      type="submit"
                      className="btn loginbtnn"
                      onClick={handleClick}
                    >
                      LOGIN
                    </button>
                    <button
                      type="button"
                      className="btn demo-loginbtnn"
                      onClick={demoHandleClick}
                    >
                      DEMO LOGIN
                    </button>
                  </div>
                </form>
              </div>
            </div>
            </div>
            <button onClick={closePopup} className="btn login-close">
              BACK TO HOME
            </button>
          </div>
        </div>
      </div>

      <Popup
        popupClass={`alertMsgpopup ${loginSucessMsg ? "open" : ""}`}
        content={
          <div className={`alert-msg`}>
            <span className="msg">Login Successfully..</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
      <ForgotPassPopup closeSignPopup={()=>setSignupPopup(false)} isSignupPOPOpen={signupPopup} />
      <Popup
        popupClass={`alertMsgpopup Error ${errMsg ? "open" : ""}`}
        content={
          <div className={`alert-msg`}>
            <div className="close-btn" onClick={()=> setErrmsg(false)}>×</div>
            <span className="msg">{msg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
    </>
  );
};

export default LoginPopup;
