import React, { useState, useEffect, useRef, useContext } from "react";
import DatePicker from "react-datepicker";
import Container from '../../components/common/Container';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { Utils } from "../../Utils";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext";

export default function MyGameReport() {
  document.title = "Game Report";
  const initialized = useRef(false);
  const token = sessionStorage.getItem("token");
  const [gameReport, setGameReport] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedTypes, setSelectedTypes] = useState('All Reports');
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
//   const { loggedIn } = useContext(PopupContext);
//   const [isLoggedIn, setIsLoggedIn] = useState(() => {
//     const storedValue = sessionStorage.getItem('loggedIn');
//     return storedValue === "true";
//   });
  
//   useEffect(()=>{
//     if(!isLoggedIn || loggedIn){
//         navigate('/')
//     }
// },[isLoggedIn, loggedIn])

  const currentDate = new Date();
  const minToDate = fromDate ? new Date(fromDate.getTime() + 24 * 60 * 60 * 1000) : null;

  const toggleRow = (item) => {
    navigate(`/myGameReport/${item._id}`);
  };

  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("en-CA");
  };

  const formatDatefromAPI = (isoDate) => {
    const date = new Date(isoDate);

    const optionsDate = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const optionsTime = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // This ensures the time is in 12-hour format with AM/PM
    };

    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

    return `${formattedDate} at ${formattedTime}`;
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      style={{ cursor: "pointer" }}
      onClick={onClick}
      value={value}
      ref={ref}
      readOnly
      placeholder={Utils.getDateFormated}
    />
  ));

  const callGameReport = (fromDate, toDate, page) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getGameReport?fromDate=${formatDate(fromDate)}&toDate=${formatDate(toDate)}&page=${page}`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        if (paresData.data.length === 0) {
          setHasMore(false);
        }else{
          setGameReport(prevData => [...prevData, ...paresData.data]);
        }
        console.log("getGameReport - ", paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callGameReport(fromDate, toDate, currentPage);
    }
  });

  const handleLoadMore =()=>{
    setCurrentPage(currentPage+1)
    callGameReport(fromDate, toDate, currentPage+1);
  }


  return (
    <Container size='full'>
      <div className="account-sec">
      <div className="acount-header-filter">
        <div className="title-wraper">
          <span className="v-border"></span>
          <h3 className="title">GAME REPORTS</h3>
        </div>
        <div className="right-filter">
          <div className="input-box date-picker">
          <DatePicker
          selected={fromDate}
          onChange={(date) => {setCurrentPage(0); setFromDate(date); callGameReport(date, toDate, 0); }}
          dateFormat={Utils.getDateFormated}
          customInput={<CustomInput />}
          maxDate={currentDate}
        />
          </div>
          <div className="input-box date-picker">
          <DatePicker
          selected={toDate}
          onChange={(date) => { setCurrentPage(0); setToDate(date); callGameReport(fromDate, date, 0); }}
          dateFormat={Utils.getDateFormated}
          customInput={<CustomInput />}
          minDate={minToDate}
          maxDate={currentDate}
          disabled={!fromDate}
        />
          </div>
        </div>

      </div>

      <div className="account-table">
      <table border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr>
            <th>S.No</th>
            <th className="event">Event</th>
            <th>Market Count</th>
            <th>Bets</th>
            <th>Open</th>
            <th>Win</th>
            <th>Lost</th>
            <th>Cancel</th>
            <th>Profit/Loss</th>
          </tr>
        </thead>
        <tbody>
          {gameReport && gameReport.map((item, index) => (
              <tr key={index} onClick={() => toggleRow(item)} style={{ cursor: "pointer" }}>
                <td>{index+1}</td>
                <td>{item._id}</td>
                <td>{item.uniqueMarketCount}</td>
                <td>{item.totalData}</td>
                <td>{item.Open}</td>
                <td>{item.won}</td>
                <td>{item.loss}</td>
                <td>{item.Cancel}</td>
                <td className={`${item.sumOfReturns > 0 ? 'green-txt' : 'red-txt'}`}>{item.sumOfReturns}</td>
              </tr>
          ))}
        </tbody>
      </table>
      {hasMore ? <div className="load-more-btn-sec"><button className="btn" onClick={()=>handleLoadMore()}>Load more</button></div> :''}
      </div>
    </div>
    </Container>
  )
}
