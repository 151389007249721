import React, { useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Popup from "../common/popup/Popup";
import { useEffect } from "react";
import { PopupContext } from "../LoginPopup/LoginPopupContext";

function StackChnage({ isopen, handleClose }) {
  const token = sessionStorage.getItem("token");
  const stackLists = sessionStorage.getItem("stackList");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [stackList, setStackLists] = useState([]);
  const initialized = useRef(false);
  const [inputs, setInputs] = useState([...stackList]);
  const { setStackList } = useContext(PopupContext);
  

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getStackListAPI();
    }
    if (stackList !== undefined) {
      
    }
  }, [stackList]);

  const getStackListAPI = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      whiteLabelName: window.location.hostname ,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/getStakeLabes`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setStackLists(paresData.data);
        setInputs(paresData.data);
        sessionStorage.setItem("stackList",JSON.stringify(paresData.data))
        setStackList(JSON.stringify(paresData.data))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setStackListAPI = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
            "data": inputs
        });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/updateStake`, requestOptions)
      .then(async (response) => {
        if(response.ok){
            let paresData = await response.json();
            sessionStorage.setItem("stackList",JSON.stringify(paresData.data))
            setStackList(JSON.stringify(paresData.data))
            setIsUpdate(true);
            setTimeout(() => {
                setIsUpdate(false);
                handleClose();
            }, 2000)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (index, value) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = value;
    setInputs(updatedInputs);
  };

  return (
    <>
      <Modal
        show={isopen}
        onHide={handleClose}
        className="menu-dropdown-popup stack-chnage-popup"
        dialogClassName="v-center"
      >
        <Modal.Header closeButton>
          <h2>
            {" "}
            <span className="title-v-border"></span> SET BUTTON STAKE
          </h2>
        </Modal.Header>
        <Modal.Body>
          {(
            <div className="stack-change-wraper">
              <div className="input-box-set">
                <label>Value</label>

                {stackList.length > 0 && stackList.map((value, index) => (
                  <div key={index} className="input-box">
                  {/* {console.log(inputs[index],`----------------------${index}`)} */}
                    <input
                      type="number" // Always use text
                      value={inputs[index] === undefined ? value :inputs[index]}
                      onChange={(e) => handleInputChange(index, Number(e.target.value))}
                    />
                  </div>
                ))}
              </div>
              <div className="btn-sec">
                <button className="btn" onClick={setStackListAPI}>Save</button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Popup
        popupClass={`alertMsgpopup ${isUpdate ? "open" : ""}`}
        content={
          <div className={`alert-msg success`}>
            <span className="msg">Stack Update Successfully!!</span>
          </div>
        }
      />

      <Popup
        popupClass={`alertMsgpopup ${isError ? "open" : ""}`}
        content={
          <div className={`alert-msg err`}>
            <span className="msg">{errorMsg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
    </>
  );
}

export default StackChnage;
