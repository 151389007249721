import React, { useEffect, useContext, useState } from "react";
import Container from "../../components/common/Container";
import Banner from "../../components/common/Banner";
import { Link } from "react-router-dom";
import Allsportdata from "../../components/allsportdata/Allsportdata";
import exchangeBanner from "../../assets/images/Exchange_Page_top.png";
import sidebarCard1 from "../../assets/images/cas-1.png";
import sidebarCard2 from "../../assets/images/cas-2.png";
import sidebarCard3 from "../../assets/images/cas-3.png";
import FeatureEvent from "../../components/common/FeatureEvent/FeatureEvent";
import useSocket from '../../Websoket.js';
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext.js";

function Exchange() {
  const [matchdatas, setMatchData] = useState({});
  const [selectedTypes, setSelectedTypes] = useState("competitions");
  document.title = 'Exchange';
  const [endPoint, setEndPoint] = useState('All');
  const [activeTab, setActiveTab] = useState('All');
  const { isConnected, sendMessage,getAllBets } = useSocket();
  const [firstTimeCall, setFirstTimeCall] = useState(false)
  const { loggedIn } = useContext(PopupContext);

  useEffect(() => {    
          let intervalId;
          if (isConnected) {
              let matchdata = null;
              let userData = sessionStorage.getItem('userId')
              if(userData){
                  matchdata =  {
                      "id": userData
                  }
              }else{
                  matchdata = ""
              }
              if (!firstTimeCall) {
                  setFirstTimeCall(true)
                  sendMessage('AllOPenBets', matchdata);                
              }
              intervalId = setInterval(() => {
                  sendMessage('AllOPenBets', matchdata);
              }, 5000);
          }
          return () => {
              if (intervalId) {
                  clearInterval(intervalId);
              }
          };
      }, [isConnected, sendMessage, getAllBets]);


  const fetchData = (currentTab,selectedTypes) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/games/getExchangeData?type=${currentTab}&filter=${selectedTypes}`,
      requestOptions
    )
      .then(async (response) => {
        let paresData = await response.json();
        setMatchData(paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTypeChange = (event) => {
    setSelectedTypes(event.target.value);
    fetchData(endPoint,event.target.value);
  };

  const handleTab =(currentTab) => {
    setActiveTab(currentTab);
    let data = "All"
    if(currentTab === 'Cricket'){
        setEndPoint('cricket');
        data = 'cricket';
    }else if(currentTab === 'Football'){
        setEndPoint('football');
        data = 'football';
    }else if(currentTab === 'Tennis'){
        setEndPoint('tennis');
        data = 'tennis';
    }else if(currentTab === 'Election'){
        setEndPoint('election');
        data = 'election';
    }else if(currentTab === 'Kabaddi'){
        setEndPoint('kabaddi');
        data = 'kabaddi';
    }else if(currentTab === 'HorseRacing'){
        setEndPoint('horseracing');
        data = 'horseracing';
    }else if(currentTab === 'GreyhoundRacing'){
        setEndPoint('greyhound');
        data = 'greyhound';
    }if(currentTab === 'All'){
        setEndPoint('All');
        data = 'All';
    }
    fetchData(data,selectedTypes)
}

  useEffect(() => {
    fetchData(endPoint,selectedTypes);
  }, []);

  return (
    <>
      {matchdatas && (
        <Container size="full">
        <div className='mobile-sport-menu'>
    <ul>
        <li><span onClick={()=>handleTab("All")} className={`mobile-tab-btn ${activeTab === 'All' ? 'active' :''}`}><span className='icon'><img alt='' src="../images/trophy.png" /></span> All Sports</span> </li>
        <li><span onClick={()=>handleTab("Cricket")} className={`mobile-tab-btn ${activeTab === 'Cricket' ? 'active' :''}`}><span className='icon'><img alt='Cricket' src='../images/cricket-ball.png' /></span> Cricket</span> </li>
        <li><span onClick={()=>handleTab("Football")} className={`mobile-tab-btn ${activeTab === 'Football' ? 'active' :''}`}><span className='icon'><img alt='Football' src='../images/football.png' /></span> Football</span> </li>
        <li><span onClick={()=>handleTab("Tennis")} className={`mobile-tab-btn ${activeTab === 'Tennis' ? 'active' :''}`}><span className='icon'><img alt='Tennis' src='../images/tennis.png' /></span> Tennis</span> </li>
        <li><span onClick={()=>handleTab("Election")} className={`mobile-tab-btn ${activeTab === 'Election' ? 'active' :''}`}><span className='icon'><img alt='Election' src='../images/mayor.png' /></span> Election</span> </li>
        <li><span onClick={()=>handleTab("Kabaddi")} className={`mobile-tab-btn ${activeTab === 'Kabaddi' ? 'active' :''}`}><span className='icon'><img alt='Kabaddi' src='../images/kabaddi.png' /></span> Kabaddi</span> </li>
        <li><span onClick={()=>handleTab("HorseRacing")} className={`mobile-tab-btn ${activeTab === 'HorseRacing' ? 'active' :''}`}><span className='icon'><img alt='Horse Racing' src='../images/horse.png' /></span> Horse Racing</span> </li>
        <li><span onClick={()=>handleTab("GreyhoundRacing")} className={`mobile-tab-btn ${activeTab === 'GreyhoundRacing' ? 'active' :''}`}><span className='icon'><img alt='Greyhound Racing' src='../images/dog.png' /></span> Greyhound Racing</span> </li>
    </ul>
    </div>
          <div className="content-w-sidebar exchange-page">
            <div className="left-content">
            <div className="banner">
              <img src={`https://admin.${window.location.hostname}/img/Exchange_Page_top.webp`} style={{ display: activeTab === 'All' ? "block" : "none" }}/>
              <img src={`https://admin.${window.location.hostname}/img/Cricket_top.webp`} style={{ display: activeTab === 'Cricket' ? "block" : "none" }}/>
              <img src={`https://admin.${window.location.hostname}/img/football_top.webp`} style={{ display: activeTab === 'Football' ? "block" : "none" }}/>
              <img src={`https://admin.${window.location.hostname}/img/Tennis_Top.webp`} style={{ display: activeTab === 'Tennis' ? "block" : "none" }}/>
              <img src={`https://admin.${window.location.hostname}/img/election_top.webp`} style={{ display: activeTab === 'Election' ? "block" : "none" }}/>
              <img src={`https://admin.${window.location.hostname}/img/kabbadi_top.webp`} style={{ display: activeTab === 'Kabaddi' ? "block" : "none" }}/>
              <img src={`https://admin.${window.location.hostname}/img/horse_top.webp`} style={{ display: activeTab === 'HorseRacing' ? "block" : "none" }}/>
              <img src={`https://admin.${window.location.hostname}/img/greyhound_top.webp`} style={{ display: activeTab === 'GreyhoundRacing' ? "block" : "none" }}/>
            </div>
              <div className="filter-btn-wrapper">
                <FeatureEvent />
                <div className="filter-btn">
                  <span>
                    View by:
                  </span>
                  <select
                    name="filterby"
                    id="filterby"
                    value={selectedTypes}
                    onChange={handleTypeChange}
                  >
                    <option value="competitions" selected="">
                      Competitions
                    </option>
                    <option value="time">Time</option>
                  </select>

                </div>
              </div>
              <div className='allsport-tab'>
            <span onClick={()=>handleTab("All")} className={`tab-btn ${activeTab === 'All' ? 'active' :''}`}><img alt='All Sports' src='../images/trophy.png' /> All Sports</span>
            <span onClick={()=>handleTab("Cricket")} className={`tab-btn ${activeTab === 'Cricket' ? 'active' :''}`}><img alt='Cricket' src='../images/cricket-ball.png' /> Cricket</span>
            <span onClick={()=>handleTab("Football")} className={`tab-btn ${activeTab === 'Football' ? 'active' :''}`}><img alt='Football' src='../images/football.png' /> Football</span>
            <span onClick={()=>handleTab("Tennis")} className={`tab-btn ${activeTab === 'Tennis' ? 'active' :''}`}><img alt='Tennis' src='../images/tennis.png' />  Tennis</span>
            <span onClick={()=>handleTab("Election")} className={`tab-btn ${activeTab === 'Election' ? 'active' :''}`}><img alt='Election' src='../images/mayor.png' /> Election</span>
            <span onClick={()=>handleTab("Kabaddi")} className={`tab-btn ${activeTab === 'Kabaddi' ? 'active' :''}`}><img alt='Kabaddi' src='../images/kabaddi.png' /> Kabaddi</span>
            <span onClick={()=>handleTab("HorseRacing")} className={`tab-btn ${activeTab === 'HorseRacing' ? 'active' :''}`}><img alt='Horse Racing' src='../images/horse.png' /> Horse Racing</span>
            <span onClick={()=>handleTab("GreyhoundRacing")} className={`tab-btn ${activeTab === 'GreyhoundRacing' ? 'active' :''}`}><img alt='Greyhound Racing' src='../images/dog.png' /> Greyhound Racing</span>
        </div>
              <Allsportdata matchData={matchdatas} selectedTypes={selectedTypes} />
            </div>
            <div className="right-content sidebar">
              <div className="sidebar-box">
                <div className="title">All Casino</div>
                <div className="sidebar-card">
                  <Link to="/Royal_casino">
                    {" "}
                    <img src={`https://admin.${window.location.hostname}/sliderImages/RoyalGamings.webp`} />
                  </Link>
                  <Link to="/live_casino">
                    {" "}
                    <img src={`https://admin.${window.location.hostname}/sliderImages/Ezugi.webp`} />
                  </Link>
                  <Link to="/slots">
                    {" "}
                    <img src={`https://admin.${window.location.hostname}/sliderImages/Slots.webp`} />
                  </Link>
                  <Link to="/live_casinoInPlay/65b75f62101c7f8b01f9031a">
                    {" "}
                    <img src={`https://admin.${window.location.hostname}/sliderImages/Aviator.webp`} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default Exchange;
