export const initializeOTPless = (callback) => {
    const OTPlessSignin = new window.OTPless(callback);
  
    // Function to send OTP
    const sendOTP = (phone, countryCode, callbacks) => {
      try {
        OTPlessSignin.initiate({
          channel: 'PHONE',
          phone: phone,
          countryCode: countryCode,
        });
        console.log("OTP Sent to:", { phone, countryCode });
        // On success, execute the onSuccess callback
        callbacks.onSuccess();
      } catch (error) {
        console.error("Error during OTP sending:", error.message);
        // On error, execute the onError callback
        callbacks.onError(error.message || "An unexpected error occurred!");
      }
    };
  
    // Function to verify OTP
    const verifyOTP = async (phone, otpcode, countryCode, callbacks) => {
      try {
        const res = await OTPlessSignin.verify({
          channel: 'PHONE',
          phone: phone,
          otp: otpcode,
          countryCode: countryCode,
        });
  
        console.log(res, "verifyOTP response");
  
        if (res.success) {
          callbacks.onSuccess();
        } else {
          callbacks.onError(res.response.errorMessage || "Incorrect OTP!");
        }
      } catch (error) {
        console.error("Error during OTP verification:", error.message);
        callbacks.onError(error.message || "An unexpected error occurred!");
      }
    };
  
    return { sendOTP, verifyOTP };
  };