import React, { useContext, useState } from 'react'
import './gridboxs.css'
import { Link, useNavigate } from 'react-router-dom'
import { PopupContext } from '../../LoginPopup/LoginPopupContext';

function GridBoxs({ GridData, title, className }) {
   const { openPopup, loggedIn } = useContext(PopupContext);
   const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
   const navigate = useNavigate();
   const handleHomePopup = () => {
      if (isLoggedIn || loggedIn) {
         navigate('/')
      }
      else {
         openPopup()
      }
   }
   return (
      <div className={className}>
         {GridData?.map((item, index) => (
            <div className="col" key={index}>
            {title === "FEATURED SPORTS" ? (
                <Link to={`${item.url}`}>
                    <img src={`https://admin.${window.location.hostname}/sliderImages/${item.name}.webp`} alt={item.name} />
                </Link>
            ) : (
                isLoggedIn || loggedIn ? (
                    <Link to={`${item.url}`}>
                        <img src={`https://admin.${window.location.hostname}/sliderImages/${item.name}.webp`} alt={item.name} />
                    </Link>
                ) : (
                    <Link onClick={handleHomePopup}>
                        <img src={`https://admin.${window.location.hostname}/sliderImages/${item.name}.webp`} alt={item.name} />
                    </Link>
                )
            )}
        </div>
         ))}
      </div>
   )
}

export default GridBoxs