import React from 'react'

function Banner({image, className}) {
    const bannerCls = className ? `banner ${className}` : 'banner';
  return (
    <div className={bannerCls}>
        <img src={image}/>
    </div>
  )
}

export default Banner