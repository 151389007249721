// LoginPopup.js
import React, { useContext, useEffect, useState } from "react";
import "./login.css";
import Popup from "../common/popup/Popup";
import { UserContext } from "../../UserContext";
import { useNavigate } from "react-router-dom";
import OtpInput from "./OtpInput";
import { callback } from "../../callback";
import { initializeOTPless } from "../../otpUtils";
import axios from "axios";

const ForgotPassPopup = ({isSignupPOPOpen,closeSignPopup}) => {
  const [isVerifed, setisVerifed] = useState(false);
  const [isUsernameEnter, setIsUsernameEnter] = useState(false);

  const [loginSucessMsg, setLoginSucessMsg] = useState();
  const { sessionData, loggedIn } = useContext(UserContext);
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [isOTPsend, setisOTPsend] = useState(false);

  const [agreeMsg, setAgreeMsg] = useState(false);

  const [mobileNo, setMobileNo] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState();
  const [errMsg, setErrmsg] = useState(false);
  const [isSuccessMsg, setIsSuccessMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const { setSessionData: setGlobalsession } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [domainName, setDomainName] = useState('');

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30); // Timer in seconds
  const [canResend, setCanResend] = useState(false); // Flag to enable/disable resend
  const abortController = new AbortController();
  const { sendOTP,verifyOTP } = initializeOTPless(callback);

  const handleAbort = () => {
    console.log("Aborting fetch request!");
    abortController.abort();
  };

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear the error as user types
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }; 

  useEffect(() => {
    const hostname = window.location.hostname;
    setDomainName(hostname);
    if (isSignupPOPOpen) {
      document.body.classList.add('popup-open');
    } else {
      document.body.classList.remove('popup-open');
    }
    return () => {
      document.body.classList.remove('popup-open');
    };
  }, [isSignupPOPOpen]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleAgreeChange = (e) => {
    setAgree(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleUsernameSubmit = (e) => {
    e.preventDefault();


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const raw = JSON.stringify({
      userName: username
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/getUserDetails`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.status != "Error") {
        // setIsSuccessMsg(true);
        // setMsg("Successfully Signup!")
        // setInterval(() => {
        //   setIsSuccessMsg(false);
        //   setMsg("");
        // }, 2000);
        // handleCloses();

        setIsUsernameEnter(true);
        setMobileNo(data.contactNumber);
      } else {
        setMsg(data.message)
        setErrmsg(true);
        setInterval(() => {
          setErrmsg(false);
          setMsg("");
        }, 2000);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      // Handle login error
    });


    
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.password) newErrors.password = "Password is required";
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    return newErrors;
  };

  const handleRSubmit = async (e) => {
    e.preventDefault();
    handleAbort();
  
    // Validate form and set errors
    const newErrors = validateForm();
    setErrors(newErrors);
  
    // If no errors, proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      const requestData = {
        whiteLabelName: window.location.hostname,
        password: formData.password,
        passwordConfirm: formData.confirmPassword,
        userName: username,
        contectNumber: sessionStorage.getItem('mobileNo'),
      };
  
      try {
        // Use await with axios to handle the API request
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/users/forgetPasswordUser`, requestData, {
          headers: {
            "Content-Type": "application/json",
          }
        });
  
        const data = response.data;
        console.log("createUser :", data);
  
        if (data.status !== "Error") {
          setIsSuccessMsg(true);
          setMsg("Successfully Update Password!!");
          setInterval(() => {
            setIsSuccessMsg(false);
            setMsg("");
          }, 2000);
          handleCloses();
        } else {
          setMsg(data.message);
          setErrmsg(true);
          setInterval(() => {
            setErrmsg(false);
            setMsg("");
          }, 2000);
        }
      } catch (error) {
        // Handle errors if the API call fails
        console.error("Error:", error);
        // Optionally, handle the error further (e.g., display an error message to the user)
      }
    } else {
      // Focus the first field with an error
      const firstErrorField = Object.keys(newErrors)[0];
      document.getElementsByName(firstErrorField)[0].focus();
    }
  };
  

  useEffect(() => {
    if(loginSucessMsg){
      const setloginsucessMsg = setTimeout(() => {
        setLoginSucessMsg(false);
      }, 2000);

      return () => {
        clearTimeout(setloginsucessMsg);
      };
    }
  }, [sessionData]);

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
    console.log("Updated OTP:", newOtp); // OTP value is updated here
  };
  
  const VerifyOTPfun = () => {
    verifyOTP(mobileNo, otp, '+91', {
      onSuccess: () => {
        setisVerifed(true);
        sessionStorage.setItem('mobileNo', mobileNo);
        console.log("OTP verified successfully!");
      },
      onError: (errorMessage) => {
        setErrmsg(true);
        setMsg(errorMessage);
        setTimeout(() => {
          setErrmsg(false);
          setMsg("");
        }, 2000);
        console.error("OTP verification failed:", errorMessage);
      }
    });
  }

  const OTPSend =() =>{
   const phoneRegex = /^[0-9]{10}$/; // Matches exactly 10 digits
   if (!phoneRegex.test(mobileNo)) {
    setAgreeMsg("Please enter a valid 10-digit mobile number.")
      setInterval(() => {
        setAgreeMsg();
      }, 5000);
   } else {
    if (agree) {    
      sendOTP(mobileNo,'+91', {
        onSuccess: () => {
          setisOTPsend(true);
          console.log("OTP sent successfully!");
        },
        onError: (errorMessage) => {
          console.error("OTP sending failed:", errorMessage);
        }
      });
    }else{
      setAgreeMsg("To continue, kindly check the box to agree to our terms and conditions.")
      setInterval(() => {
        setAgreeMsg();
      }, 5000);
    }
   }
  }

  const handleCloses = () => {
    closeSignPopup();
    setIsUsernameEnter(false);
    setisVerifed(false);
    setisOTPsend(false);
    setCanResend(false);
    setUsername("");
    setMobileNo("");
    setAgree(false);
    setAgreeMsg(false);
    setFormData({}); // Reset form data
    setErrors({}); // Reset errors
    setOtp("");
    setTimer(30); // Reset timer
  }

  useEffect(() => {
    let interval;

    if (isOTPsend && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1); // Decrement timer every second
      }, 1000);
    } else if (timer === 0 && isOTPsend) {
      setCanResend(true); // Allow resending OTP
    }

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [timer, isOTPsend]);

  const handleResend = () => {
    console.log("OTP resent!");
    OTPSend();
    setTimer(30); // Reset the timer to 30 seconds
    setCanResend(false); // Disable the button/text
  };

  return (
    <>
      <div className={`popup login-popup signup-popup ${isSignupPOPOpen ? "open" : ""}`}>
        <div className="popup-content">
          <div className="loginpopup-content">
            <div className="lp-row">
            <div className="lp-col">
              <div className="login-form-wraper">
                <div className="logo">
                  <img
                    className="myloginmodl-form-logo"
                    src={`https://admin.${domainName}/logo/${domainName}2.webp`}
                    alt=""
                  />
                </div>
                {!isUsernameEnter ? <form
                  method="post"
                  className="myloginmodl-form-dv"
                  onSubmit={handleUsernameSubmit}
                >
                  <div className="input-group">
                    <label>Username</label>
                    <input
                      type="text"
                      name="username"
                      onChange={handleUsernameChange}
                      value={username}
                      required
                    />
                  </div>

                  <div className="login-btns">
                    <button
                      type="submit"
                      className="btn loginbtnn"
                      onClick={handleUsernameSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form> :'' }

                {!isVerifed && isUsernameEnter ? <form
                  method="post"
                  className="myloginmodl-form-dv"
                  onSubmit={handleSubmit}
                >
                  <div className="input-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      name="mobileNumber"
                      placeholder="e.g. +1234567890"
                      value={mobileNo}
                      style={{caretColor: "transparent",cursor:'not-allowed'}}
                      required
                      disabled={isOTPsend}
                      autoComplete="tel"
                    />
                  </div>

                  {isOTPsend ? <OtpInput onOtpChange={handleOtpChange}  />:''}

                  {isOTPsend ? (
                    canResend ? (
                      <button className="btn loginbtnn" onClick={handleResend}>Resend OTP</button>
                    ) : (
                      `Resend OTP in ${timer} seconds`
                    )
                  ) : ''}

                  {!isOTPsend ? <div className="input-group checkbox">
                    <input
                      type="checkbox"
                      name="agree"
                      id="checkbox1"
                      onChange={handleAgreeChange}
                    /><label for="checkbox1">I agree to the all terms & Conditions</label>
                  </div> : "" }
   
                  {agreeMsg && <div><span>{agreeMsg}</span></div>}
                  <div className="login-btns">
                  {isOTPsend ? <button
                      type="submit"
                      className="btn loginbtnn"
                      onClick={VerifyOTPfun}>
                      Verify OTP
                      </button> 
                    :<button
                      type="submit"
                      className="btn loginbtnn"
                      onClick={OTPSend}
                    >
                      Send OTP
                    </button>}
                    
                  </div>
                </form> :'' }
                
                {isVerifed ? <form className="myloginmodl-form-dv signup-form" onSubmit={handleRSubmit}>
                  <div className="row">
                  <div className="input-group col-12">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    {errors.password && <span className="error">{errors.password}</span>}
                  </div>
                  <div className="input-group col-12">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                     {errors.confirmPassword && (
                      <span className="error">{errors.confirmPassword}</span>
                      )}
                  </div>
                  </div>
                  <button type="submit" className="btn loginbtnn">Update Password</button> 
                </form> :''}
                
              </div>
            </div>
            </div>
            <button onClick={()=>handleCloses()} className="btn login-close">
              BACK TO HOME
            </button>
          </div>
        </div>
      </div>

      <Popup
        popupClass={`alertMsgpopup ${isSuccessMsg ? "open" : ""}`}
        content={
          <div className={`alert-msg`}>
             <div className="close-btn" onClick={()=> setIsSuccessMsg(false)}>×</div>
            <span className="msg">{msg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
      <Popup
        popupClass={`alertMsgpopup Error ${errMsg ? "open" : ""}`}
        content={
          <div className={`alert-msg`}>
            <div className="close-btn" onClick={()=> setErrmsg(false)}>×</div>
            <span className="msg">{msg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
    </>
  );
};

export default ForgotPassPopup;
