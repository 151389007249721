import React, { useEffect, useState } from "react";
import './hometab.css'
import HorseRacing from '../../assets/images/HorseRacing.svg'
import greyhound from '../../assets/images/greyhound.svg'
import election from "../../assets/images/election2.svg";
import kabaddi from "../../assets/images/kabaddi.svg";
import Allsportdata from "../allsportdata/Allsportdata";
function HomeTab() {
    const [activeTab, setActiveTab] = useState("tab1"); // Initial active tab
    const [matchdata, setMatchData] = useState([]);
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/games/getExchangeData?type=`
    let endPoint = 'cricket';

    const handleTabClick = (tab) => {
        setActiveTab(tab); // Update the active tab on click

        if(tab === 'tab1'){
            endPoint = 'cricket';
        }else if(tab === 'tab2'){
            endPoint = 'football';
        }else if(tab === 'tab3'){
            endPoint = 'tennis';
        }else if(tab === 'tab4'){
            endPoint = 'election';
        }else if(tab === 'tab5'){
            endPoint = 'kabaddi';
        }else if(tab === 'tab6'){
            endPoint = 'horseracing';
        }else if(tab === 'tab7'){
            endPoint = 'greyhound';
        }
        fetchData()
    };

    const fetchData = async () => {
        try {
            const response = await fetch(`${url}${endPoint}`);
            const data = await response.json();
            setMatchData(data);
        } catch (error) {
            console.error('Error fetching API data:', error);
        }
    };

    useEffect(() => {
        // Fetch API data here
        fetchData();
    }, []);

    return (
        <div className="home-tab">
            <div className="tab-header">
                <button className={`tab-btn ${activeTab === "tab1" ? "active" : ""}`} onClick={() => handleTabClick("tab1")} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M15.8916 4.10831C14.32 2.59032 12.2149 1.75037 10.03 1.76936C7.84499 1.78834 5.75489 2.66475 4.20982 4.20982C2.66475 5.75489 1.78834 7.84499 1.76936 10.03C1.75037 12.2149 2.59032 14.32 4.10831 15.8916C5.67999 17.4096 7.78501 18.2496 9.96999 18.2306C12.155 18.2116 14.2451 17.3352 15.7901 15.7901C17.3352 14.2451 18.2116 12.155 18.2306 9.96999C18.2496 7.78501 17.4096 5.67999 15.8916 4.10831ZM5.28331 5.28331C6.22222 4.34401 7.42027 3.70655 8.72387 3.45266C10.0275 3.19877 11.3772 3.34003 12.6 3.85831L12.3583 4.10831L12.95 4.69998L13.3833 4.25831C13.6249 4.40409 13.8586 4.56265 14.0833 4.73331L4.73331 14.0833C4.56265 13.8586 4.40409 13.6249 4.25831 13.3833L4.69998 12.95L4.10831 12.3583L3.86664 12.6C3.34732 11.378 3.20472 10.0288 3.45712 8.7252C3.70951 7.42165 4.34541 6.22315 5.28331 5.28331ZM14.7166 14.7166C13.7777 15.6559 12.5797 16.2934 11.2761 16.5473C9.97248 16.8012 8.62276 16.6599 7.39998 16.1416L7.64164 15.8916L7.04998 15.3L6.61664 15.7416C6.37504 15.5959 6.14137 15.4373 5.91664 15.2666L15.2666 5.91664C15.4373 6.14137 15.5959 6.37504 15.7416 6.61664L15.3 7.04998L15.8916 7.64164L16.1333 7.39998C16.6526 8.62197 16.7952 9.9712 16.5428 11.2748C16.2904 12.5783 15.6545 13.7768 14.7166 14.7166ZM9.40831 12.95L9.99998 13.5333L8.82498 14.7166L8.23331 14.1666L9.40831 12.95ZM11.7666 10.5916L12.3583 11.175L11.175 12.3583L10.5916 11.7666L11.7666 10.5916ZM14.1666 8.23331L14.7583 8.82498L13.5333 9.99998L12.95 9.40831L14.1666 8.23331ZM10.5916 7.04998L9.99998 6.46664L11.175 5.28331L11.7666 5.87498L10.5916 7.04998ZM8.23331 9.40831L7.64164 8.82498L8.82498 7.64164L9.40831 8.23331L8.23331 9.40831ZM5.87498 11.7666L5.28331 11.175L6.46664 9.99998L7.04998 10.5916L5.87498 11.7666Z" fill="black"></path></svg>Cricket
                </button>

                <button className={`tab-btn ${activeTab === "tab2" ? "active" : ""}`}
                    onClick={() => handleTabClick("tab2")} id="footbal-li"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10 1.875C12.1549 1.875 14.2215 2.73102 15.7452 4.25476C17.269 5.77849 18.125 7.84512 18.125 10C18.125 12.1549 17.269 14.2215 15.7452 15.7452C14.2215 17.269 12.1549 18.125 10 18.125C7.84512 18.125 5.77849 17.269 4.25476 15.7452C2.73102 14.2215 1.875 12.1549 1.875 10C1.875 7.84512 2.73102 5.77849 4.25476 4.25476C5.77849 2.73102 7.84512 1.875 10 1.875ZM8.545 3.27875L10 4.25L11.455 3.28C10.496 3.07321 9.50398 3.07321 8.545 3.28V3.27875ZM7.05375 3.78625C6.14171 4.22028 5.33608 4.84925 4.69375 5.62875L5.5025 8.25875L6.76 8.6775L9.375 6.69V5.335L7.05375 3.78625ZM3.81 7.00625C3.46445 7.71843 3.24535 8.48526 3.1625 9.2725L4.26875 8.49875L3.81 7.00625ZM3.17 10.7938C3.295 11.875 3.67 12.8813 4.23625 13.75H6.61625L7.41 12.9562L6.38125 9.86875L5.10125 9.4425L3.17 10.7925V10.7938ZM5.2825 15C5.78625 15.4775 6.36375 15.8787 6.995 16.185L6.47125 15H5.28375H5.2825ZM8.6075 16.7338C9.52621 16.9228 10.4738 16.9228 11.3925 16.7338L12.38 14.5138L11.6163 13.75H8.38375L7.62125 14.5125L8.6075 16.7338ZM13.0037 16.185C13.6322 15.8793 14.2105 15.4797 14.7187 15H13.5312L13.0037 16.185ZM15.7625 13.75C16.3125 12.9075 16.6825 11.9375 16.8175 10.8938L14.8875 9.44625L13.6188 9.86875L12.59 12.9562L13.3837 13.75H15.7625ZM16.845 9.3525C16.7689 8.53061 16.5444 7.72933 16.1825 6.9875L15.7238 8.5125L16.845 9.3525ZM15.29 5.60875C14.6503 4.83838 13.8505 4.21648 12.9463 3.78625L10.625 5.335V6.69L13.24 8.6775L14.495 8.26L15.29 5.60875ZM7.61 9.6L8.575 12.5H11.425L12.39 9.60125L10 7.785L7.61 9.6025V9.6Z" fill="black"></path></svg>Football</button>
                <button className={`tab-btn ${activeTab === "tab3" ? "active" : ""}`}
                    onClick={() => handleTabClick("tab3")} id="tennis-li"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.8925 15.8926C19.1467 12.6384 19.1467 7.36178 15.8925 4.10762C12.6383 0.853451 7.36166 0.853451 4.1075 4.10762C0.853329 7.36178 0.853329 12.6384 4.1075 15.8926C7.36166 19.1468 12.6383 19.1468 15.8925 15.8926ZM16.6192 9.20095C16.4423 7.7189 15.7712 6.33968 14.7142 5.28595C13.6565 4.22509 12.2712 3.55312 10.7833 3.37928L10.775 3.42595C10.4367 5.25035 9.55327 6.92933 8.24124 8.24136C6.9292 9.5534 5.25023 10.4368 3.42583 10.7751L3.37916 10.7835C3.553 12.2713 4.22496 13.6567 5.28583 14.7143C6.33956 15.7714 7.71878 16.4425 9.20083 16.6193L9.21083 16.5609C9.54896 14.7364 10.4323 13.0572 11.7444 11.745C13.0564 10.4328 14.7355 9.54929 16.56 9.21095L16.6183 9.20095H16.6192ZM16.56 11.191V10.9126C15.1828 11.2276 13.9223 11.9245 12.9234 12.9235C11.9244 13.9225 11.2274 15.1829 10.9125 16.5601H11.1908C12.5264 16.3195 13.7561 15.6753 14.7142 14.7143C15.6752 13.7562 16.3195 12.5265 16.56 11.191ZM3.42666 9.07428V8.88845C3.65543 7.52308 4.30549 6.26345 5.28583 5.28595C6.26325 4.30536 7.52288 3.655 8.88833 3.42595H9.07333C8.75839 4.80315 8.06142 6.06361 7.06246 7.06258C6.06349 8.06155 4.80303 8.75852 3.42583 9.07345L3.42666 9.07428Z" fill="black"></path></svg>Tennis</button>
                
                <button className={`tab-btn ${activeTab === "tab4" ? "active" : ""}`}
                    onClick={() => handleTabClick("tab4")} id="election-li"><img src={election} />Election</button>
                <button className={`tab-btn ${activeTab === "tab5" ? "active" : ""}`}
                    onClick={() => handleTabClick("tab5")} id="kabaddi-li"><img src={kabaddi} />Kabaddi</button>
                    <button className={`tab-btn ${activeTab === "tab6" ? "active" : ""}`}
                        onClick={() => handleTabClick("tab6")} id="horse-li">
                            <img src={HorseRacing} />
                        Horse Racing
                    </button>
                    <button className={`tab-btn ${activeTab === "tab7" ? "active" : ""}`}
                        onClick={() => handleTabClick("tab7")} id="grey-li">
                        <img src={greyhound} />
                        GreyHound
                    </button>
            </div>
            <div className="tab-content">
                <div className={`tab-panel ${activeTab === "tab1" ? "active" : ""}`}>
                <Allsportdata  matchData={matchdata}/>
                </div>
                <div className={`tab-panel ${activeTab === "tab2" ? "active" : ""}`}><Allsportdata  matchData={matchdata}/></div>
                <div className={`tab-panel ${activeTab === "tab3" ? "active" : ""}`}><Allsportdata  matchData={matchdata}/></div>
                <div className={`tab-panel ${activeTab === "tab4" ? "active" : ""}`}><Allsportdata  matchData={matchdata}/></div>
                <div className={`tab-panel ${activeTab === "tab5" ? "active" : ""}`}><Allsportdata  matchData={matchdata}/></div>
                <div className={`tab-panel ${activeTab === "tab6" ? "active" : ""}`}><Allsportdata  matchData={matchdata}/></div>
                <div className={`tab-panel ${activeTab === "tab7" ? "active" : ""}`}><Allsportdata  matchData={matchdata}/></div>
            </div>
        </div>
    );
}

export default HomeTab;
