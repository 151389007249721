import React, { useState, useEffect, useRef, useContext } from "react";
import Container from '../../components/common/Container';
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useNavigate } from "react-router-dom";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext";

export default function MyGameReportEvent() {
  document.title = "Game Report";
  const initialized = useRef(false);
  const token = sessionStorage.getItem("token");
  const [gameReport, setGameReport] = useState();
  const { eventname } = useParams();
  const navigate = useNavigate();
  // const { loggedIn } = useContext(PopupContext);
  // const [isLoggedIn, setIsLoggedIn] = useState(() => {
  //   const storedValue = sessionStorage.getItem('loggedIn');
  //   return storedValue === "true";
  // });
  
  //   useEffect(()=>{
  //       if(!isLoggedIn || !loggedIn){
  //           navigate('/')
  //       }
  //   },[isLoggedIn, loggedIn])

  const toggleRow = (item) => {
    navigate(`/myGameReport/${item.event}/${item.match}`);
  };

  const callGameReport = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getEventWiseReport?compName=${eventname}`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setGameReport(paresData);
        console.log("getGameReport - ", paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callGameReport();
    }
  });

  return (
    <Container size='full'>
      <div className="account-sec">
      <div className="acount-header-filter">
        <div className="title-wraper">
          <h3 className="title" onClick={()=>navigate(-1)}><i className="fa-solid fa-arrow-left"></i>{eventname}</h3>
        </div>
      </div>

      <div className="account-table">
      <table border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr>
            <th>Match</th>
            <th>Bets</th>
            <th>Win</th>
            <th>Lost</th>
            <th>Void</th>
            <th>Open</th>
            <th>Profit/Loss</th>
          </tr>
        </thead>
        <tbody>
          {gameReport && gameReport.data.map((item, index) => (
              <tr key={index} onClick={() => toggleRow(item)} style={{ cursor: "pointer" }}>
                <td>{item.match}</td>
                <td>{item.totalData}</td>
                <td>{item.win}</td>
                <td>{item.loss}</td>
                <td>{item.cancel}</td>
                <td>{item.open}</td>
                <td className={`${item.totalSumOfReturns > 0 ? 'green-txt' : 'red-txt'}`}>{item.totalSumOfReturns}</td>
              </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
    </Container>
  )
}
