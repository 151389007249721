import React, { useContext, useEffect, useRef, useState } from "react";
import useSocket from '../../Websoket.js';
import { convertToOriginalValue } from "../../Utils";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext.js";
import betslipclosearrow from '../../assets/images/betslip-close-arrow.png'
import Popup from "../../components/common/popup/Popup.js";
import ExchanngeinfoPopup from "../../components/matchData/ExchanngeinfoPopup.js";

export default function MultiMarket({ initialData }) {
    document.title = 'Multi Market';
  const token = sessionStorage.getItem("token"); // get token from session storage to make API call to get data for MultiMarket page
  const [data, setData] = useState(initialData);
  const [multimarketArrrrr, setMultimarketArrrrr] = useState([])
  const [eidmid, setEidMid] = useState([])
  const { isConnected, receivedMultiMarket, receivedMultiMarketUpdates, sendMessage,clearCashout,receivedBookData,receivedMMOpenBets,receivedmarketIdbookDetailsMM } = useSocket();
      const [grulesPopup, setGrulesPopup] = useState();
  const [firstTimeCall, setFirstTimeCall] = useState(false)
  const [firstTimeCall3, setFirstTimeCall3] = useState(false)
  const [firstTimeCall2, setFirstTimeCall2] = useState(false)
  const initialized = useRef(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
  const [betLoader, setBetLoader] = useState(false);
  const [betData, setBetData] = useState([]);
  const { stackArray,openPopup, setBetLoder,betUpdated, loggedIn, isLoggedOut, direactsecId, setSecIds,clearSecId,unsetBetLoader,isBetUpdate,betNotUpdated,isBetLoader, loaderCounter,loaderCounting,sessionUpdate, sessionUpdatevalue} = useContext(PopupContext);
  const isOneClicked = sessionStorage.getItem('isOneClicked')
  let oneClickValue
    if(isOneClicked == 'true'){
        oneClickValue = sessionStorage.getItem('oneClickAmount')
    }
  const [bklyClassValue, setBklyClass] = useState();
  const [slideToggle, setSlideToggle] = useState(false)
  const [priceGet, setPriceGet] = useState(0);
  const [Beton, setBeton] = useState();
  const [oddsValue, setoddsValue] = useState();
  const [plusMinusValue, setplusMinusValue] = useState();
  const [perticularprofit, setperticularprofit] = useState();
  const [betresultMsg, setBetresultMsg] = useState();
  const [betPopup, setBetPopup] = useState(false);
    const [stack, setStack] = useState([]);
    const formatNumberinStack = (num) => {
        if (num >= 1000000000) {
          return (num / 1000000000) + 'B'; // Billion
        } else if (num >= 1000000) {
          return (num / 1000000) + 'M'; // Million
        } else if (num >= 1000) {
          return (num / 1000) + 'K'; // Thousand
        } else {
          return num; // Less than 1000
        }
      };

  const gruleshandleClose = ()=>{
    setGrulesPopup(false);
}
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (sessionStorage.getItem('userId')) {
        callAPI();
      }
    }
    // console.log(receivedMultiMarket,"------receivedMultiMarket");
    
  }, [receivedMultiMarket,isLoggedIn,loggedIn]);

  useEffect(() => {
          setPreviousBookValues();          
      }, [receivedBookData]);

          useEffect(() => {
              try{
                  setStack(JSON.parse(stackArray));
                  if(!isBetLoader){
                      handlecloseSlip();
                  }
              }catch(err){
                  console.log(err, 'errerrerrerr');
                  setStack(stackArray)
                  if(!isBetLoader){
                      handlecloseSlip();
                  }
              }
            }, [isBetLoader, loaderCounter,stackArray]);

  useEffect(() => {
    let intervalId3;
    if (isConnected && multimarketArrrrr.length > 0) {
        if (!firstTimeCall3) {
            setFirstTimeCall3(true)
            if (sessionStorage.getItem('userId')) {
                let openbetsData = {marketIds: multimarketArrrrr, userId:sessionStorage.getItem('userId')}
                sendMessage('multiMarketOpenBets', openbetsData);   
            }
        }
        intervalId3 = setInterval(() => {            
            if (sessionStorage.getItem('userId')) {
                let openbetsData = {marketIds: multimarketArrrrr, userId:sessionStorage.getItem('userId')}
                sendMessage('multiMarketOpenBets', openbetsData);
            }
          }, 5000 );
    }
    return () => {
        if (intervalId3) {
            clearInterval(intervalId3);
        }
    };
}, [isConnected,sendMessage, multimarketArrrrr,receivedMMOpenBets]);

useEffect(() => {
    if (isConnected && multimarketArrrrr.length > 0) {
        if (!firstTimeCall3) {
            setFirstTimeCall3(true)
            if (sessionStorage.getItem('userId')) {
                let resumesocket ={
                    userId:sessionStorage.getItem('userId'),
                    marketIds:eidmid
                  };
                sendMessage('marketIdbookDetailsMultiMarket', resumesocket);   
            }
        }
    }
}, [isConnected,sendMessage, multimarketArrrrr,receivedmarketIdbookDetailsMM]);

  useEffect(() => {       
    let intervalId1;
    if (isConnected && multimarketArrrrr.length > 0) {
        if (!firstTimeCall) {
          setFirstTimeCall(true)
          let ids = {marketIds: multimarketArrrrr}
            sendMessage('multiMartketOddsUpdate', ids);
        }
        intervalId1 = setInterval(() => {            
          let ids = {marketIds: multimarketArrrrr}  
            sendMessage('multiMartketOddsUpdate', ids);
        }, 550 );
    }
    return () => {
        if (intervalId1) {
            clearInterval(intervalId1);
        }
    };

}, [isConnected, receivedMultiMarket, sendMessage, multimarketArrrrr,eidmid,receivedMultiMarketUpdates]);

useEffect(() => {
    let timer;
    if (isBetLoader) {
      timer = setInterval(() => {
        loaderCounting(prevSeconds => prevSeconds + 1);
      }, 1000);
    } else {
      loaderCounting(0);
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isBetLoader]);

useEffect(() => {
    let count  = receivedMultiMarketUpdates.length === 0
        let resumesocket ={
          userId:sessionStorage.getItem('userId'),
          array:eidmid
        };          
        let intervalId2;
        if (isConnected) {
            if (!firstTimeCall2) {
              setFirstTimeCall2(true)
                sendMessage('multimarketUpdate', resumesocket); 
            }
            
            intervalId2 = setInterval(() => {            
              sendMessage('multimarketUpdate', resumesocket);
            }, count ? 100 : 5000 );
        }
        return () => {
            if (intervalId2) {
                clearInterval(intervalId2);
            }
        };

    }, [isConnected, sendMessage,eidmid,receivedMultiMarketUpdates]);

    const onClickFunction = (runnerId, toggleclass, event, runnerName, marketsData) => {
        event.persist();
        setSlideToggle((prevSlideToggle) => prevSlideToggle === runnerId ? runnerId : runnerId);
        setSecIds(runnerId);
        setBklyClass(toggleclass);
        const strongElement = event.target.tagName === 'SPAN'
          ? event.target.querySelector('strong').innerText
          : event.target.closest('span')?.querySelector('strong')?.innerText;
        setoddsValue(strongElement);
        setPriceGet(oneClickValue);
        setBetLoader(true);
        console.log(marketsData, 'marketsData1111111111');
        
        getBetDataHandle({
            eventid: marketsData.eventID,
            marketId: marketsData.marketId,
            stake: oneClickValue * 1,
            bklyClassValue: toggleclass,
            selectionName: runnerName,
            secId: runnerId,
            oddsValue: strongElement,
          },marketsData)
      };

  const callAPI = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/getmultimarket`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setData(paresData);
        setMultimarketArrrrr(paresData.muliMarketArray);
        const formattedData = paresData.marketdata.map(item => ({
          marketId: item?.marketId,
          eventId: item.eventID || null
        }));
        setEidMid(formattedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

const mutliMarketAddRemove =(marketID,evenID) =>{
 handleRemove(marketID)
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

  const raw = JSON.stringify({
      marketId: marketID,
      eventId: evenID
  });

  const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
  };

  fetch(`${process.env.REACT_APP_BASE_URL}/armultimarket`, requestOptions)
  .then(async (response) => {
      let paresData = await response.json();
      if(response.ok){
        setMultimarketArrrrr(paresData?.marketId);
      }
    })
    .catch((error) => {
      console.log(error.message);
    });
}

const handleRemove = (marketId) => {
  const updatedData = data.marketdata.filter(item => item?.marketId !== marketId);
  setData({ marketdata: updatedData });
};

function isMoSuspended(marketId,runnerId) {
  if (!receivedMultiMarket || receivedMultiMarket.length === 0 && !receivedMultiMarketUpdates || receivedMultiMarketUpdates.length === 0) {  
    return false;
  }
  let marketObject = receivedMultiMarket?.find(market => market?.marketId === marketId);
  let runnerObject = marketObject?.runners?.find(market => market.runnerId === runnerId);
  let runnerStatus = runnerObject?.status !== 'ACTIVE';

  let datts = receivedMultiMarketUpdates?.find(market => market?.marketId === marketId);

// console.log("marketName----",marketObject.marketName,'eventName=======',marketObject.eventName,"................",datts, 'datts?.resumesuspendstatus');


  if(marketObject?.status === 'OPEN'){
    if(datts?.resumesuspendstatus){
      if (runnerStatus) {
        return true;
      }else{
        return false;
      }
    }else{
      return true;
    }
}else{
  return true;
}
}

const getMin = (item) => {  
  if (receivedMultiMarketUpdates.length === 0) {
    return formatMinMaxNumber(item?.Limits?.min_stake);
  }
  let datts = receivedMultiMarketUpdates.find(market => market?.marketId === item?.marketId);
  return formatMinMaxNumber(datts?.Limits?.min_stake)
}

const getMax = (item) => {  
  if (receivedMultiMarketUpdates.length === 0) {
    return formatMinMaxNumber(item?.Limits?.max_stake);
  }
  let datts = receivedMultiMarketUpdates.find(market => market?.marketId === item?.marketId);
//   console.log(datts, 'dattsdattsdattsdattsdattsdatts');
  
  return formatMinMaxNumber(datts?.Limits?.max_stake)
}

const getMaxx = (item) => {  
    if (receivedMultiMarketUpdates.length === 0) {
      return item?.Limits?.max_stake;
    }
    let datts = receivedMultiMarketUpdates.find(market => market?.marketId === item?.marketId);
    // console.log(datts, 'dattsdattsdattsdattsdattsdatts');
    
    return datts?.Limits?.max_stake
  }

const getMaxOdds = (item) => {  
  if (receivedMultiMarketUpdates.length === 0) {
    return 0;
  }
  let datts = receivedMultiMarketUpdates.find(market => market?.marketId === item?.marketId);
  return convertToOriginalValue(datts?.Limits?.max_odd)
}

const formatNumber = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2) + 'B'; // Billion
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + 'M'; // Million
  } else if (num >= 1000) {
    return (num / 1000).toFixed(2) + 'K'; // Thousand
  } else {
    return num; // Less than 1000
  }
};

const formatMinMaxNumber = (num) => {
    if (num >= 1000000000) {
      return (num / 1000000000) + 'B'; // Billion
    } else if (num >= 1000000) {
      return (num / 1000000) + 'M'; // Million
    } else if (num >= 1000) {
      return (num / 1000) + 'K'; // Thousand
    } else {
      return num; // Less than 1000
    }
  };

const getBetSlipClasss = (oddItem) => {
  if (direactsecId === oddItem?.runnerId) {
      if(oddItem.status !== 'ACTIVE'){
          clearSecId()
          unsetBetLoader();
          return 'betslip-wraper'
      }else{
          return 'betslip-wraper open'
      }
  }
  return 'betslip-wraper';
};

    useEffect(() => {
        if (direactsecId) {
          setPreviousBookValues();
        }
      }, [direactsecId]);

const handlecloseSlip =(item) => {
  try{
      clearCashout()
      setSlideToggle(false);
      clearSecId();
      setPriceGet(0);
      handleSendMarketBookDetails(item);      
  }catch(err){
      clearCashout()
    //   console.log(err, 'errerrerr');
  }
}

const handleSendMarketBookDetails = (item) => {
    console.log(item, 'asaasaasasas');
    
    let eventId =  item.eventID;
    let marketId = item.marketId;
    let userinfo = sessionStorage.getItem('userinfo');
    if (userinfo && eventId && marketId) {

        userinfo = JSON.parse(userinfo);
        sendMessage('marketIdbookDetails', { eventId, marketId, user: userinfo });        
    } else if(!userinfo){
        sendMessage('marketIdbookDetails', { eventId, marketId });        
    }else{
        console.error('Missing eventId or marketId');
    }
};

const subtractValue = (event,marketsData) => {
  const valueToSubtract  =  plusMinusValue ? plusMinusValue : 100
  let oldValue = priceGet

  setPriceGet(prevPrice => {
      const currentPrice = prevPrice ? Number(prevPrice) : 0;
      const newPrice = currentPrice - valueToSubtract;
      return newPrice < 0 ? 0 : newPrice;
  });
  if(oldValue){
      let newValue = oldValue - valueToSubtract
      if(newValue > 0){
          // console.log(newValue, oldValue);

          updateBook(event, oldValue, newValue,marketsData)
      }else{
          setPreviousBookValues()
      }
  }
};

const escapeSelectorId = (id) => {
    if(id){
        return String(id).replace(/([#.,+*~':"!^$[\]()=>|\\/])/g, '\\$1');
    }
};

const setPreviousBookValues = () => {
  if (receivedBookData && receivedBookData.showData) {
      for (let i = 0; i < receivedBookData.showData.length; i++) {
          const originalId = receivedBookData.showData[i].runnerId;
          const escapedId = escapeSelectorId(originalId);
          const element = document.getElementById(`${escapedId}`);
          if (element) {
              const mainDiv = element.querySelector('.book-value');
              if (mainDiv) {
                  mainDiv.innerText = receivedBookData.showData[i].amount !== null && receivedBookData.showData[i].amount !== undefined &&  !isNaN(Number(receivedBookData.showData[i].amount)) ? Number(receivedBookData.showData[i].amount).toFixed(2) : 'Invalid data';
                  if (receivedBookData.showData[i].amount > 0) {
                      mainDiv.classList.remove('red-txt');
                      mainDiv.classList.add('green-txt');
                  } else {
                      mainDiv.classList.remove('green-txt');
                      mainDiv.classList.add('red-txt');
                  }
              } else {
                  console.error(`.book-value not found for ID: #${escapedId}`);
              }
          } else {
              console.error(`Element with ID #${escapedId} not found. Original ID was: ${originalId}`);
          }
      }
      if(receivedBookData.showData.length == 0){
          // console.log('gothere');

          const escapedId = escapeSelectorId(receivedBookData.marketId);
          const element = document.getElementById(`${receivedBookData.marketId}`);
        //   console.log(element,escapedId, 'elementelement');

          if(element){
             let div = element.querySelectorAll('.book-value')
          //    console.log(div, 'divdivdiv');

             div.forEach(div => {
                  div.innerText = ''
             })
          }
      }
  }
};

const removeLastDigit = (event,marketsData) => {
    let oldValue = priceGet
    setPriceGet(prevPrice => {
        if (prevPrice !== undefined) {
            const newPrice = String(prevPrice).slice(0, -1);
            return newPrice === '' ? 0 : Number(newPrice);
        }
        return 0;
    });
    if(oldValue){
        let newValue = String(oldValue).slice(0, -1);
        updateBook(event, oldValue, newValue,marketsData)
    }else{
        setPreviousBookValues()
    }
};

const addAllValue = (newValue,event,marketsData) => {
    const closestBetslip = event.target.closest('.data-with-betslip');
    if (closestBetslip) {
        const bookValueDiv = closestBetslip.querySelector('.book-value');
        if (bookValueDiv) {
            // console.log('Found book-value:', bookValueDiv);
            let value
            if(bookValueDiv.innerText == ''){
                if(marketsData.marketType.startsWith('BOOK')){
                if(bklyClassValue === 'back'){
                    value = (newValue * oddsValue)/100
                }else{
                    value = -(newValue * oddsValue)/100
                }
            }else{
                if(bklyClassValue === 'back'){
                    value = (newValue * oddsValue) - newValue
                }else{
                    value = -((newValue * oddsValue) - newValue)
                }
            }
            }else{
                let previousValue = bookValueDiv.innerText
                if(marketsData.marketType.startsWith('BOOK')){
                if(bklyClassValue === 'back'){
                    value = parseFloat((newValue * oddsValue)/100)+ parseFloat(previousValue)
                }else{
                    value =previousValue - parseFloat((newValue * oddsValue)/100)
                }
            }else{
                if(bklyClassValue === 'back'){
                    value = parseFloat((newValue * oddsValue) - newValue) + parseFloat(previousValue)
                }else{
                    value = previousValue - parseFloat((newValue * oddsValue) - newValue)
                }
            }
            }
            bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
? Number(value).toFixed(2)
: 'Invalid value';
            setperticularprofit(value)
            if(value > 0){
                bookValueDiv.classList.remove('red-txt')
                bookValueDiv.classList.add('green-txt')
            }else{
                bookValueDiv.classList.remove('green-txt')
                bookValueDiv.classList.add('red-txt')
            }
            const closestBetslip2 = event.target.closest('.match-list');
            const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
            // console.log(allBookValueDivs, closestBetslip2);

            allBookValueDivs.forEach(div => {
                if (div !== bookValueDiv) {
                    let value
                    if(div.innerText == ''){
                    if(marketsData.marketType.startsWith('BOOK')){
                        if(bklyClassValue === 'back'){
                            value = -newValue
                        }else{
                            value = newValue
                        }
                    }else{
                        if(bklyClassValue === 'back'){
                            value = -newValue
                        }else{
                            value = newValue
                        }
                    }
                    }else{
                        let previousValue = div.innerText
                        if(marketsData.marketType.startsWith('BOOK')){
                        if(bklyClassValue === 'back'){
                            value = previousValue - newValue
                        }else{
                            value =  parseFloat(newValue) + parseFloat(previousValue)
                        }
                    }else{
                        if(bklyClassValue === 'back'){
                            value = previousValue - newValue
                        }else{
                            value =  parseFloat(newValue) + parseFloat(previousValue)
                        }
                    }
                    }
                    div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
? Number(value).toFixed(2)
: 'Invalid value';
                    if(value > 0){
                        div.classList.remove('red-txt')
                        div.classList.add('green-txt')
                    }else{
                        div.classList.remove('green-txt')
                        div.classList.add('red-txt')
                    }

                }
            });
        } else {
            console.log('.book-value div not found');
        }
    } else {
        console.log('.data-with-betslip not found');
    }



    setplusMinusValue(newValue)
    setPriceGet(newValue);

};

const allValue = () => {
    let userinfo = sessionStorage.getItem('balance');
    if (userinfo !== null) {
        let data = JSON.parse(userinfo);
        return (data.availableBalance - data.totalExposure).toFixed(2);
    }else{
        return 0;
    }
};

const incrementValue = (increment, event,marketsData) => {
    let oldValue = priceGet
    setPriceGet(prevPrice => {
        const currentPrice = prevPrice ? Number(prevPrice) : 0;
        return currentPrice + increment;
    });
    let newValue = parseFloat(oldValue + increment)
    // console.log(oldValue, newValue);

    updateBook(event, oldValue, newValue,marketsData)
};

const addValue = (newValue,event,marketsData) => {
    const closestBetslip = event.target.closest('.data-with-betslip');
    if (closestBetslip) {
        const bookValueDiv = closestBetslip.querySelector('.book-value');
        if (bookValueDiv) {
            // console.log('Found book-value:', bookValueDiv);
            let value
            if(bookValueDiv.innerText == ''){
                if(marketsData.marketType.startsWith('BOOK')){
                if(bklyClassValue === 'back'){
                    value = (newValue * oddsValue)/100
                }else{
                    value = -(newValue * oddsValue)/100
                }
            }else{
                if(bklyClassValue === 'back'){
                    value = (newValue * oddsValue) - newValue
                }else{
                    value = -((newValue * oddsValue) - newValue)
                }
            }
            }else{
                let previousValue = bookValueDiv.innerText
                if(marketsData.marketType.startsWith('BOOK')){
                if(bklyClassValue === 'back'){
                    value = parseFloat((newValue * oddsValue)/100) + parseFloat(previousValue)
                }else{
                    value = parseFloat(previousValue) - parseFloat((newValue * oddsValue)/100)
                }
            }else{
                if(bklyClassValue === 'back'){
                    value = parseFloat((newValue * oddsValue) - newValue) + parseFloat(previousValue)
                }else{
                    value = previousValue - parseFloat((newValue * oddsValue) - newValue)
                }
            }
            }
            bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
? Number(value).toFixed(2)
: 'Invalid value';
            setperticularprofit(value)
            if(value > 0){
                bookValueDiv.classList.remove('red-txt')
                bookValueDiv.classList.add('green-txt')
            }else{
                bookValueDiv.classList.remove('green-txt')
                bookValueDiv.classList.add('red-txt')
            }
            const closestBetslip2 = event.target.closest('.match-list');
            const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
            // console.log(allBookValueDivs, closestBetslip2);

            allBookValueDivs.forEach(div => {
                if (div !== bookValueDiv) {
                    let value
                    if(div.innerText == ''){
                    if(marketsData.marketType.startsWith('BOOK')){
                        if(bklyClassValue === 'back'){
                            value = -newValue
                        }else{
                            value = newValue
                        }
                    }else{
                        if(bklyClassValue === 'back'){
                            value = -newValue
                        }else{
                            value = newValue
                        }
                    }
                    }else{
                        let previousValue = div.innerText
                        if(marketsData.marketType.startsWith('BOOK')){
                        if(bklyClassValue === 'back'){
                            value = previousValue - newValue
                        }else{
                            value =  parseFloat(newValue) + parseFloat(previousValue)
                        }
                    }else{
                        if(bklyClassValue === 'back'){
                            value = previousValue - newValue
                        }else{
                            value =   parseFloat(previousValue) + parseFloat(newValue)
                        }
                    }
                    }
                    div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
? Number(value).toFixed(2)
: 'Invalid value';
                    if(value > 0){
                        div.classList.remove('red-txt')
                        div.classList.add('green-txt')
                    }else{
                        div.classList.remove('green-txt')
                        div.classList.add('red-txt')
                    }

                }
            });
        } else {
            console.log('.book-value div not found');
        }
    } else {
        console.log('.data-with-betslip not found');
    }



    setplusMinusValue(newValue)
    if (priceGet !== undefined) {
        setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
    } else {
        setPriceGet(newValue);
    }

};


const PlusValue = (event,marketsData) => {
    // marketsData.marketType === 'BOOKMAKER'
    const newValue =  plusMinusValue ? plusMinusValue : 100
    let oldValue = priceGet

    if (priceGet !== undefined) {
        setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
    } else {
        oldValue = 0
        setPriceGet(newValue);
    }
    let newValuestake =  parseFloat(oldValue) + parseFloat(newValue)

    updateBook(event, oldValue, newValuestake,marketsData)
};

const updateBook = (event, oldvalue, newvalue,marketsData) => {
  const closestBetslip = event.target.closest('.data-with-betslip');
  if (closestBetslip) {
      const bookValueDiv = closestBetslip.querySelector('.book-value');
      console.log(marketsData, 'marketsDatamarketsData');
      
      if (bookValueDiv) {
          if(oldvalue < newvalue){
              newvalue = newvalue - oldvalue
              let value
          if(bookValueDiv.innerText === ''){
            if(marketsData.marketType.startsWith('BOOK')){
              if(bklyClassValue === 'back'){
                  value = (newvalue * oddsValue)/100
              }else{
                  value = -((newvalue * oddsValue)/100)
              }
            }else{
                if(bklyClassValue === 'back'){
                    value = (newvalue * oddsValue)- newvalue
                }else{
                    value = -((newvalue * oddsValue) - newvalue)
                }
            }
          }else{
              let previousValue = bookValueDiv.innerText
              if(marketsData.marketType.startsWith('BOOK')){
              if(bklyClassValue === 'back'){
                  value = parseFloat((newvalue * oddsValue)/100) + parseFloat(previousValue)
              }else{
                  value = previousValue - parseFloat((newvalue * oddsValue)/100)
              }
            }else{
                if(bklyClassValue === 'back'){
                    value = parseFloat((newvalue * oddsValue) - newvalue) + parseFloat(previousValue)
                }else{
                    value = previousValue - parseFloat((newvalue * oddsValue) - newvalue)
                }
            }
          }
          bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
? Number(value).toFixed(2)
: 'Invalid value';
          setperticularprofit(value)
          if(value > 0){
              bookValueDiv.classList.remove('red-txt')
              bookValueDiv.classList.add('green-txt')
          }else{
              bookValueDiv.classList.remove('green-txt')
              bookValueDiv.classList.add('red-txt')
          }
          const closestBetslip2 = event.target.closest('.match-list');
          const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
          allBookValueDivs.forEach(div => {
              if (div !== bookValueDiv) {
                  let value
                  if(div.innerText == ''){
                    if(marketsData.marketType.startsWith('BOOK')){
                      if(bklyClassValue === 'back'){
                          value = -newvalue
                      }else{
                          value = newvalue
                      }
                    }else{
                        if(bklyClassValue === 'back'){
                            value = -newvalue
                        }else{
                            value = +newvalue
                        }
                    }
                  }else{
                      let previousValue = div.innerText
                      if(marketsData.marketType.startsWith('BOOK')){
                        if(bklyClassValue === 'back'){
                            value = previousValue - newvalue
                        }else{
                            value =  parseFloat(newvalue) + parseFloat(previousValue)
                        }
                    }else{
                        if(bklyClassValue === 'back'){
                            value = previousValue - newvalue
                        }else{
                            value =  parseFloat(newvalue) + parseFloat(previousValue)
                        }
                    }
                  }
                  div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
? Number(value).toFixed(2)
: 'Invalid value';
                  if(value > 0){
                      div.classList.remove('red-txt')
                      div.classList.add('green-txt')
                  }else{
                      div.classList.remove('green-txt')
                      div.classList.add('red-txt')
                  }

              }
          });
          }else{
              let diff = Math.abs(newvalue - oldvalue)

              const closestBetslip = event.target.closest('.data-with-betslip');
              if (closestBetslip) {
                  const bookValueDiv = closestBetslip.querySelector('.book-value');
                  if (bookValueDiv) {
                      let value
                      if(bookValueDiv.innerText == ''){
                        if(marketsData.marketType.startsWith('BOOK')){
                          if(bklyClassValue === 'back'){
                              value = (newvalue * oddsValue)/100
                          }else{
                              value = -((newvalue * oddsValue)/100)
                          }
                        }else{
                          if(bklyClassValue === 'back'){
                            value = (newvalue * oddsValue)- newvalue
                        }else{
                            value = -((newvalue * oddsValue)- newvalue)
                        }
                    }
                      }else{
                          let previousValue = bookValueDiv.innerText
                            if(marketsData.marketType.startsWith('BOOK')){
                          if(bklyClassValue === 'back'){
                              value = parseFloat(previousValue) - parseFloat((diff * oddsValue)/100)
                          }else{
                              value = parseFloat(previousValue) + parseFloat((diff * oddsValue)/100)
                          }
                        }else{
                          if(bklyClassValue === 'back'){
                            value = parseFloat(previousValue) - parseFloat((diff * oddsValue)- diff)
                        }else{
                            value = parseFloat(previousValue) + parseFloat((diff * oddsValue)- diff)
                        }
                        }
                      }
                      bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
? Number(value).toFixed(2)
: 'Invalid value';
                      setperticularprofit(value)
                      if(value > 0){
                          bookValueDiv.classList.remove('red-txt')
                          bookValueDiv.classList.add('green-txt')
                      }else{
                          bookValueDiv.classList.remove('green-txt')
                          bookValueDiv.classList.add('red-txt')
                      }
                      const closestBetslip2 = event.target.closest('.match-list');
                      const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
                      allBookValueDivs.forEach(div => {
                          if (div !== bookValueDiv) {
                              let value
                              if(div.innerText == ''){
                                if(marketsData.marketType.startsWith('BOOK')){
                                  if(bklyClassValue === 'back'){
                                      value = -newvalue
                                  }else{
                                      value = newvalue
                                  }
                                }else{
                                  if(bklyClassValue === 'back'){
                                    value = -newvalue
                                }else{
                                    value = newvalue
                                }
                            }
                              }else{
                                  let previousValue = div.innerText
                                  if(marketsData.marketType.startsWith('BOOK')){
                                  if(bklyClassValue === 'back'){
                                      value =  parseFloat(previousValue) + parseFloat(diff)
                                  }else{
                                      value =  parseFloat(previousValue) - parseFloat(diff)
                                  }
                                }else{
                                    if(bklyClassValue === 'back'){
                                        value =  parseFloat(previousValue) + parseFloat(diff)
                                    }else{
                                        value =  parseFloat(previousValue) - parseFloat(diff)
                                    }
                                }
                              }
                              div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
? Number(value).toFixed(2)
: 'Invalid value';
                              if(value > 0){
                                  div.classList.remove('red-txt')
                                  div.classList.add('green-txt')
                              }else{
                                  div.classList.remove('green-txt')
                                  div.classList.add('red-txt')
                              }

                          }
                      });
                  }
              }
          }
      }
  }
}

const toggleHandle = (slideId, bklyClass, event, runnerName,marketsData) => {
    handleSendMarketBookDetails(marketsData);
    const strongElement = event.target.tagName === 'SPAN'
    ? event.target.querySelector('strong').innerText
    : event.target.closest('span')?.querySelector('strong').innerText;
    setSlideToggle(slideToggle === slideId ? slideId : slideId);
    setBklyClass(bklyClass);
    setoddsValue(strongElement);
    setSecIds(slideId);
    setPriceGet('')
    setBeton(runnerName)
}

const updateSessionStorage = () => {
    const newValue = `Updated at ${new Date().toLocaleTimeString()}`;
    sessionStorage.setItem("sharedData", newValue);
    sessionUpdate(newValue);
  };

const callBetPlace = (data,marketsData) => {
    try {
        // Replace 'your-token' with your actual token
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
        console.log("request :::::: ", data)
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify(data)
          };

          fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/bets/placeBet`, requestOptions)
          .then(async (response) => {
            let openbetsData = {marketIds: multimarketArrrrr, userId:sessionStorage.getItem('userId')}
            sendMessage('multiMarketOpenBets', openbetsData);  
            updateSessionStorage();
            let resumesocket ={
                userId:sessionStorage.getItem('userId'),
                marketIds:eidmid
              };
            sendMessage('marketIdbookDetailsMultiMarket', resumesocket); 
            let result = await response.json();
            console.log("Response :::: ",result);
            setBetresultMsg(result);
            setBetPopup(true);
            unsetBetLoader();
            clearCashout();
            handlecloseSlip(marketsData);
                const betResultpopupTimeer = setTimeout(() => {
                    clearSecId();
                    setBetPopup(false);
                }, 1500);
                return () => {
                    clearSecId();
                    clearTimeout(betResultpopupTimeer);
                }
          })
          .catch((error) => {
            let resumesocket ={
                userId:sessionStorage.getItem('userId'),
                marketIds:eidmid
              };
            sendMessage('marketIdbookDetailsMultiMarket', resumesocket); 
            clearSecId();
            clearCashout();
            console.log(error);
          });

    } catch (error) {
        clearCashout();
        console.error('Error fetching API data:', error);
    }
}

const getBetDataHandle = (getBetSlipData,marketsData) => {
    console.log(getBetSlipData,'-----------------------******-----------');
  
    betUpdated();
    setBetLoder();
    setBetData(getBetSlipData);
    let data = {
        eventId: getBetSlipData?.eventid,
        marketId: getBetSlipData?.marketId,
        stake: getBetSlipData?.stake,
        betType: getBetSlipData?.bklyClassValue,
        selectionName: getBetSlipData?.selectionName,
        secId: `${getBetSlipData?.secId}`,
        odds: getBetSlipData?.oddsValue
    }
    console.log('datadatadata', data)
    if (data.betType === 'back') {
        data.betType = 'BACK'
    } else {
        data.betType = 'LAY'
    }

    callBetPlace(data,marketsData);
};

const getBookText = (runnerid,marketid) => {
    if (!receivedmarketIdbookDetailsMM || receivedmarketIdbookDetailsMM.length === 0) {
        return <div className="green-txt book-value"></div>;
    }
    let marketObject = receivedmarketIdbookDetailsMM?.find(market => market?.marketId === marketid);
    let runnerObject = marketObject?.showData?.find(market => market.runnerId === runnerid);
    return (<div className={`${runnerObject?.amount > 0 ? 'green-txt' : 'red-txt'} book-value`}>{runnerObject?.amount.toFixed(2) || ''}</div>);
}

const grulesHandle = ()=>{
    setGrulesPopup(true);
}

  return (
    <div className="container-fluid multimarket-page">
          <h3>MultiMarket</h3>
      <div className="content-w-sidebar exchange-inner-page">
          <div className="left-content">
            <div className="match-data-wraper">
            <>
              {sessionStorage.getItem('userId') ?  (data && data.marketdata.map((marketsData,index) => (
              <div className="sport-all-data inner-match-list" id={marketsData?.marketId} key={index}>
                  <div className="match-wrapper">
                      <div className={`heading ${sessionStorage.getItem('userId') ? 'w-star':''}`}>
                        <div className="heading-text sport-name">{marketsData.eventName} / {marketsData.marketName} <span className="rulespopup-btn" onClick={grulesHandle} ><i className="fa-solid fa-circle-info"></i></span></div>
                        <div className="heading-text sport-name">Min : {getMin(marketsData)}, Max : {getMax(marketsData)}</div>
                        {sessionStorage.getItem('userId') ? (
                          <svg className='star-icon' onClick={()=>mutliMarketAddRemove(marketsData?.marketId,marketsData.eventID)} xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
                                    <path d="M12.3834 1.13606C13.0434 -0.201277 14.9514 -0.201277 15.6114 1.13606L18.7554 7.50672L25.7861 8.52806C27.2621 8.74139 27.8514 10.5574 26.7834 11.5987L25.5754 12.7761C24.0802 11.9352 22.3691 11.557 20.6588 11.6894C18.9485 11.8217 17.3159 12.4586 15.9678 13.5194C14.6198 14.5802 13.6168 16.0172 13.086 17.6484C12.5552 19.2797 12.5204 21.0317 12.9861 22.6827L7.71006 25.4561C6.39006 26.1494 4.84606 25.0294 5.09672 23.5587L6.29806 16.5574L1.21139 11.5974C0.143391 10.5574 0.732724 8.74272 2.20872 8.52806L9.23939 7.50672L12.3834 1.13606ZM28.6634 20.3334C28.6634 18.3885 27.8908 16.5232 26.5155 15.1479C25.1402 13.7727 23.275 13.0001 21.3301 13.0001C19.3851 13.0001 17.5199 13.7727 16.1446 15.1479C14.7693 16.5232 13.9967 18.3885 13.9967 20.3334C13.9967 22.2783 14.7693 24.1436 16.1446 25.5188C17.5199 26.8941 19.3851 27.6667 21.3301 27.6667C23.275 27.6667 25.1402 26.8941 26.5155 25.5188C27.8908 24.1436 28.6634 22.2783 28.6634 20.3334ZM21.9967 21.0001L21.9994 24.3374C21.9994 24.5142 21.9292 24.6838 21.8041 24.8088C21.6791 24.9338 21.5095 25.0041 21.3327 25.0041C21.1559 25.0041 20.9863 24.9338 20.8613 24.8088C20.7363 24.6838 20.6661 24.5142 20.6661 24.3374V21.0001H17.3261C17.1492 21.0001 16.9797 20.9298 16.8547 20.8048C16.7296 20.6798 16.6594 20.5102 16.6594 20.3334C16.6594 20.1566 16.7296 19.987 16.8547 19.862C16.9797 19.737 17.1492 19.6667 17.3261 19.6667H20.6661L20.6647 16.3321C20.6647 16.1552 20.735 15.9857 20.86 15.8607C20.985 15.7356 21.1546 15.6654 21.3314 15.6654C21.5082 15.6654 21.6778 15.7356 21.8028 15.8607C21.9278 15.9857 21.9981 16.1552 21.9981 16.3321V19.6654H25.3274C25.5042 19.6654 25.6738 19.7356 25.7988 19.8607C25.9238 19.9857 25.9941 20.1552 25.9941 20.3321C25.9941 20.5089 25.9238 20.6784 25.7988 20.8035C25.6738 20.9285 25.5042 20.9987 25.3274 20.9987H21.9994L21.9967 21.0001Z" fill="#182EA6"></path>
                                </svg>
                        ) :'' }
                      </div>
                      <div className="heading match-heading">
                        <div className="heading-text sport-name">Market</div>
                        <div className={`odds-heading ${marketsData.marketType === 'BOOKMAKER' ? 'col2-odds-heading' : ''}`}>
                            <div className="heading-text box">Back</div>
                            <div className="heading-text box">Lay</div>
                        </div>
                      </div>
                      <div className="match-data">
                        <div className="match-list" id={marketsData?.marketId}>
                        {marketsData.runners?.map((runner, runnerIindex) => (
                          <div className="data-with-betslip" key={runnerIindex}>
                              <div className="match-info-row" id={runner.runnerId}>
                                  <div className="match-name">{runner.runnerName}</div>
                                  {getBookText(runner.runnerId,marketsData?.marketId)}
                                  <div className="match-odds">
                                  
                                  {isMoSuspended(marketsData?.marketId,runner.runnerId) &&  (<div className='suspended'>Suspended</div>)}
                                  
                                    <div className="box">

                                      {marketsData.marketType === 'BOOKMAKER' ? (
                                        (() => {

                                          let marketObject = receivedMultiMarket?.find(market => market?.marketId === marketsData?.marketId);
                                          let runnerObject = marketObject?.runners?.find(market => market?.runnerId === runner?.runnerId);
                                          let runnerStatus = runnerObject?.status !== 'ACTIVE';

                                          let backPriceExists = runnerObject?.backPrices && runnerObject?.backPrices[0];
                                          let backPriceData = runnerObject?.backPrices?.[0];
                                          let datas = receivedMultiMarketUpdates?.find(market => market?.marketId === marketsData?.marketId);

                                          let isLocked =
                                            !datas?.resumesuspendstatus ||
                                            runnerStatus ||
                                            !backPriceExists ||
                                            (backPriceData && backPriceData.price === 0);

                                          return (
                                            <span
                                                    key={0}
                                                    className={`blue ${!backPriceExists || (backPriceData && backPriceData.price === 0) ? 'lock' : ''} ${getMaxOdds(marketsData) < runner?.backPrices[0]?.price ? 'disable' : ''}`}
                                                    onClick={(event) => {
                                                        if(!isBetLoader){
                                                        if (backPriceExists) {
                                                            if (isOneClicked === "true") {
                                                                onClickFunction(runner.runnerId, 'back', event, runner.runnerName,marketsData);
                                                            } else {
                                                                toggleHandle(runner.runnerId, 'back', event, runner.runnerName,marketsData);
                                                            }
                                                        }
                                                        }
                                                    }}
                                                >
                                                    {isLocked ? (
                                                        <i className="fa fa-lock"></i>
                                                    ) : (
                                                        <>
                                                            <strong>{backPriceExists ? backPriceData?.price : runner?.backPrices[0]?.price}</strong>
                                                        </>
                                                    )}
                                                </span>
                                          );
                                        })()) :
                                      (Array.from({ length: 3 }).map((_, position) => {
                                            let marketObject = receivedMultiMarket?.find(market => market?.marketId === marketsData?.marketId);
                                            let runnerObject = marketObject?.runners?.find(market => market?.runnerId === runner?.runnerId);
                                            let runnerStatus = runnerObject?.status !== 'ACTIVE';
                                           
                                            let backPriceExists = runnerObject?.backPrices && runnerObject?.backPrices[position];
                                            let backPriceData = runnerObject?.backPrices?.slice().reverse()[position];
                                            let datas = receivedMultiMarketUpdates?.find(market => market?.marketId === marketsData?.marketId);

                                            let isLocked = 
                                                !datas?.resumesuspendstatus || 
                                                runnerStatus ||
                                                !backPriceExists || 
                                                (backPriceData && backPriceData.price === 0);

                                            return (
                                                <span
                                                    key={position}
                                                    className={`blue ${!backPriceExists || (backPriceData && backPriceData.price === 0) ? 'lock' : ''} ${getMaxOdds(marketsData) < runner?.backPrices[position]?.price ? 'disable' : ''}`}
                                                    onClick={(event) => {
                                                        if(!isBetLoader){
                                                        if (backPriceExists) {
                                                            if (isOneClicked === "true") {
                                                                onClickFunction(runner.runnerId, 'back', event, runner.runnerName,marketsData);
                                                            } else {
                                                                toggleHandle(runner.runnerId, 'back', event, runner.runnerName,marketsData);
                                                            }
                                                        }
                                                        }
                                                    }}
                                                >
                                                    {isLocked ? (
                                                        <i className="fa fa-lock"></i>
                                                    ) : (
                                                        <>
                                                            <strong>{backPriceExists ? backPriceData?.price : runner?.backPrices[position]?.price}</strong>
                                                            <small>
                                                                {backPriceExists ? formatNumber(backPriceData?.size) : formatNumber(runner?.backPrices[position]?.size)}
                                                            </small>
                                                        </>
                                                    )}
                                                </span>
                                            );
                                        }))}
                                    </div>

                                    <div className="box">
                                    {marketsData.marketType === 'BOOKMAKER' ? (
                                        (() => {
                                          let marketObject = receivedMultiMarket?.find(market => market?.marketId === marketsData?.marketId);
                                          let runnerObject = marketObject?.runners?.find(market => market?.runnerId === runner?.runnerId);
                                          let runnerStatus = runnerObject?.status !== 'ACTIVE';

                                          let layPriceExists = runnerObject?.layPrices && runnerObject?.layPrices[0];
                                          let layPriceData = runnerObject?.layPrices?.[0];
                                          let datas = receivedMultiMarketUpdates?.find(market => market?.marketId === marketsData?.marketId);

                                          let isLocked =
                                            !datas?.resumesuspendstatus ||
                                            runnerStatus ||
                                            !layPriceExists ||
                                            (layPriceData && layPriceData.price === 0);

                                          return (
                                            <span
                                                    key={0}
                                                    className={`pink ${!layPriceExists || (layPriceData && layPriceData.price === 0) ? 'lock' : ''} ${getMaxOdds(marketsData) < runner?.layPrices[0]?.price ? 'disable' : ''}`}
                                                    onClick={(event) => {
                                                        if(!isBetLoader){
                                                        if (layPriceExists) {
                                                            if (isOneClicked === "true") {
                                                                onClickFunction(runner.runnerId, 'lay', event, runner.runnerName,marketsData);
                                                            } else {
                                                                toggleHandle(runner.runnerId, 'lay', event, runner.runnerName,marketsData);
                                                            }
                                                        }
                                                        }
                                                    }}
                                                >
                                                    {isLocked ? (
                                                        <i className="fa fa-lock"></i>
                                                    ) : (
                                                        <>
                                                            <strong>{layPriceExists ? layPriceData?.price : runner?.layPrices[0]?.price}</strong>
                                                        </>
                                                    )}
                                                </span>
                                          );
                                        })()) :(Array.from({ length: 3 }).map((_, position) => {
                                      let marketObject = receivedMultiMarket?.find(market => market?.marketId === marketsData?.marketId);
                                    let runnerObject = marketObject?.runners?.find(market => market?.runnerId === runner?.runnerId);
                                    let runnerStatus = runnerObject?.status !== 'ACTIVE';

                                      let layPriceExists = runnerObject?.layPrices && runnerObject?.layPrices[position];
                                      let layPriceData = runnerObject?.layPrices?.[position];

                                      let datas = receivedMultiMarketUpdates?.find(market => market?.marketId === marketsData?.marketId);

                                    let isLocked = 
                                                !datas?.resumesuspendstatus || 
                                                runnerStatus ||
                                                !layPriceExists || 
                                                (layPriceData && layPriceData.price === 0);

                                            return (
                                                <span
                                                    key={position}
                                                    className={`pink ${!layPriceExists || (layPriceData && layPriceData.price === 0) ? 'lock' : ''} ${getMaxOdds(marketsData) < (layPriceExists?.price || 0) ? 'disable' : ''}`}
                                                    onClick={(event) => {
                                                        if(!isBetLoader){
                                                        if (layPriceExists) {
                                                            if (isOneClicked === 'true') {
                                                                onClickFunction(runner.runnerId, 'lay', event, runner.runnerName,marketsData);
                                                            } else {
                                                                toggleHandle(runner.runnerId, 'lay', event, runner.runnerName,marketsData);
                                                            }
                                                        }
                                                        }
                                                    }}
                                                >
                                                    {isLocked ? (
                                                        <i className="fa fa-lock"></i>
                                                    ) : (
                                                        <>
                                                            <strong>{layPriceExists ? layPriceData?.price : ''}</strong>
                                                            <small>
                                                                {layPriceExists ? formatNumber(layPriceData?.size) : ''}
                                                            </small>
                                                        </>
                                                    )}
                                                </span>
                                            );
                                        }))}
                                    </div>
                                  </div>
                              </div>

                              <div className={getBetSlipClasss(runner)}>
                                            <div className={`betslip ${direactsecId === runner.runnerId ? 'active' : ''} ${bklyClassValue}`}>
                                                <div className="close-btn" onClick={()=>handlecloseSlip(marketsData)}>×</div>
                                                <div className="betslip-top">
                                                    <div className="bet-selection-name" >Bet on : {Beton} @{oddsValue}</div>
                                                    <div className="bet-profit"> </div>
                                                    <div className="betslip-odds"> <strong>Odds</strong> <span className="blue-text">{oddsValue}</span> </div>
                                                    <div className="betslip-stake">
                                                        <strong>Stake</strong>
                                                        <div className="input-group">
                                                            <span className="minus" onClick={(event) => subtractValue(event,marketsData)}>-</span>
                                                            <input type="text" value={priceGet} onChange={(e) => {
                                                                const value = e.target.value;
                                                                if (/^\d*$/.test(value)) {
                                                                    let old = priceGet
                                                                setPriceGet(value);
                                                                updateBook(e,old,value);
                                                                }
                                                            }} className="set-stake-form-input"  aria-label="Last name"
                                                                autoFocus="" maxLength="8" />
                                                            <span className="plus" onClick={(event) => PlusValue(event,marketsData)}>+</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="betslip-placebutton">
                                                    <div className="price-btns">
                                                    {Array.isArray(stack) && stack.map((item,index) => (
                                                            <span key={index} id={`${item}`} onClick={(event) => addValue(`${item}`,event,marketsData)}>{formatNumberinStack(item)}</span>
                                                        )) }
                                                        <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${getMaxx(marketsData)}`,event,marketsData)}>MAX</span>
                                                        <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${allValue()}`,event,marketsData)}>All</span>
                                                    </div>
                                                    <div className="betplace-btn">
                                                    {loggedIn || isLoggedIn ? <button className="place-bet" onClick={(event) =>{
                                                        setBetLoader(true);
                                                        getBetDataHandle({eventid : marketsData.eventID , marketId :marketsData.marketId,stake: priceGet,bklyClassValue, selectionName :runner.runnerName, secId :runner.runnerId, oddsValue},marketsData)} }>Place Bet</button> :  <button onClick={openPopup} className="login-btn">Login to continue</button>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`betslip_mobileview ${direactsecId === runner.runnerId ? 'active' : ''} ${bklyClassValue}`}>
                                                <div className="close-btn" onClick={()=>handlecloseSlip(marketsData)}>×</div>
                                                <table className="eventdetails">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className='plus-minus-wraper'>
                                                                <div className='plus-minus'>
                                                                    <span>-</span>
                                                                    <input type="text" value={oddsValue} />
                                                                    <span>+</span>
                                                                </div>
                                                                <div className='plus-minus'>
                                                                    <span onClick={(event) => subtractValue(event,marketsData)}>-</span>
                                                                    <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*$/.test(value)) {
                                                                        let old = priceGet
                                                                    setPriceGet(value);
                                                                    updateBook(e,old,value,marketsData,marketsData);
                                                                    }
                                                                }}  aria-label="Last name" autoFocus="" maxLength="8" />
                                                                    <span onClick={(event) => PlusValue(event,marketsData)} >+</span>
                                                                </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="11" className='mbet-slip'>
                                                                <div className="mbet-slip-wraper">
                                                                {Array.isArray(stack) && stack.map((item,index) => (
                                                                    <div key={index} id={`${item}`} className="odds-value" onClick={(event) => addValue(`${item}`,event,marketsData)}>{formatNumberinStack(item)}</div>
                                                                )) }
                                                                    <div className="odds-value max" onClick={(event)=>addAllValue(`${getMaxx(marketsData)}`,event,marketsData)} id="10000">MAX</div>
                                                                    <div className="odds-value all" onClick={(event)=>addAllValue(`${allValue()}`,event,marketsData)} id="98941.80">ALL IN</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="numbering">
                                                            <td colSpan="11">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><span onClick={(event) => incrementValue('1',event,marketsData)} id="1">1</span></td>
                                                                            <td><span onClick={(event) => incrementValue('2',event,marketsData)} id="2">2</span></td>
                                                                            <td><span onClick={(event) => incrementValue('3',event,marketsData)} id="3">3</span></td>
                                                                            <td><span onClick={(event) => incrementValue('4',event,marketsData)} id="4">4</span></td>
                                                                            <td><span onClick={(event) => incrementValue('5',event,marketsData)} id="5">5</span></td>
                                                                            <td><span onClick={(event) => incrementValue('6',event,marketsData)} id="6">6</span></td>
                                                                            <td rowSpan="2"><span className="back-span" onClick={(event) => removeLastDigit(event,marketsData)}><img alt="" src={betslipclosearrow}/></span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><span onClick={(event) => incrementValue('7',event,marketsData)} id="7">7</span></td>
                                                                            <td><span onClick={(event) => incrementValue('8',event,marketsData)} id="8">8</span></td>
                                                                            <td><span onClick={(event) => incrementValue('9',event,marketsData)} id="9">9</span></td>
                                                                            <td><span onClick={(event) => incrementValue('0',event,marketsData)} id="0">0</span></td>
                                                                            <td><span onClick={(event) => incrementValue('00',event,marketsData)} id="double0">00</span></td>
                                                                            {/* <td><span onClick={(event) => incrementValue('000',event,marketsData)} id="dot">000</span></td> */}
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr className="bet-btns">
                                                            <td colSpan="11">
                                                                <div className="btn-wraper">
                                                                    <button className="btn cancel-bet" onClick={()=>handlecloseSlip(marketsData)}>CANCEL</button>
                                                                    {loggedIn || isLoggedIn ? <button className="btn place-bet" onClick={(event) =>{
                                                                        setBetLoader(true)
                                                                        getBetDataHandle({eventid : marketsData.eventID , marketId :marketsData.marketId,stake: priceGet,bklyClassValue, selectionName :runner.runnerName, secId :runner.runnerId, oddsValue},marketsData)
                                                                    }
                                                                        }>Place Bet</button> :  <button onClick={openPopup} className="btn place-bet">Login to continue</button>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                    {isBetLoader ?
                                       <div className="loader-sec loading">
                                        Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{String(loaderCounter).padStart(2, '0')}</span><span className='loader'></span></div>
                                        </div> : ''}
                                    </div>
                            </div>
                        ))}
                        </div>
                      </div>
                  </div>
                </div>
            ))) :( <><div className="no-data">There are currently no followed multi markets.</div></>)}
            </>
            
            </div>
          </div>
          <div className="right-content sidebar">
          {loggedIn || isLoggedIn ? <div className="sidebar-box">
          <div className='title'>Open Bets ({`${receivedMMOpenBets.length}`})</div>
          {receivedMMOpenBets?.length > 0 ?
                                <div className='betlist'>
                                    {loggedIn || isLoggedIn ?
                                        <table className="betlist-table">
                                            <thead>
                                                <tr>
                                                    <th>Selection</th>
                                                    <th>Odds</th>
                                                    <th>Market</th>
                                                    <th>Stake</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {receivedMMOpenBets?.map((item, index) => (
                                                    <tr key={index} className={`${item?.bettype2 === 'BACK' ? 'back' : 'lay'}`}>
                                                        <td> {item?.selectionName}</td>
                                                        <td> {item?.oddValue}</td>
                                                        <td> {item?.marketName}</td>
                                                        <td> {item?.Stake}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> : ''}
                                </div> : ''}
          </div>: '' }
          
          
          </div>
      </div>
      <Popup popupClass={`alertMsgpopup ${betPopup ? 'open' : ''}`} content={<div className={`alert-msg ${betresultMsg?.status === 'Error' ? 'err' : ''}`}> <span className='msg'>{betresultMsg?.status === 'success' ? betresultMsg?.msg : betresultMsg?.message}</span><div className="alert-progress-bar"></div> </div>} />
      <ExchanngeinfoPopup isOpen={grulesPopup} handleClose={gruleshandleClose} />
    </div>
  );
}
