import React, { useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Popup from "../common/popup/Popup";
import { useEffect } from "react";
import { PopupContext } from "../LoginPopup/LoginPopupContext";
import bankTransfer from "../../assets/images/bank-transfer.png";
import upiPay from "../../assets/images/upi-pay.png";
import paytmPay from "../../assets/images/paytm-pay.png";
import { Link, useNavigate } from 'react-router-dom'
import Tesseract from "tesseract.js";
import { QRCodeCanvas } from 'qrcode.react';

function DepositPopup({ isopen, handleClose }) {
  const token = sessionStorage.getItem("token");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const initialized = useRef(false);  
  const navigate = useNavigate();
  const [paymentTab, setPaymentTab] = useState("tab1");
  const [accountTab, setAccountTab] = useState(0);
  const [currentAccount, setCurrentAccount] = useState();
  const [isBankTransfer, setIsBankTransfer] = useState([]);
  const [isUPI, setIsUPI] = useState([]);
  const [isPaytm, setIsPaytm] = useState([]);

  const [imagePreview, setImagePreview] = useState(null);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [note, setNote] = useState("");
  const fileInputRef = useRef();
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if form is submitted

  const wordsToNumber = (words) => {
    const numberWords = {
      one: 1, two: 2, three: 3, four: 4, five: 5,
      six: 6, seven: 7, eight: 8, nine: 9, ten: 10,
      eleven: 11, twelve: 12, thirteen: 13, fourteen: 14, fifteen: 15,
      sixteen: 16, seventeen: 17, eighteen: 18, nineteen: 19, twenty: 20,
      thirty: 30, forty: 40, fifty: 50, sixty: 60, seventy: 70,
      eighty: 80, ninety: 90, hundred: 100, thousand: 1000,
    };

    let wordsArray = words.toLowerCase().split(" ");
    let number = 0, tempNumber = 0;

    wordsArray.forEach((word, index) => {
      if (numberWords[word] >= 100) {
        tempNumber *= numberWords[word];
      } else {
        tempNumber += numberWords[word] || 0;
      }

      if (word === "only" || index === wordsArray.length - 1) {
        number += tempNumber;
        tempNumber = 0;
      }
    });

    return number;
  };

  const handleFileChange = (event) => {
    setLoaderVisible(true);

    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setCurrentFile(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        setImagePreview(e.target.result);

        // Use Tesseract.recognize directly
        Tesseract.recognize(
          e.target.result, // Image source
          "eng", // Language (English in this case)
          {
            logger: (m) => console.log(m), // This logs the OCR process
          }
        )
          .then(({ data: { text } }) => {
            console.log("Extracted text:", text);
            // Regex to match rupee amount in words (Rupees ... Only)
            const rupeeWordsRegex = /Rupees\s+([A-Za-z\s]+)\s+Only/i;
            const rupeeWordsMatch = text.match(rupeeWordsRegex);

            // Regex to find amounts associated with keywords
            const amountRegex = /(?:Debited from|Amount|Total|Paid|[$₹])\s*([0-9]{1,3}(?:,[0-9]{3})*(?:\.\d{1,2})?)/gi;
            const matches = [...text.matchAll(amountRegex)];

            // Process matches to find the most probable amount
            let amount = 'Not Found';
            let maxAmount = 0;

            matches.forEach(match => {
              // Remove commas and parse as float
              const value = parseFloat(match[1].replace(/,/g, ''));

              // Ensure the value is large enough to be a realistic amount
              if (value > maxAmount && value >= 10) {
                maxAmount = value;
                amount = match[1].replace(/,/g, '').trim();
              }
            });

            // If no amount is found, try to parse the rupee words
            if (amount === 'Not Found' && rupeeWordsMatch) {
              const wordsAmount = rupeeWordsMatch[1];
              const numericAmount = wordsToNumber(wordsAmount);
              amount = numericAmount ? numericAmount.toFixed(2) : 'Not Found';
            }

            // If still not found, try additional regex patterns
            if (amount === 'Not Found') {
              const regex = /Debited from\s[\dX]+(\s\d+)/;
              const match = text.match(regex);
              if (match) {
                amount = match[1].trim();
                if (!parseFloat(amount)) {
                  amount = 'Not Found';
                }
              }
            }

            if(amount === 'Not Found'){
              const paidToRegex = /Paid\s+to\s+[A-Za-z]+\s+F(\d+)/i;
              const paidToMatch = text.match(paidToRegex);
              if (paidToMatch) {
                amount = paidToMatch[1]; 
              }
            }

            if(amount === "Not Found"){
              const paidToAmountRegex = /Paid\s+to\s+([A-Za-z\s]+)\s+(\d+)/i;
              const paidToAmountMatch = text.match(paidToAmountRegex);
              if (paidToAmountMatch) {
                  amount = paidToAmountMatch[2];  
              }
            }

            if(amount === "Not Found"){
              const razorpayAmountRegex = /To\s+[A-Za-z]+\s+([\d,]+\.\d{2})/i;
              const razorpayAmountMatch = text.match(razorpayAmountRegex);
              if (razorpayAmountMatch) {
                amount = razorpayAmountMatch[1];  
              }
            }

            if(amount === "Not Found"){
              const razorpayAmountRegex = /To\s+[A-Za-z\s]+\s+([\d,]+)/i;
              const razorpayAmountMatch = text.match(razorpayAmountRegex);
              if (razorpayAmountMatch) {
                amount = razorpayAmountMatch[1];  
              }
            }

            if(amount === "Not Found"){
              const razorpayAmountRegex = /To\s+[A-Za-z\s]+\s+[\+\d\s]+(\d{1,3}(?:,\d{3})*)/i;
              const razorpayAmountMatch = text.match(razorpayAmountRegex);
              if (razorpayAmountMatch) {
                amount = razorpayAmountMatch[1];  
              }
            }

            if(amount === "Not Found"){
              const razorpayAmountRegex = /To\s+[A-Za-z\s]+\/\s*(\d{1,3}(?:,\d{3})*)/i;
              const razorpayAmountMatch = text.match(razorpayAmountRegex);
              if (razorpayAmountMatch) {
                amount = razorpayAmountMatch[1];  
              }
            }

            if(amount === "Not Found"){
              const razorpayAmountRegex = /Paid\s+to\s+[A-Za-z+\s]+\s*%(\d+)/i;
              const razorpayAmountMatch = text.match(razorpayAmountRegex);
              if (razorpayAmountMatch) {
                amount = razorpayAmountMatch[1];  
              }
            }

            // If amount is less than 100, set it to 0
            if(amount < 100){
              amount = 0;
            }

            // Extract transaction ID using regex
            const transactionIdRegex = /\b\d{12}\b/;
            const transactionIdMatch = text.match(transactionIdRegex);
            const transactionId = transactionIdMatch ? transactionIdMatch[0] : 'Not Found';

            // Populate form fields if data found
            if (transactionId !== 'Not Found') {
              setTransactionId(transactionId)
            } else {
              setTransactionId('')
            }

            if (amount !== 'Not Found') {
              setAmount(amount);
            } else {
              setAmount('')
            }

            setLoaderVisible(false);
          })
          .catch((err) => {
            console.error("OCR Error:", err);
            setLoaderVisible(false);
          });
      };

      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
      setLoaderVisible(false);
    }
  };

  const handleTransactionIdChange = (e) => {
    const value = e.target.value;
    setTransactionId(value);

    // Validate the transaction ID on each change
    if (value.length < 11) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        transactionId: 'enter valid utr(minimum 12 digit alpha numarical)',
      }));
    } else {
      // Clear error once 12 digits are entered
      setErrors((prevErrors) => {
        const { transactionId, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Initialize an errors object
    let validationErrors = {};

    // Check if the fields are filled out
    if (!amount) {
      validationErrors.amount = 'Amount is required';
    }
    if (!note) {
      validationErrors.note = 'Notes are required';
    }
    if (!transactionId) {
      validationErrors.transactionId = 'Transaction ID is required';
    } else if (transactionId.length < 11) {
      validationErrors.transactionId = 'enter valid utr(minimum 12 digit alpha numarical)';
    }
    if (!imagePreview) {
      validationErrors.image = 'Image is required';
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are errors, set them to state
      setErrors(validationErrors);

      // Hide the errors after 2 seconds
      setTimeout(() => {
        setErrors({});
      }, 2000);
    } else {
      setIsSubmitted(true);
      try {    
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
    
        const formData = new FormData();
        formData.append("file", currentFile);
        formData.append("utr", transactionId);
        formData.append("amount", amount);
        formData.append("pmethod", currentAccount.pmethod);
        formData.append("accountholdername", currentAccount.accountholdername);
        formData.append("whiteLabelName", window.location.hostname);
    
        // Replace with your image upload endpoint
        const response = await fetch('https://b2cadmin.supermaster.live/api/v1/Account/paymentDeposite', {
          headers: myHeaders,
          method: "POST",
          body: formData,
        });
    
        if (!response.ok) {
          const data = await response.json();
          setIsError(true);
          setErrorMsg(data.message);
          setTimeout(() => {
            setIsError(false);
          });
        }
    
        const data = await response.json();
       if (data.status === 'success') {
          setIsSubmitted(false); 
          setIsUpdate(true);
          setTimeout(() => {
            setIsUpdate(false);
            handleClose();
          }, 3000);  
       }
      } catch (error) {
        console.error("Image upload failed:", error);
        throw new Error("Image upload failed");
      }









      // const myHeaders = new Headers();
      // myHeaders.append("Authorization", `Bearer ${token}`);
      // myHeaders.append("Content-Type", "application/json");
      
      // const formdata = new FormData();
      // formdata.append("utr", transactionId);
      // formdata.append("amount", amount);
      // formdata.append("pmethod", currentAccount.pmethod);
      // formdata.append("accountholdername", currentAccount.accountholdername);
      // formdata.append("file", currentFile, `${currentFile.name}`);
      // formdata.append("whiteLabelName", window.location.hostname);
      // // formdata.append("whiteLabelName", 'b2c.supermaster.live');

      // const requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   body: formdata,
      //   redirect: "follow"
      // };
      // // `${process.env.REACT_APP_BASE_URL}/api/v1/payment/paymentDeposite`
      
      // fetch('https://b2cadmin.supermaster.live/api/v1/Account/paymentDeposite', requestOptions)
      // .then(async (response) => {
      //   let paresData = await response.json();
      //   console.log(paresData,"paymentDepositepaymentDepositepaymentDeposite");
        // setIsSubmitted(false); 
      //   if (response.ok) {
      //     handleClose();
      //   }
      // })
      // .catch((error) => {
      //   setIsSubmitted(false); 
      //   console.log(error);
      // });
    }
  };

  const getPaymentDetails = () => {
    const myHeaders = new Headers();
    // myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBIjoiNjczYzYzY2NmNjU0ZmQxYjYyOGY3ODM4IiwiaWF0IjoxNzM3MzUxNjUxLCJleHAiOjE3MzkxNTE2NTF9.UZtwCY075h8vN0TKomgLxGHElBpuR1cc_WbEkUqGk4c");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/payment/getDepositePaymentDetails`, requestOptions)
    .then(async (response) => {
      let paresData = await response.json();
        if (response.ok) {
          if (paresData && paresData.paymentMethodDetail != 0) {
            let bank = paresData.paymentMethodDetail.filter(item => item.pmethod === "banktransfer" && item.status);
            let upi = paresData.paymentMethodDetail.filter(item => item.pmethod === "upi" && item.status);
            let paytm = paresData.paymentMethodDetail.filter(item => item.pmethod === "paytm" && item.status);
            setIsBankTransfer(bank)
            setIsUPI(upi)
            setIsPaytm(paytm)
            if (paymentTab === 'tab1') {
              if (bank.length > 0) {
                setCurrentAccount(bank[0]);                
              }else{
                setCurrentAccount();
              }
            }else if (paymentTab === 'tab2') {
              if (upi.length > 0) {
                setCurrentAccount(upi[0]);
              }else{
                setCurrentAccount();
              }
            }else if (paymentTab === 'tab3') {
              if (paytm.length > 0) {
                setCurrentAccount(paytm[0]);
              }else{
                setCurrentAccount();
              }
            }
          } else {
            
          }
        }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getPaymentDetails();
    }
  }, [currentAccount]);

  const handleTabChnage =(tab) => {
    setPaymentTab(tab);
    setAccountTab(0);
    if (tab === "tab1") {
      setCurrentAccount(isBankTransfer[0]);
    }else if(tab === "tab2"){
      setCurrentAccount(isUPI[0]);
    }else if(tab === "tab3"){
      setCurrentAccount(isPaytm[0]);
    }
  }

  const handleAccountTab = (index,item) =>{
    setAccountTab(index);
    setCurrentAccount(item);
  }

  const getCurrentAccount = () => {
    if (paymentTab === "tab1") {
      if (currentAccount) {
       return <ul id="BANK-DATA">
          <li id="Acc-Name">{currentAccount.accountholdername}<span class="copy-icon"></span></li>
          <li id="Acc-Number">{currentAccount.accountnumber}<span class="copy-icon"></span></li>
          <li id="Bank-Name">{currentAccount.bankname}<span class="copy-icon"></span></li>
          <li id="IFSC">IFSC : {currentAccount.ifsccode}<span class="copy-icon"></span></li>
        </ul>
      }
    }else if(paymentTab === "tab2"){
      if (currentAccount) {
        return <ul id="BANK-DATA">
          <li id="Acc-Name">{currentAccount.accountholdername}<span class="copy-icon"></span></li>
          <li id="Acc-Number">{currentAccount.upiid}<span class="copy-icon"></span></li>
          <QRCodeCanvas
            value={currentAccount.upiid}
            size={150} // size of the QR code
            bgColor="#ffffff" // background color
            fgColor="#000000" // foreground color
            level="Q" // error correction level
          />
        </ul>
      }
    }else if(paymentTab === "tab3"){
      if (currentAccount) {
        return <ul id="BANK-DATA">
          <li id="Acc-Name">{currentAccount.accountholdername}<span class="copy-icon"></span></li>
          <li id="Acc-Number">{currentAccount.phonenumber}<span class="copy-icon"></span></li>
        </ul>
      }
    }
  }

  const getMainForm = () => {
    return <>
    <div class="enter-payment-detail">
    <div className="title">Enter Payment Details</div>
    <form className="payment-fom" method="post">
    <div className="input-box">
    <input type="text" placeholder="Enter Amount" value={amount} onChange={(e) => {setAmount(e.target.value)}} required/>
    {errors.amount && <span className="error">{errors.amount}</span>}</div>
    <div className="input-box">
    <input type="text" placeholder="Enter Notes" value={note} onChange={(e) => {setNote(e.target.value)}} required/>
    {errors.note && <span className="error">{errors.note}</span>}</div>
    <div className="input-box">
    <input type="text" placeholder="Enter Transaction Reference ID" value={transactionId} onChange={(e) => {handleTransactionIdChange(e)}} required/>
    {errors.transactionId && <span className="error">{errors.transactionId}</span>}</div>
    <div className="input-box">
      <input type="file" 
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*" 
        required
        placeholder="Enter Transaction Reference ID" />
      {/* {loaderVisible && <div className="loader">Processing...</div>} */}
      {imagePreview && <img src={imagePreview} alt="Preview" style={{display: "block", maxWidth: "200px", margin: "10px auto 0px", borderRadius: "10px"}} id="img-preview"  />}

    </div>
    <div className="btn-sec">
    
    <button
      type="submit"
      disabled={isSubmitted}
      className="btn change-pass-model-form-submit-btn"
      onClick={handleSubmit}>Submit</button>
    </div>
  </form></div></>
  }

  const getForms = () => {
    if (paymentTab === "tab1") {
      if (isBankTransfer.length > 0) {
        return getMainForm()
      }
    }else if (paymentTab === "tab2") {
      if (isUPI.length > 0) {
        return getMainForm()
      }
    }else if (paymentTab === "tab3") {
      if (isPaytm.length > 0) {
        return getMainForm()
      }
    }
  }

  const getTab = () => {
    if (paymentTab === "tab1") {
      if (isBankTransfer.length > 0) {
        return <div className="accountnamecontainer">
            {isBankTransfer.map((item,index)=>(
              <div className={`luck-enterprise-tag ${accountTab === index ? 'active':''}`} onClick={()=>handleAccountTab(index,item)} id={index} key={index}>{item.accountholdername}</div>
            ))}
        </div> 
      }else{
        return <ul id="BANK-DATA">This Payment method not allowed yet!!</ul>
      }
    }else if(paymentTab === "tab2"){
      if (isUPI.length > 0) {
        return <div className="accountnamecontainer">
        {isUPI.map((item,index)=>(
          <div className={`luck-enterprise-tag ${accountTab === index ? 'active':''}`} onClick={()=>handleAccountTab(index,item)} id={index} key={index}>{item.accountholdername}</div>     
        ))}
    </div> 
      }else{
        return <ul id="BANK-DATA">This Payment method not allowed yet!!</ul>
      }
    }else if(paymentTab === "tab3"){
      if (isPaytm.length > 0) {
        return <div className="accountnamecontainer">
        {isPaytm.map((item,index)=>(
          <div className={`luck-enterprise-tag ${accountTab === index ? 'active':''}`} onClick={()=>handleAccountTab(index,item)} id={index} key={index}>{item.accountholdername}</div>     
        ))}
        </div>
      }else{
        return <ul id="BANK-DATA">This Payment method not allowed yet!!</ul>
      }
    }
  }

  return (
    <>
    {loaderVisible ? <div className="loader-page"><span className="loader"></span></div> :''}
      <Modal
        show={isopen}
        onHide={handleClose}
        className="menu-dropdown-popup stack-chnage-popup paymentDepositePopup"
        dialogClassName="v-center"
      >
        <Modal.Header closeButton>
          <h2>
            {" "}
            <span className="title-v-border"></span> Deposit
          </h2>
        </Modal.Header>
        <Modal.Body>
        <div className="choose-payment-option">
            <div className="payment-tab">
                <div className="payment-tab-list">
                    <span onClick={()=>handleTabChnage("tab1")} className={`img-payment bank-img ${paymentTab === 'tab1' ? 'active':''}`} ><img src={bankTransfer}/></span>
                    <span onClick={()=>handleTabChnage("tab2")} className={`img-payment upi-img ${paymentTab === 'tab2' ? 'active':''}`}><img src={upiPay}/></span>
                    <span onClick={()=>handleTabChnage("tab3")} className={`img-payment pytm-img ${paymentTab === 'tab3' ? 'active':''}`}><img src={paytmPay}/></span>
                    <span className="payment-mng-link" onClick={()=>{navigate("/depositRequest"); handleClose();}}>Past Requests</span>
                </div>
                <div className="payment-tab-content">
                    <div className="title">Choose Beneficiary Account</div>
                    <div className="luck-enterprise">
                    {getTab()}
                    {getCurrentAccount()}
                    </div>
                </div>
                  {getForms()}
            </div>
        </div>
        </Modal.Body>
      </Modal>

      <Popup
        popupClass={`alertMsgpopup ${isUpdate ? "open" : ""}`}
        content={
          <div className={`alert-msg success`}>
            <span className="msg">Successfully Submitted!!</span>
          </div>
        }
      />

      <Popup
        popupClass={`alertMsgpopup ${isError ? "open" : ""}`}
        content={
          <div className={`alert-msg err`}>
            <span className="msg">{errorMsg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
    </>
  );
}

export default DepositPopup;
