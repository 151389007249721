export const callback = (eventCallback) => {
    // Debug: Log the full event callback for inspection
    console.log("Event Callback Received:", eventCallback);
  
    // Define event handlers
    const ONETAP = () => {
      const { response } = eventCallback;
      const { token } = response;
      console.log("ONETAP Triggered:", { response, token });
      // Implement your custom logic here.
    };
  
    const OTP_AUTO_READ = () => {
      const { response: { otp } } = eventCallback;
      console.log("OTP_AUTO_READ Triggered:", { otp });
      // Auto-read OTP logic here.
    };
  
    const FAILED = () => {
      const { response } = eventCallback;
      console.error("FAILED Triggered:", { response });
      // Handle failure scenarios here.
    };
  
    const FALLBACK_TRIGGERED = () => {
      const { response } = eventCallback;
      console.warn("FALLBACK_TRIGGERED Triggered:", { response });
      // Handle fallback scenarios here.
    };
  
    // Map of response types to handlers
    const EVENTS_MAP = {
      ONETAP,
      OTP_AUTO_READ,
      FAILED,
      FALLBACK_TRIGGERED,
    };
  
    // Check and execute the appropriate handler
    if ("responseType" in eventCallback) {
      const handler = EVENTS_MAP[eventCallback.responseType];
      if (handler) {
        handler();
      } else {
        console.warn("Unhandled responseType:", eventCallback.responseType);
      }
    } else {
      console.error("Invalid eventCallback: Missing 'responseType'");
    }
  };