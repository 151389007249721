import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";

function CommissionPopup({ isopen, handleClose,marketid }) {
  const token = sessionStorage.getItem("token");
      const [plStatement, setPLStatement] = useState();

  useEffect(() => {
    getStackListAPI();
  }, [marketid]);

  const getStackListAPI = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/getdetails?id=${marketid}`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setPLStatement(paresData);
        console.log('12313654584 : ',paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatDatefromAPI = (isoDate) => {
    const date = new Date(isoDate);

    const optionsDate = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const optionsTime = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // This ensures the time is in 12-hour format with AM/PM
    };

    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

    return `${formattedDate} at ${formattedTime}`;
  };

  return (
    <>
      <Modal
        show={isopen}
        onHide={handleClose}
        className="commission-popup"
        dialogClassName="v-center"
      >
        <Modal.Header closeButton>
          <h2>
            {" "}
            <span className="title-v-border"></span> Fancy Book
          </h2>
        </Modal.Header>
        <Modal.Body>
          {(
            <div className="account-table">
      <table border="1" cellPadding="10" cellSpacing="0">
  <thead>
    <tr>
      <th>Date</th>
      <th>Event</th>
      <th>Market</th>
      <th>Bet On</th>
      <th>Odds</th>
      <th>Stake</th>
      <th>Status</th>
      <th>Returns</th>
    </tr>
  </thead>
  <tbody>
    {plStatement && plStatement.betData && plStatement.betData.length > 0 ? (
      plStatement.betData.map((item, index) => (
        <tr
          key={index} className={`${item.bettype2 === "LAY" ? "lay" : "back"}`}
        >
          <td>{formatDatefromAPI(item.date)}</td>
          <td>{item.match}</td>
          <td>{item.marketName}</td>
          <td>{item.selectionName}</td>
          <td>{item.oddValue}</td>
          <td>{item.Stake}</td>
          <td>{item.status}</td>
          <td>{item.returns}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="7" style={{ textAlign: "center" }}>
          No data available
        </td>
      </tr>
    )}
  </tbody>
</table>

      </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CommissionPopup;
