import React, { useContext, useEffect, useState } from 'react'
import useSocket from '../../Websoket.js';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import './matchdata.css'
import { PopupContext } from '../LoginPopup/LoginPopupContext.js';
import betslipclosearrow from '../../assets/images/betslip-close-arrow.png'
import ExchanngeinfoPopup from './ExchanngeinfoPopup.js';

function MatchOdds({ eventAccess, getMatchOdds, onBetClick, isHRGHMarket, HGMarketId }) {
    const matchOdds = {
        "eventId": eventAccess?.matchData?.eventId,
        "marketIds": [
            HGMarketId
        ]
    };

    const [perticularprofit, setperticularprofit] = useState();
    const [multimarketArray, setMultimarketArray] = useState([])
    const isOneClicked = sessionStorage.getItem('isOneClicked')
    let oneClickValue
    if(isOneClicked == 'true'){
        oneClickValue = sessionStorage.getItem('oneClickAmount')
    }
    const [firstTimeCall, setFirstTimeCall] = useState(false)

    const [odds, setOdds] = useState(matchOdds);
    const [slideToggle, setSlideToggle] = useState(false)
    const { isConnected,receivedMatchoddsHG,receivedBookData,receivedunderOvebook, sendMessage,clearCashout } = useSocket();
    const [priceGet, setPriceGet] = useState();
    const [plusMinusValue, setplusMinusValue] = useState();
    const [bklyClassValue, setBklyClass] = useState();
    const [oddsValue, setoddsValue] = useState();
    const [eventObj, setEventObj] = useState(null); // State to store event
    const [oldOddsValue, setOldOddsValue] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
    const { stackArray, openPopup, loggedIn, direactsecId, setSecIds,clearSecId,unsetBetLoader,isBetUpdate,betNotUpdated,isBetLoader, loaderCounter} = useContext(PopupContext);
    const [grulesPopup, setGrulesPopup] = useState();
    const [Beton, setBeton] = useState();
    const [getMatchOddsThisMarket, setgetMatchOddsThisMarket] = useState();
    const [stack, setStack] = useState([]);
    const formatNumberinStack = (num) => {
        if (num >= 1000000000) {
          return (num / 1000000000) + 'B'; // Billion
        } else if (num >= 1000000) {
          return (num / 1000000) + 'M'; // Million
        } else if (num >= 1000) {
          return (num / 1000) + 'K'; // Thousand
        } else {
          return num; // Less than 1000
        }
      };
    const escapeSelectorId = (id) => {
        if(id){
            return String(id).replace(/([#.,+*~':"!^$[\]()=>|\\/])/g, '\\$1');
        }
    };

    const gruleshandleClose = ()=>{
        setGrulesPopup(false);
    }
    const grulesHandle = ()=>{
        setGrulesPopup(true);
    }

    const addAllValue = (newValue,event) => {
        const closestBetslip = event.target.closest('.data-with-betslip');
        if (closestBetslip) {
            const bookValueDiv = closestBetslip.querySelector('.book-value');
            if (bookValueDiv) {
                let value
                if(bookValueDiv.innerText == ''){
                    if(bklyClassValue === 'back'){
                        value = (newValue * oddsValue) - newValue
                    }else{
                        value = -((newValue * oddsValue) - newValue)
                    }
                }else{
                    let previousValue = bookValueDiv.innerText
                    if(bklyClassValue === 'back'){
                        value = parseFloat((newValue * oddsValue) - newValue) + parseFloat(previousValue)
                    }else{
                        value =previousValue - parseFloat((newValue * oddsValue) - newValue)
                    }
                }
                bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                setperticularprofit(value)
                if(value > 0){
                    bookValueDiv.classList.remove('red-txt')
                    bookValueDiv.classList.add('green-txt')
                }else{
                    bookValueDiv.classList.remove('green-txt')
                    bookValueDiv.classList.add('red-txt')
                }
                const closestBetslip2 = event.target.closest('.match-list');
                const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');

                allBookValueDivs.forEach(div => {
                    if (div !== bookValueDiv) {
                        let value
                        if(div.innerText == ''){
                            if(bklyClassValue === 'back'){
                                value = -newValue
                            }else{
                                value = newValue
                            }
                        }else{
                            let previousValue = div.innerText
                            if(bklyClassValue === 'back'){
                                value = previousValue - newValue
                            }else{
                                value =  parseFloat(newValue) + parseFloat(previousValue)
                            }
                        }
                        div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                        if(value > 0){
                            div.classList.remove('red-txt')
                            div.classList.add('green-txt')
                        }else{
                            div.classList.remove('green-txt')
                            div.classList.add('red-txt')
                        }

                    }
                });
            } else {
                console.log('.book-value div not found');
            }
        } else {
            console.log('.data-with-betslip not found');
        }

        setplusMinusValue(newValue);
        setPriceGet(newValue);
    };

    const addValue = (newValue,event) => {
        const closestBetslip = event.target.closest('.data-with-betslip');
        if (closestBetslip) {
            const bookValueDiv = closestBetslip.querySelector('.book-value');
            if (bookValueDiv) {
                let value
                if(bookValueDiv.innerText == ''){
                    if(bklyClassValue === 'back'){
                        value = (newValue * oddsValue) - newValue
                    }else{
                        value = -((newValue * oddsValue) - newValue)
                    }
                }else{
                    let previousValue = bookValueDiv.innerText
                    if(bklyClassValue === 'back'){
                        value = parseFloat((newValue * oddsValue) - newValue) + parseFloat(previousValue)
                    }else{
                        value = previousValue - parseFloat((newValue * oddsValue) - newValue)
                    }
                }
                bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                setperticularprofit(value)
                if(value > 0){
                    bookValueDiv.classList.remove('red-txt')
                    bookValueDiv.classList.add('green-txt')
                }else{
                    bookValueDiv.classList.remove('green-txt')
                    bookValueDiv.classList.add('red-txt')
                }
                const closestBetslip2 = event.target.closest('.match-list');
                const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');

                allBookValueDivs.forEach(div => {
                    if (div !== bookValueDiv) {
                        let value
                        if(div.innerText == ''){
                            if(bklyClassValue === 'back'){
                                value = -newValue
                            }else{
                                value = newValue
                            }
                        }else{
                            let previousValue = div.innerText
                            if(bklyClassValue === 'back'){
                                value = previousValue - newValue
                            }else{
                                value =   parseFloat(previousValue) + parseFloat(newValue)
                            }
                        }
                        div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                        if(value > 0){
                            div.classList.remove('red-txt')
                            div.classList.add('green-txt')
                        }else{
                            div.classList.remove('green-txt')
                            div.classList.add('red-txt')
                        }

                    }
                });
            } else {
                console.log('.book-value div not found');
            }
        } else {
            console.log('.data-with-betslip not found');
        }



        setplusMinusValue(newValue)
        if (priceGet !== undefined) {
            setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
        } else {
            setPriceGet(newValue);
        }

    };

    const handleSendMarketBookDetails = () => {
        // console.log('ttttttttttttttttttttttttttttttttttttttttttttttttttttt');

        let eventId =  eventAccess?.matchData?.eventId;
        let marketId2 = HGMarketId;
        let userinfo = sessionStorage.getItem('userinfo');
        if (userinfo && eventId) {
            userinfo = JSON.parse(userinfo);
            if(marketId2){
                sendMessage('marketIdbookDetails', { eventId, marketId:marketId2, user: userinfo });
            }
        } else if(!userinfo){
            if(marketId2){
                sendMessage('marketIdbookDetails', { eventId, marketId:marketId2 });
            }
        }else{
            console.error('Missing eventId or marketId');
        }
    };

    useEffect(() => {
        setPreviousBookValues();
    }, [receivedBookData]);


    useEffect(() => {
        if (getMatchOdds) {
            let getMatchOddsTemp = getMatchOdds[`${receivedMatchoddsHG?.finalResult?.[0]?.marketId}`]
            setgetMatchOddsThisMarket(getMatchOddsTemp)
        }
        
    }, [getMatchOdds,receivedMatchoddsHG ]);

    const setPreviousBookValues = () => {
        if (receivedBookData && receivedBookData.showData) {
            for (let i = 0; i < receivedBookData.showData.length; i++) {
                const originalId = receivedBookData.showData[i].runnerId;
                const escapedId = escapeSelectorId(originalId);
                const element = document.getElementById(`${escapedId}`);
                if (element) {
                    const mainDiv = element.querySelector('.book-value');
                    if (mainDiv) {
                        mainDiv.innerText = receivedBookData.showData[i].amount !== null && receivedBookData.showData[i].amount !== undefined &&  !isNaN(Number(receivedBookData.showData[i].amount)) ? Number(receivedBookData.showData[i].amount).toFixed(2) : 'Invalid data';
                        if (receivedBookData.showData[i].amount > 0) {
                            mainDiv.classList.remove('red-txt');
                            mainDiv.classList.add('green-txt');
                        } else {
                            mainDiv.classList.remove('green-txt');
                            mainDiv.classList.add('red-txt');
                        }
                    } else {
                        console.error(`.book-value not found for ID: #${escapedId}`);
                    }
                } else {
                    console.error(`Element with ID #${escapedId} not found. Original ID was: ${originalId}`);
                }
            }
            if (receivedBookData.showData.length == 0) {

                // Use the ID directly with getElementById
                let element = document.getElementById(receivedBookData.marketId);

                if (!element) {
                    // If element is still null, escape special characters for querySelector
                    const escapedId = receivedBookData.marketId.replace(/([.#$[\]])/g, '\\$1');

                    // Use querySelector with escaped ID
                    element = document.getElementById(`${escapedId}`);
                }


                if (element) {
                    let divs = element.querySelectorAll('.book-value');

                    divs.forEach(div => {
                        div.innerText = '';  // Clear the text inside each .book-value div
                    });
                } else {
                }
            }
        }
        if (receivedunderOvebook && receivedunderOvebook.showData) {
            for (let i = 0; i < receivedunderOvebook.showData.length; i++) {
                const originalId = receivedunderOvebook.showData[i].runnerId;
                const escapedId = escapeSelectorId(originalId);
                const element = document.getElementById(`${escapedId}`);
                if (element) {
                    const mainDiv = element.querySelector('.book-value');
                    if (mainDiv) {
                        mainDiv.innerText = receivedunderOvebook.showData[i].amount !== null && receivedunderOvebook.showData[i].amount !== undefined &&  !isNaN(Number(receivedunderOvebook.showData[i].amount)) ? Number(receivedunderOvebook.showData[i].amount).toFixed(2) : 'Invalid data';
                        if (receivedunderOvebook.showData[i].amount > 0) {
                            mainDiv.classList.remove('red-txt');
                            mainDiv.classList.add('green-txt');
                        } else {
                            mainDiv.classList.remove('green-txt');
                            mainDiv.classList.add('red-txt');
                        }
                    } else {
                        console.error(`.book-value not found for ID: #${escapedId}`);
                    }
                } else {
                    console.error(`Element with ID #${escapedId} not found. Original ID was: ${originalId}`);
                }
            }
            if (receivedunderOvebook.showData.length == 0) {

                let elements = document.querySelectorAll('.under-over');

                elements.forEach(element => {
                    let divs = element.querySelectorAll('.book-value');

                    divs.forEach(div => {
                        div.innerText = '';
                    });
                })
            }else{
                let elements = document.querySelectorAll('.under-over');
                // console.log(receivedunderOvebook);

                elements.forEach(element => {
                    if(receivedunderOvebook.showData[0]){
                        if(!receivedunderOvebook.showData.some(item => item.marketId == element.id)){
                            let divs = element.querySelectorAll('.book-value');

                            divs.forEach(div => {
                                div.innerText = '';
                            });
                        }
                    }else{
                        let divs = element.querySelectorAll('.book-value');

                        divs.forEach(div => {
                            div.innerText = '';
                        });
                    }
                })

            }
        }
    };

    const getBetSlipClasss = (oddItem) => {
        if (direactsecId === oddItem.runnerId) {
            if(oddItem.status !== 'ACTIVE'){
                clearSecId()
                unsetBetLoader();
                return 'betslip-wraper'
            }else{
                return 'betslip-wraper open'
            }
        }
        return 'betslip-wraper';
      };

    useEffect(() => {
                    try{
                        setStack(JSON.parse(stackArray));
                        if(!isBetLoader){
                            handlecloseSlip();
                        }
                    }catch(err){
                        console.log(err, 'errerrerrerr');
                        setStack(stackArray)
                        if(!isBetLoader){
                            handlecloseSlip();
                        }
                    }
                  }, [isBetLoader, loaderCounter,stackArray]);

      useEffect(() => {
        if (direactsecId !== '') {
          setPreviousBookValues();
        }
      }, [direactsecId]);

      useEffect(() => {
              if (oddsValue !== null && eventObj !== null) {
                updateOddsBook(eventObj)
              }
          }, [oddsValue]);

    const updateOddsBook = (event) => {
            const closestBetslip = event.target.closest('.data-with-betslip');
            if (closestBetslip) {
                const bookValueDiv = closestBetslip.querySelector('.book-value');
                if (bookValueDiv) {
                    if(priceGet){
                        let value
                    if(bookValueDiv.innerText === ''){
                        if(bklyClassValue === 'back'){
                            value = (priceGet * oddsValue) - priceGet
                        }else{
                            value = -((priceGet * oddsValue) - priceGet)
                        }
                    }else{
                        let previousValue = bookValueDiv.innerText
                        // console.log(oldOddsValue,  );

                        if(bklyClassValue === 'back'){
                            let oldValue = parseFloat((priceGet * oldOddsValue)- priceGet)
                            let newValue = parseFloat((priceGet * oddsValue)- priceGet)
                            value = parseFloat(previousValue) + newValue - oldValue
                        }else{
                            let oldValue = -parseFloat((priceGet * oldOddsValue)- priceGet)
                            let newValue = -parseFloat((priceGet * oddsValue)- priceGet)
                            value =  parseFloat(previousValue) - oldValue + newValue

                        }
                    }
                    bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
        ? Number(value).toFixed(2)
        : 'Invalid value';
                    setperticularprofit(value)
                    if(value > 0){
                        bookValueDiv.classList.remove('red-txt')
                        bookValueDiv.classList.add('green-txt')
                    }else{
                        bookValueDiv.classList.remove('green-txt')
                        bookValueDiv.classList.add('red-txt')
                    }
                    const closestBetslip2 = event.target.closest('.match-list');
                    const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
                    allBookValueDivs.forEach(div => {
                        if (div !== bookValueDiv) {
                            let value
                            if(div.innerText == ''){
                                if(bklyClassValue === 'back'){
                                    value = -priceGet
                                }else{
                                    value = +priceGet
                                }
                                div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
        ? Number(value).toFixed(2)
        : 'Invalid value';
                            if(value > 0){
                                div.classList.remove('red-txt')
                                div.classList.add('green-txt')
                            }else{
                                div.classList.remove('green-txt')
                                div.classList.add('red-txt')
                            }
                            }else{
                                let previousValue = div.innerText
                                // if(bklyClassValue === 'back'){
                                //     value = previousValue - priceGet
                                // }else{
                                //     value =  parseFloat(priceGet) + parseFloat(previousValue)
                                // }
                            }


                        }
                    });
                    }else{
                        let diff = Math.abs(priceGet)

                        const closestBetslip = event.target.closest('.data-with-betslip');
                        if (closestBetslip) {
                            const bookValueDiv = closestBetslip.querySelector('.book-value');
                            if (bookValueDiv) {
                                let value
                                if(bookValueDiv.innerText == ''){
                                    if(bklyClassValue === 'back'){
                                        value = (priceGet * oddsValue)- priceGet
                                    }else{
                                        value = -((priceGet * oddsValue)- priceGet)
                                    }
                                }else{
                                    let previousValue = bookValueDiv.innerText
                                    if(bklyClassValue === 'back'){
                                        value = parseFloat(previousValue) - parseFloat((diff * oddsValue)- diff)
                                    }else{
                                        value = parseFloat(previousValue) + parseFloat((diff * oddsValue)- diff)
                                    }
                                }
                                bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
        ? Number(value).toFixed(2)
        : 'Invalid value';
                                setperticularprofit(value)
                                if(value > 0){
                                    bookValueDiv.classList.remove('red-txt')
                                    bookValueDiv.classList.add('green-txt')
                                }else{
                                    bookValueDiv.classList.remove('green-txt')
                                    bookValueDiv.classList.add('red-txt')
                                }
                                const closestBetslip2 = event.target.closest('.match-list');
                                const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
                                allBookValueDivs.forEach(div => {
                                    if (div !== bookValueDiv) {
                                        let value
                                        if(div.innerText == ''){
                                            if(bklyClassValue === 'back'){
                                                value = -priceGet
                                            }else{
                                                value = priceGet
                                            }
                                        }else{
                                            let previousValue = div.innerText
                                            if(bklyClassValue === 'back'){
                                                value =  parseFloat(previousValue) + parseFloat(diff)
                                            }else{
                                                value =  parseFloat(previousValue) - parseFloat(diff)
                                            }
                                        }
                                        div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
        ? Number(value).toFixed(2)
        : 'Invalid value';
                                        if(value > 0){
                                            div.classList.remove('red-txt')
                                            div.classList.add('green-txt')
                                        }else{
                                            div.classList.remove('green-txt')
                                            div.classList.add('red-txt')
                                        }

                                    }
                                });
                            }
                        }
                    }
                }
            }
    }

      const handleIncrementOdds = (event) => {
        setOldOddsValue(oddsValue);
        setEventObj(event);
        setoddsValue(parseFloat((Number(oddsValue) + 0.01)).toFixed(2))
      };

    const handleDecrementOdds = (event) => {
        setOldOddsValue(oddsValue);
        setEventObj(event);
        setoddsValue(parseFloat((Number(oddsValue) - 0.01)).toFixed(2))
      };

    const updateBook = (event, oldvalue, newvalue) => {
        const closestBetslip = event.target.closest('.data-with-betslip');
        if (closestBetslip) {
            const bookValueDiv = closestBetslip.querySelector('.book-value');
            if (bookValueDiv) {
                if(oldvalue < newvalue){
                    newvalue = newvalue - oldvalue
                    let value
                if(bookValueDiv.innerText === ''){
                    if(bklyClassValue === 'back'){
                        value = (newvalue * oddsValue) - newvalue
                    }else{
                        value = -((newvalue * oddsValue) - newvalue)
                    }
                }else{
                    let previousValue = bookValueDiv.innerText
                    if(bklyClassValue === 'back'){
                        value = parseFloat((newvalue * oddsValue)- newvalue) + parseFloat(previousValue)
                    }else{
                        value = previousValue - parseFloat((newvalue * oddsValue)- newvalue)
                    }
                }
                bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                setperticularprofit(value)
                if(value > 0){
                    bookValueDiv.classList.remove('red-txt')
                    bookValueDiv.classList.add('green-txt')
                }else{
                    bookValueDiv.classList.remove('green-txt')
                    bookValueDiv.classList.add('red-txt')
                }
                const closestBetslip2 = event.target.closest('.match-list');
                const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
                allBookValueDivs.forEach(div => {
                    if (div !== bookValueDiv) {
                        let value
                        if(div.innerText == ''){
                            if(bklyClassValue === 'back'){
                                value = -newvalue
                            }else{
                                value = +newvalue
                            }
                        }else{
                            let previousValue = div.innerText
                            if(bklyClassValue === 'back'){
                                value = previousValue - newvalue
                            }else{
                                value =  parseFloat(newvalue) + parseFloat(previousValue)
                            }
                        }
                        div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                        if(value > 0){
                            div.classList.remove('red-txt')
                            div.classList.add('green-txt')
                        }else{
                            div.classList.remove('green-txt')
                            div.classList.add('red-txt')
                        }

                    }
                });
                }else{
                    let diff = Math.abs(newvalue - oldvalue)

                    const closestBetslip = event.target.closest('.data-with-betslip');
                    if (closestBetslip) {
                        const bookValueDiv = closestBetslip.querySelector('.book-value');
                        if (bookValueDiv) {
                            let value
                            if(bookValueDiv.innerText == ''){
                                if(bklyClassValue === 'back'){
                                    value = (newvalue * oddsValue)- newvalue
                                }else{
                                    value = -((newvalue * oddsValue)- newvalue)
                                }
                            }else{
                                let previousValue = bookValueDiv.innerText
                                if(bklyClassValue === 'back'){
                                    value = parseFloat(previousValue) - parseFloat((diff * oddsValue)- diff)
                                }else{
                                    value = parseFloat(previousValue) + parseFloat((diff * oddsValue)- diff)
                                }
                            }
                            bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                            setperticularprofit(value)
                            if(value > 0){
                                bookValueDiv.classList.remove('red-txt')
                                bookValueDiv.classList.add('green-txt')
                            }else{
                                bookValueDiv.classList.remove('green-txt')
                                bookValueDiv.classList.add('red-txt')
                            }
                            const closestBetslip2 = event.target.closest('.match-list');
                            const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
                            allBookValueDivs.forEach(div => {
                                if (div !== bookValueDiv) {
                                    let value
                                    if(div.innerText == ''){
                                        if(bklyClassValue === 'back'){
                                            value = -newvalue
                                        }else{
                                            value = newvalue
                                        }
                                    }else{
                                        let previousValue = div.innerText
                                        if(bklyClassValue === 'back'){
                                            value =  parseFloat(previousValue) + parseFloat(diff)
                                        }else{
                                            value =  parseFloat(previousValue) - parseFloat(diff)
                                        }
                                    }
                                    div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                                    if(value > 0){
                                        div.classList.remove('red-txt')
                                        div.classList.add('green-txt')
                                    }else{
                                        div.classList.remove('green-txt')
                                        div.classList.add('red-txt')
                                    }

                                }
                            });
                        }
                    }
                }
            }
        }
    }


    const PlusValue = (event) => {
        const newValue =  plusMinusValue ? plusMinusValue : 100
        let oldValue = priceGet
        if(oldValue.toString().trim() === ''){
            oldValue = 0
        }
        if (priceGet !== undefined) {
            setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
        } else {
            oldValue = 0
            setPriceGet(newValue);
        }
        let newValuestake =  parseFloat(oldValue) + parseFloat(newValue)

        updateBook(event, oldValue, newValuestake)
    };
    const subtractValue = (event) => {
        const valueToSubtract  =  plusMinusValue ? plusMinusValue : 100
        let oldValue = priceGet

        setPriceGet(prevPrice => {
            const currentPrice = prevPrice ? Number(prevPrice) : 0;
            const newPrice = currentPrice - valueToSubtract;
            return newPrice < 0 ? 0 : newPrice;
        });
        if(oldValue){
            let newValue = oldValue - valueToSubtract
            if(newValue > 0){

                updateBook(event, oldValue, newValue)
            }else{
                setPreviousBookValues()
            }
        }
    };

    const incrementValue = (increment, event) => {
        let oldValue = priceGet
        setPriceGet(prevPrice => {
            const currentPrice = prevPrice ? Number(prevPrice) : 0;
            return currentPrice + increment;
        });
        let newValue = parseFloat(oldValue + increment)

        updateBook(event, oldValue, newValue)
    };

    const removeLastDigit = (event) => {
        let oldValue = priceGet
        setPriceGet(prevPrice => {
            if (prevPrice !== undefined) {
                const newPrice = String(prevPrice).slice(0, -1);
                return newPrice === '' ? 0 : Number(newPrice);
            }
            return 0;
        });
        if(oldValue){
            let newValue = String(oldValue).slice(0, -1);
            updateBook(event, oldValue, newValue)
        }else{
            setPreviousBookValues()
        }
    };

    useEffect(() => {
        let intervalId;
        if (isConnected) {
            let matchdata2 = null
            let userData = sessionStorage.getItem('userId')
            // console.log(eventAccess,"/----------eventAccess");
            if(userData){
                matchdata2 = {
                    "eventId": eventAccess?.matchData?.eventId,
                    "marketIds": [
                        HGMarketId
                    ],
                    userId: sessionStorage.getItem('userId')
                }
            }else{
                matchdata2 = {
                    "eventId": eventAccess?.matchData?.eventId,
                    "marketIds": [
                        HGMarketId
                    ]
                }
            }
            if (!firstTimeCall) {
                setFirstTimeCall(true)
                sendMessage('matchOddsMarketIdsHG', matchdata2);
                setMultimarketArray(eventAccess.multiMarketArray);
            }

            intervalId = setInterval(() => {
                sendMessage('matchOddsMarketIdsHG', matchdata2);
            }, 5000);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };

    }, [isConnected, odds, sendMessage]);

    const getLockDataBACK = (backPriceData,oddItem,backPriceExists,position) => {
        if (backPriceData != undefined && backPriceData?.price != 0 && backPriceData?.price != "" ) {
            return(<>
                <strong>{backPriceExists ? backPriceData?.price : oddItem?.backPrices[position]?.price}</strong>
                <small>
                    {backPriceExists ? formatNumber(backPriceData?.size) : formatNumber(oddItem?.backPrices[position]?.size)}
                </small>
            </>)
        }else{
            return(<i className="fa fa-lock"></i>)
        }
    }
    const onClickFunction = (runnerId, toggleclass, event, runnerName,backPriceData) =>{
        event.persist();
        setSecIds(runnerId);
        setSlideToggle(slideToggle === runnerId ? runnerId : runnerId);
        setBklyClass(toggleclass);
        const strongElement = event.target.tagName === 'SPAN'
            ? event.target.querySelector('strong').innerText
            : event.target.closest('span')?.querySelector('strong').innerText;
            setoddsValue(backPriceData.price);
            setPriceGet(oneClickValue)
            onBetClick({ eventid: receivedMatchoddsHG?.finalResult?.[0]?.eventId, marketId: receivedMatchoddsHG?.finalResult?.[0].marketId, stake: oneClickValue*1, bklyClassValue:toggleclass, selectionName: runnerName, secId: runnerId, oddsValue:backPriceData.price })
    }

    useEffect(() => {
        let intervalId;

        if(isBetUpdate){
            handleSendMarketBookDetails();
            betNotUpdated();
        }
        const userinfo = sessionStorage.getItem('userinfo');
        if (userinfo) {
            if (isConnected) {
                let check = 0
                setInterval(() => {
                    if(check < 3){
                        handleSendMarketBookDetails();
                        check ++
                    }else{
                        clearInterval(intervalId);
                    }


                }, 1000);
            }

        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isConnected, sendMessage,isBetUpdate]);


    const toggleHandle = (slideId, bklyClass, event, runnerName,backPriceData) => {
        const strongElement = event.target.tagName === 'SPAN'
            ? event.target.querySelector('strong').innerText
            : event.target.closest('span')?.querySelector('strong').innerText;
        setSlideToggle(slideToggle === slideId ? slideId : slideId);
        setBklyClass(bklyClass);
        setoddsValue(backPriceData.price);
        setSecIds(slideId);
        setBeton(runnerName)
        setPriceGet('')

    }

    const handlecloseSlip =() => {
        clearCashout()
        setSlideToggle(false);
        clearSecId();
        handleSendMarketBookDetails();
        // setPriceGet(0);
    }

    const checkUpdateSize = (oddItem,targetClass,targetId) => {
        removeBackgroundColor();
        // const targetElement = document.getElementById(targetId);

         // Use querySelector to target based on class and data-id
         const targetElement = document.querySelector(`.${targetClass}#${targetId}[data-id="${oddItem.runnerId}"]`);

        // console.log("Element found: ", targetElement);
        if (
            targetElement
        ) {
            // console.log(oddItem.runnerId,targetClass, targetElement.querySelector('strong')?.innerText.trim(),"-----------------------");
            return targetElement.querySelector('small')?.innerText.trim() || 0;
            // const smallText = targetElement.querySelector('small')?.innerText.trim() || null;

        } else {
            // console.log("Element not found or does not match criteria!", oddItem.runnerId, targetClass, targetId);
            return 0;
        }
    }

    const checkUpdateOdds = (oddItem,targetClass,targetId) => {
        removeBackgroundColor();
        // const targetElement = document.getElementById(targetId);

         // Use querySelector to target based on class and data-id
         const targetElement = document.querySelector(`.${targetClass}#${targetId}[data-id="${oddItem.runnerId}"]`);

        // console.log("Element found: ", targetElement);
        if (
            targetElement
        ) {
            // console.log(oddItem.runnerId,targetClass, targetElement.querySelector('strong')?.innerText.trim(),"-----------------------");
            return targetElement.querySelector('strong')?.innerText.trim() || 0;
            // const smallText = targetElement.querySelector('small')?.innerText.trim() || null;

        } else {
            // console.log("Element not found or does not match criteria!", oddItem.runnerId, targetClass, targetId);
            return 0;
        }
    }

    function removeBackgroundColor() {
        const spans = document.querySelectorAll('.box .blue');
        spans.forEach((span) => {
            // Check if the span has a yellow background color
            if (span.style.backgroundColor === 'gold') {
                // Set an interval to remove the background color after 0.3 seconds
                setTimeout(() => {
                    span.style.backgroundColor = ''; // Remove background color
                }, 200); // 300ms (0.3 seconds)
            }
        });

        const spans1 = document.querySelectorAll('.box .pink');
        spans1.forEach((span) => {
            // Check if the span has a yellow background color
            if (span.style.backgroundColor === 'gold') {
                // Set an interval to remove the background color after 0.3 seconds
                setTimeout(() => {
                    span.style.backgroundColor = ''; // Remove background color
                }, 200); // 300ms (0.3 seconds)
            }
        });
    }

    useEffect(() => {

        if (getMatchOddsThisMarket?.status !== 'OPEN' || getMatchOddsThisMarket?.runners?.some(runner => runner.status !== "ACTIVE")) {
            setSlideToggle(false);
        }
    }, [getMatchOddsThisMarket]);

    const formatNumber = (num) => {
        if (num >= 1000000000) {
          return (num / 1000000000).toFixed(2) + 'B'; // Billion
        } else if (num >= 1000000) {
          return (num / 1000000).toFixed(2) + 'M'; // Million
        } else if (num >= 1000) {
          return (num / 1000).toFixed(2) + 'K'; // Thousand
        } else {
          return num; // Less than 1000
        }
      };

      function isSuspended(receivedMatchoddsHG, getMatchOdds, index) {
        // Check the conditions for suspension
        if (getMatchOdds!= null) {
            getMatchOdds = getMatchOdds[`${receivedMatchoddsHG?.finalResult?.[0]?.marketId}`]

            if (receivedMatchoddsHG?.finalResult[0].resumesuspendstatus) {
                if (getMatchOdds?.status !== 'OPEN' || getMatchOdds?.runners?.[index]?.status !== 'ACTIVE') {
                    return true;
                }
                } else {
                return true;
            }
        }

        return false;
      }

      const convertToOriginalValue = (shorthand) => {
        // Ensure shorthand is a string
        if (typeof shorthand !== 'string') {
            return shorthand; // Return the value as is if it's not a string
        }

        const value = parseFloat(shorthand); // Extract numeric part
        if (shorthand.toUpperCase().endsWith('K')) {
            return value * 1_000; // Multiply by 1,000 for 'K'
        } else if (shorthand.toUpperCase().endsWith('M')) {
            return value * 1_000_000; // Multiply by 1,000,000 for 'M'
        }
        return value; // Return the numeric value if no suffix
    };

    const allValue = () => {
        let userinfo = sessionStorage.getItem('balance');
        if (userinfo !== null) {
            let data = JSON.parse(userinfo);
            return (data.availableBalance - data.totalExposure).toFixed(2);
        }else{
            return 0;
        }
    };

    const mutliMarketAddRemove =(marketID,evenID) =>{
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const raw = JSON.stringify({
            marketId: marketID,
            eventId: evenID
        });

        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/armultimarket`, requestOptions)
        .then(async (response) => {
            let paresData = await response.json();
            if(response.ok){
                setMultimarketArray(paresData.marketId)
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
    }
    const isMarketIdPresent = (multimarketArray, receivedMatchoddsHG) => {
        return multimarketArray.includes(receivedMatchoddsHG?.finalResult?.[0]?.marketId);
    };



        return (
            <>

                {(isHRGHMarket && receivedMatchoddsHG) ? <div className='sport-all-data inner-match-list'>
                    <div className='match-wrapper'>

                     <Accordion transition transitionTimeout={350}>
                        <AccordionItem initialEntered header={<div className={`heading`}>
                            <div className='heading-text sport-name'>
                            {receivedMatchoddsHG?.finalResult?.[0]?.marketName} <span className='rulespopup-btn' onClick={grulesHandle}><i className="fa-solid fa-circle-info"></i></span>
                            </div>
                            <div className='heading-text sport-name'>
                                Min : {receivedMatchoddsHG?.finalResult?.[0]?.min_stake}, Max : {receivedMatchoddsHG?.finalResult?.[0]?.max_stake}
                            </div>
                        </div>}>

                        <div className='heading match-heading'>
                            <div className='heading-text sport-name'>
                                Market
                            </div>
                            <div className='odds-heading'>
                            <div className='heading-text box blue'>Back</div>
                            <div className='heading-text box pink'>Lay</div>
                            </div>
                        </div>
                        <div className='match-data'>
                            <div className='match-list' id={receivedMatchoddsHG?.finalResult?.[0].marketId}>
                                {receivedMatchoddsHG?.finalResult?.[0]?.runners.map((oddItem, index) => (
                                    <div className='data-with-betslip' key={index} id={oddItem.runnerId}>
                                        <div className='match-info-row'>
                                            {eventAccess?.matchData?.sportId == '7'?
                                            <div className="title-w-popover">
                                            <div className="horse-number">
                                            <span>{oddItem.metadata.CLOTH_NUMBER_ALPHA}</span>
                                            <span>({oddItem.metadata.STALL_DRAW})</span>
                                            </div>
                                            {oddItem.metadata.SIRE_NAME?
                                            <div className="icon"><img className="w-90" src={`https://${window.location.hostname}/images/tshirt.png`} height="28px" width="28px" alt=""/></div>
                                            :<div className="icon"></div>}
                                            <div className="title runnerName-div">  {oddItem.runnerName} <span>{oddItem.metadata.JOCKEY_NAME}</span> </div>
                                            <div className="popover">
                                                <ul className="popover-list">
                                                    <li><strong>Trainer</strong>{oddItem.metadata.TRAINER_NAME}</li>
                                                    <li><strong>Age/Weight</strong>{oddItem.metadata.AGE}</li>
                                                    <li><strong>Form</strong>{oddItem.metadata.FORM}</li>
                                                    <li><strong>Days since last run</strong>{oddItem.metadata.DAYS_SINCE_LAST_RUN}</li>
                                                    {oddItem.metadata.JOCKEY_CLAIM
                                                        ?<li><strong>Jockey claim</strong>{oddItem.metadata.JOCKEY_NAME}</li>
                                                        :<li><strong>Jockey claim</strong>-</li>
                                                    }
                                                        {oddItem.metadata.WEARING
                                                            ?<li><strong>Wearning</strong>{oddItem.metadata.WEARING}</li>
                                                            :<li><strong>Wearning</strong>-</li>
                                                        }

                                                </ul>
                                            </div>
                                            </div>
                                            :<div className='match-name'>{oddItem.runnerName}</div>
                                            }
                                            <div className={`${(receivedBookData && receivedBookData.showData && receivedBookData.showData.length !== 0
                                                && receivedBookData.showData.find(item => item.runnerId === oddItem.runnerId)?.amount < 0)
                                                ? 'red-txt'
                                                : 'green-txt'} book-value`}>
                                            {receivedBookData && receivedBookData.showData && receivedBookData.showData.length !== 0
                                                ? (receivedBookData.showData.find(item => item.runnerId === oddItem.runnerId)?.amount || 0)
                                                : ''
                                            }
                                            </div>
                                            <div className='match-odds'>

                                            {isSuspended(receivedMatchoddsHG, getMatchOdds, index) &&  (
                                            <>
                                            <div className='suspended'>Suspended</div>
                                            {/* {clearSecId()}
                                            {unsetBetLoader()} */}
                                            </>
                                            )}

                                                <div className='box'>
                                                {Array.from({ length: 3 }).map((_, position,array) => {
                                                    {/* const position = array.length - 1 - position;  */}
                                                const backPriceExists = oddItem?.backPrices && oddItem?.backPrices[position];
                                                const backPriceData = getMatchOddsThisMarket?.runners?.[index]?.backPrices?.slice().reverse()[position];
                                                const isLocked =
                                                    !receivedMatchoddsHG?.finalResult[0].resumesuspendstatus ||
                                                    getMatchOddsThisMarket?.status !== 'OPEN' ||
                                                    getMatchOddsThisMarket?.runners?.[index]?.status !== 'ACTIVE' ||
                                                    !backPriceExists ||
                                                    (backPriceData && backPriceData.price === 0);

                                                return (
                                                    <span
                                                        key={position}
                                                        className={`blue ${!backPriceExists || (backPriceData && backPriceData.price === 0) ? 'lock' : ''} ${receivedMatchoddsHG?.finalResult?.[0].max_odd < oddItem?.backPrices[position]?.price ? 'disable' : ''}`}
                                                        style={{backgroundColor:checkUpdateOdds(oddItem, "blue", `id-${position}`) == backPriceData?.price || checkUpdateSize(oddItem, "blue", `id-${position}`) == formatNumber(backPriceData?.size)? 'gold' : 'revert-layer'}}
                                                        data-id={oddItem.runnerId}
                                                        id={`id-${position}`}
                                                        onClick={(event) => {
                                                            if(!isBetLoader){
                                                            if (backPriceExists) {
                                                                handleSendMarketBookDetails();
                                                                if (isOneClicked === "true") {
                                                                    onClickFunction(oddItem.runnerId, 'back', event, oddItem.runnerName, getMatchOddsThisMarket?.runners?.[index]?.backPrices?.[0]);
                                                                } else {
                                                                    toggleHandle(oddItem.runnerId, 'back', event, oddItem.runnerName, getMatchOddsThisMarket?.runners?.[index]?.backPrices?.[0]);
                                                                }
                                                            }
                                                            }
                                                        }}
                                                    >
                                                        {isLocked ? (
                                                            <i className="fa fa-lock"></i>
                                                        ) : (
                                                            <>
                                                            {getLockDataBACK(backPriceData,oddItem,backPriceExists,position)}
                                                            </>
                                                        )}
                                                    </span>
                                                );
                                            })}


                                                </div>

                                                <div className='box'>
                                                {Array.from({ length: 3 }).map((_, position) => {
                                                const layPriceExists = oddItem?.layPrices && oddItem?.layPrices[position];
                                                const layPriceData = getMatchOddsThisMarket?.runners?.[index]?.layPrices?.[position];
                                                const isLocked =
                                                    !receivedMatchoddsHG?.finalResult[0].resumesuspendstatus ||
                                                    getMatchOddsThisMarket?.status !== 'OPEN' ||
                                                    getMatchOddsThisMarket?.runners?.[index]?.status !== 'ACTIVE' ||
                                                    !layPriceExists ||
                                                    (layPriceData && layPriceData.price === 0);

                                                return (
                                                    <span
                                                        key={position}
                                                        className={`pink lock`}
                                                    >
                                                        <i className="fa fa-lock"></i>
                                                    </span>
                                                );
                                            })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={getBetSlipClasss(oddItem)}>
                                                <div className={`betslip ${direactsecId === oddItem.runnerId ? 'active' : ''} ${bklyClassValue}`}>

                                                    <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                                    <div className="betslip-top">
                                                        <div className="bet-selection-name" >Bet on : {Beton} @{oddsValue}</div>
                                                        <div className="bet-profit"></div>
                                                        <div className="betslip-odds betslip-stake">
                                                        <strong>Odds</strong>
                                                        <div className='input-group'>
                                                                                <span onClick={(event) => handleDecrementOdds(event)}>-</span>
                                                                                <input type="text" value={oddsValue} />
                                                                                <span onClick={(event) => handleIncrementOdds(event)}>+</span>
                                                                            </div>
                                                    </div>
                                                        <div className="betslip-stake">
                                                            <strong>Stake</strong>
                                                            <div className="input-group">
                                                                <span className="minus" onClick={(event) => subtractValue(event)}>-</span>
                                                                <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*$/.test(value)) {
                                                                        let old = priceGet
                                                                    setPriceGet(value);
                                                                    updateBook(e,old,value);
                                                                    }
                                                                }}  aria-label="Last name"
                                                                    autoFocus="" maxLength="8" />
                                                                <span className="plus" onClick={(event) => PlusValue(event)}>+</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="betslip-placebutton">
                                                        <div className="price-btns">
                                                            {Array.isArray(stack) && stack.map((item,index) => (
                                                                <span key={index} id={`${item}`} onClick={(event) => addValue(`${item}`,event)}>{formatNumberinStack(item)}</span>
                                                            )) }
                                                            <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedMatchoddsHG?.finalResult?.[0]?.max_stake)}`,event)}>MAX</span>
                                                            <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${allValue()}`,event)}>All</span>
                                                        </div>
                                                        <div className="betplace-btn">
                                                            {isLoggedIn || loggedIn ? <button className="place-bet" onClick={(event) =>{
                                                                onBetClick({ eventid: receivedMatchoddsHG?.finalResult?.[0]?.eventId, marketId: receivedMatchoddsHG?.finalResult?.[0].marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.runnerName, secId: oddItem.runnerId, oddsValue })
                                                            } }>Place Bet</button> : <button onClick={openPopup} className="login-btn">Login to continue</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`betslip_mobileview ${direactsecId === oddItem.runnerId ? 'active' : ''} ${bklyClassValue}`}>
                                                    <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                                    <table className="eventdetails">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div className='plus-minus-wraper'>
                                                                    <div className='plus-minus'>
                                                                        <span onClick={(event) => handleDecrementOdds(event)}>-</span>
                                                                        <input type="text" value={oddsValue} />
                                                                        <span onClick={(event) => handleIncrementOdds(event)}>+</span>
                                                                    </div>
                                                                    <div className='plus-minus'>
                                                                        <span onClick={(event) => subtractValue(event)}>-</span>
                                                                        <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            if (/^\d*$/.test(value)) {
                                                                                let old = priceGet
                                                                            setPriceGet(value);
                                                                            updateBook(e,old,value);
                                                                            }
                                                                        }} placeholder="00" aria-label="Last name" autoFocus="" maxLength="8" />
                                                                        <span onClick={(event) => PlusValue(event)} >+</span>
                                                                    </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="11" className='mbet-slip'>
                                                                    <div className="mbet-slip-wraper">
                                                                    {Array.isArray(stack) && stack.map((item,index) => (
                                                                        <div key={index} id={`${item}`} className="odds-value" onClick={(event) => addValue(`${item}`,event)}>{formatNumberinStack(item)}</div>
                                                                    )) }
                                                                        <div className="odds-value max" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedMatchoddsHG?.finalResult?.[0]?.max_stake)}`,event)} id="10000">MAX</div>
                                                                        <div className="odds-value all" onClick={(event)=>addAllValue(`${allValue()}`,event)} id="98941.80">ALL IN</div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr className="numbering">
                                                                <td colSpan="11">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><span onClick={(event) => incrementValue('1',event)} id="1">1</span></td>
                                                                                <td><span onClick={(event) => incrementValue('2',event)} id="2">2</span></td>
                                                                                <td><span onClick={(event) => incrementValue('3',event)} id="3">3</span></td>
                                                                                <td><span onClick={(event) => incrementValue('4',event)} id="4">4</span></td>
                                                                                <td><span onClick={(event) => incrementValue('5',event)} id="5">5</span></td>
                                                                                <td><span onClick={(event) => incrementValue('6',event)} id="6">6</span></td>
                                                                                <td rowSpan="2"><span className="back-span" onClick={(event) => removeLastDigit(event)}><img alt="" src={betslipclosearrow}/></span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><span onClick={(event) => incrementValue('7',event)} id="7">7</span></td>
                                                                                <td><span onClick={(event) => incrementValue('8',event)} id="8">8</span></td>
                                                                                <td><span onClick={(event) => incrementValue('9',event)} id="9">9</span></td>
                                                                                <td><span onClick={(event) => incrementValue('0',event)} id="0">0</span></td>
                                                                                <td><span onClick={(event) => incrementValue('00',event)} id="double0">00</span></td>
                                                                                {/* <td><span onClick={(event) => incrementValue('000',event)} id="dot">000</span></td> */}
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr className="bet-btns">
                                                                <td colSpan="11">
                                                                    <div className="btn-wraper">
                                                                        <button className="btn cancel-bet" onClick={handlecloseSlip}>CANCEL</button>
                                                                        {isLoggedIn || loggedIn ? <button className="btn place-bet" onClick={(event) => {
                                                                            onBetClick({ eventid: receivedMatchoddsHG?.finalResult?.[0]?.eventId, marketId: receivedMatchoddsHG?.finalResult?.[0].marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.runnerName, secId: oddItem.runnerId, oddsValue })
                                                                        }}>Place Bet</button> : <button onClick={openPopup} className="btn login-btn">Login to continue</button>}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            {isBetLoader ?
                                        <div className="loader-sec loading">
                                            Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{String(loaderCounter).padStart(2, '0')}</span><span className='loader'></span></div>
                                            </div> : ''}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                        </AccordionItem>
                     </Accordion>


                    </div>
                </div> : ''}

                <ExchanngeinfoPopup isOpen={grulesPopup} handleClose={gruleshandleClose} />
            </>
        )
}

export default MatchOdds