import React, { useState, useEffect, useRef } from "react";

export default function Premium_sports() {
    document.title = "Premium Sports";
    const initialized = useRef(false);
    const [premiumSports, setPremiumSports] = useState();
    const token = sessionStorage.getItem("token");

    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;
          callAPI();
        }
      }, [premiumSports]);

    const callAPI = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
    
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
    
        fetch(`${process.env.REACT_APP_BASE_URL}/premium_sports`, requestOptions)
          .then(async (response) => {
            console.log("response code : ",response.status)
            if(response.ok){
              let paresData = await response.json();
              setPremiumSports(paresData);
              console.log("premium_sports - ", paresData);
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      };
  return (
    <>
    <div style={{ width: "100%", height: "100vh" }}>
      {premiumSports && <iframe
        src={premiumSports.urldata.url} // Replace with the desired URL
        title={'premiumSports'}
        style={{ width: "100%", height: "100%", border: "none" }}
      />}
    </div>
  </>
  )
}
