import React, { useEffect, useRef, useState } from "react";
import Container from "../../components/common/Container";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "../../components/common/popup/Popup";

export default function MyCommissionSport() {
  document.title = "Commission Report";
  const { sportId } = useParams();
  const initialized = useRef(false);
  const token = sessionStorage.getItem("token");
  const [plStatement, setPLStatement] = useState();
  const navigate = useNavigate();

    const [loginSucessMsg, setLoginSucessMsg] = useState();
        const [errMsg, setErrmsg] = useState();
        const [msg, setMsg] = useState('');
      
          useEffect(() => {
            if(loginSucessMsg){
              const setloginsucessMsg = setTimeout(() => {
                setLoginSucessMsg(false);
              }, 2000);
        
              return () => {
                clearTimeout(setloginsucessMsg);
              };
            }
      
            if (errMsg) {
              const errMsgTime = setTimeout(() => {
                  setErrmsg(false);
                }, 2000);
                return () => {
                  clearTimeout(errMsgTime);
                };
            }
          });
    
          const handleClaimCommission = (toClaimCommission) => {
            // if (toClaimCommission > 0) {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                const requestOptions = {
                  method: "GET",
                  headers: myHeaders,
                  redirect: "follow",
                };
            
                fetch(`${process.env.REACT_APP_BASE_URL}/claimCommission`, requestOptions)
                  .then(async (response) => {
                    let paresData = await response.json();
                    setMsg(paresData.msg);
                    if (response.status === 200) {
                        setLoginSucessMsg(true);
                    }else if(response.status === 401){
                        setMsg(paresData.message);
                        setErrmsg(true);
                    }else{
                        setMsg(paresData.msg);
                        setErrmsg(true);
                    }
                    
                    console.log("claimCommission - ", paresData);
                    callPLStatement();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            // }
          }

  const handleRowClick = (item) => {
    navigate(`/myCommissionReports/series/${item._id}`);
  };

  const callPLStatement = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/commissionPage?sportId=${sportId} `, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setPLStatement(paresData);
        console.log("commissionPage2 - ", paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callPLStatement();
    }
  });

  return (
    <Container size="full">
      <div className="account-sec">
        <div className="acount-header-filter">
          <div className="title-wraper">
            <h3 className="title back-icon" onClick={()=>navigate(-1)}><i className="fa-solid fa-arrow-left"></i> Commission Report | Cricket</h3>
          </div>
          <div className="right-filter">
            <div className="btns">
              <button className="commission btn" onClick={()=>handleClaimCommission(plStatement && plStatement.toClaimCommission)}>
                <i className="fa-solid fa-money-check-dollar"></i> Claim Commission
                ({plStatement && plStatement.toClaimCommission})
              </button>
            </div>
          </div>
        </div>

        <div className="account-table">
          <table border="1" cellPadding="10" cellSpacing="0">
            <thead>
              <tr>
                <th className="game">SR No.</th>
                <th>Competition</th>
                <th>Commission</th>
              </tr>
            </thead>
            <tbody>
              {plStatement &&
              plStatement.commissionData &&
              plStatement.commissionData.length > 0 ? (
                plStatement.commissionData.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => handleRowClick(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{index + 1}</td>
                    <td>{item._id}</td>
                    <td>{item.totalCommissionPoints}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Popup
        popupClass={`alertMsgpopup ${loginSucessMsg ? "open" : ""}`}
        content={
          <div className={`alert-msg`}>
            <span className="msg">{msg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
      <Popup
        popupClass={`alertMsgpopup Error ${errMsg ? "open" : ""}`}
        content={
          <div className={`alert-msg`}>
            <span className="msg">{msg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
    </Container>
  );
}
