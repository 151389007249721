import React, { useState } from "react";

const OtpInput = ({ onOtpChange }) => {
  const [otp, setOtp] = useState(new Array(6).fill("")); // Array for 6 inputs

  const handleChange = (value, index) => {
    if (/^[0-9]*$/.test(value)) { // Allow only numbers
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Notify parent component of the OTP change
      onOtpChange(newOtp.join(""));

      // Move focus to the next input if value is entered
      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("Text").slice(0, 6); // Get the first 6 characters
    if (/^[0-9]+$/.test(pastedData)) {
      const newOtp = pastedData.split("");
      setOtp(newOtp);
      onOtpChange(newOtp.join(""));
    }
  };

  return (
    <div className="otp-number">
      {otp.map((digit, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          style={{
            width: "40px",
            height: "40px",
            fontSize: "18px",
            textAlign: "center",
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "0px",
          }}
        />
      ))}
    </div>
  );
};

export default OtpInput;
